<template>
  <table
    align="center"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    class="e-c sect sect-NosTMq"
  >
    <tr>
      <td bgcolor="transparent">
        <div
          class="e-c"
          style="max-width: 640px; margin: 0px auto;"
        >
          <table
            align="center"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="640"
            class="s-r_t"
            style="max-width: 100%; width: 100%;"
          >
            <tr>
              <td
                bgcolor="#FFFFFF"
                width="100%"
                style="border-radius: 0px; background-color: rgb(255, 255, 255); width: 100%;"
              >
                <div style="font-size: 0px; line-height: 0;">
                  <table>
                    <tr>
                      <td
                        class="s-sp_l"
                        width="30"
                        style="padding-left: 30px; padding-right: 0px;"
                      />
                      <td
                        class="s-sp"
                        width="100%"
                        style="padding: 10px 0px;"
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          border="0"
                          width="100%"
                        >
                          <tr>
                            <td
                              align="center"
                              valign="top"
                              style="font-size: 0px; padding: 0px;"
                            >
                              <div
                                class="st-c"
                                style="padding-left: 0px; padding-right: 0px; min-height: 80px; box-sizing: border-box; margin: 0px; vertical-align: top; max-width: 580px; min-width: 160px; width: 100%;"
                              >
                                <div
                                  class="mb-db"
                                  style="display: table-cell; vertical-align: top;"
                                >
                                  <div class="st-ci">
                                    <table
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="0"
                                      width="100%"
                                    >
                                      <tr>
                                        <td style="padding: 0px; font-size: 16px; line-height: normal;">
                                          <table
                                            align="center"
                                            cellspacing="0"
                                            cellpadding="0"
                                            border="0"
                                            width="100%"
                                            style="padding: 0px;"
                                          >
                                            <tr>
                                              <td
                                                class="i-tltd"
                                                align="center"
                                                bgcolor="transparent"
                                                style="padding: 10px;"
                                              >
                                                <div class="b-EHtMaR">
                                                  <img
                                                    width="84"
                                                    src="https://cdn.sender.net/email_images/230394/images/all/kf_logo_faca118c.png"
                                                    alt="Image description"
                                                    border="0"
                                                    class="g-img"
                                                    style="display: block; box-sizing: border-box; max-width: 100%; border-radius: 0px;"
                                                  >
                                                </div>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        class="s-sp_r"
                        width="30"
                        style="padding-left: 30px; padding-right: 0px;"
                      />
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
  </table>
  <table
    align="center"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    class="e-c sect sect-6vT7az"
  >
    <tr>
      <td bgcolor="transparent">
        <div
          class="e-c"
          style="max-width: 640px; margin: 0px auto;"
        >
          <table
            align="center"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="640"
            class="s-r_t"
            style="max-width: 100%; width: 100%;"
          >
            <tr>
              <td
                bgcolor="#13103F"
                width="100%"
                style="border-radius: 0px; background-color: rgb(19, 16, 63); width: 100%;"
              >
                <div style="font-size: 0px; line-height: 0;">
                  <table>
                    <tr>
                      <td
                        class="s-sp_l"
                        width="48"
                        style="padding-left: 48px; padding-right: 0px;"
                      />
                      <td
                        class="s-sp"
                        width="100%"
                        style="padding: 48px 0px 0px;"
                      >
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          border="0"
                          width="100%"
                        >
                          <tr>
                            <td
                              align="center"
                              valign="top"
                              style="font-size: 0px; padding: 0px;"
                            >
                              <div
                                class="st-c"
                                style="padding-left: 0px; padding-right: 0px; min-height: 360px; box-sizing: border-box; margin: 0px; vertical-align: top; max-width: 511px; min-width: 160px; width: 100%;"
                              >
                                <div
                                  class="mb-db"
                                  style="display: table-cell; vertical-align: top;"
                                >
                                  <div class="st-ci">
                                    <table
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="0"
                                      width="100%"
                                    >
                                      <tr>
                                        <td style="padding: 0px; font-size: 16px; line-height: normal;">
                                          <table
                                            align="center"
                                            cellspacing="0"
                                            cellpadding="0"
                                            border="0"
                                            width="100%"
                                            style="padding: 0px;"
                                          >
                                            <tr>
                                              <td
                                                align="center"
                                                class="i-tltd"
                                                bgcolor="transparent"
                                                style="padding: 10px; direction: ltr; font-family: Arial; color: rgb(255, 255, 255); font-size: 42px; text-align: center; font-weight: bold; font-style: normal; text-decoration: none; line-height: 150%;"
                                              >
                                                <div>
                                                  <div class="inline-redactor-wrapper">
                                                    <p>132324</p>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="i-tltd"
                                                bgcolor="transparent"
                                                style="padding: 10px; direction: ltr; font-family: Arial; color: rgb(255, 208, 66); font-size: 24px; text-align: center; font-weight: bold; font-style: normal; text-decoration: none; line-height: 150%;"
                                              >
                                                <div>
                                                  <div class="inline-redactor-wrapper">
                                                    <p>Here is your code</p>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="i-tltd"
                                                bgcolor="transparent"
                                                style="padding: 16px 0px; direction: ltr; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(255, 255, 255); font-size: 16px; text-align: center; line-height: 150%;"
                                              >
                                                <div>
                                                  <div class="inline-redactor-wrapper">
                                                    <p>Welcome to the world of KryptoFighters!</p><p>Please use the following Verification Code below to confirm your access.</p>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td
                                                align="center"
                                                class="i-tltd"
                                                bgcolor="transparent"
                                                style="padding: 10px; direction: ltr; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(255, 255, 255); font-size: 16px; text-align: center; line-height: 140%;"
                                              >
                                                <div>
                                                  <div
                                                    class="inline-redactor-wrapper "
                                                    style=" color: rgb(209, 217, 255); "
                                                  >
                                                    <p>The code expires in 10 minutes.</p>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </table>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        class="s-sp_r"
                        width="81"
                        style="padding-left: 81px; padding-right: 0px;"
                      />
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
  </table>
  <table
    align="center"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    class="e-c sect sect-iHMxy7"
  >
    <tbody>
      <tr>
        <td
          bgcolor="transparent"
          style="background-color: transparent;"
        >
          <div
            class="e-c"
            style="max-width: 640px; margin: 0px auto;"
          >
            <!--[if mso]>
          <table align="center" cellspacing="0" cellpadding="0" border="0" width="640" style="max-width: 640px; width: 640px;">
            <tr>
              <td>
          <![endif]-->
            <table
              align="center"
              cellspacing="0"
              cellpadding="0"
              border="0"
              width="640"
              class="s-r_t"
              style="max-width: 100%; width: 100%;"
            >
              <tbody>
                <tr>
                  <td
                    bgcolor="#080F39"
                    width="100%"
                    style="border-radius: 0px; background-color: rgb(8, 15, 57); width: 100%;"
                  >
                    <div style="font-size: 0px; line-height: 0;">
                      <table>
                        <tbody>
                          <tr>
                            <td
                              class="s-sp_l"
                              width="48"
                              style="padding-left: 48px; padding-right: 0px;"
                            />
                            <td
                              class="s-sp"
                              width="100%"
                              style="padding: 48px 0px 32px;"
                            >
                              <table
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="center"
                                      valign="top"
                                      style="font-size: 0px; padding: 0px;"
                                    >
                                      <!--[if mso]>
                                    <table align="center" cellspacing="0" cellpadding="0" border="0" width="100%">
                                      <tr>
                                    <![endif]-->
                                      <!--[if !mso]><!-->
                                      <!--<![endif]-->
                                      <!--[if mso]>
                                    <td class="ms-st_c" width="544" valign="top" bgcolor="undefined" style="background-color: undefined; padding: 0;">
                                    <![endif]-->
                                      <div
                                        class="st-c"
                                        style="padding-left: 0px; padding-right: 0px; min-height: 130px; box-sizing: border-box; margin: 0px; vertical-align: top; max-width: 544px; min-width: 160px; width: 100%;"
                                      >
                                        <div
                                          class="mb-db"
                                          style="display: table-cell; vertical-align: top;"
                                        >
                                          <div class="st-ci">
                                            <table
                                              cellspacing="0"
                                              cellpadding="0"
                                              border="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding: 0px; font-size: 16px; line-height: normal;">
                                                    <table
                                                      align="center"
                                                      cellspacing="0"
                                                      cellpadding="0"
                                                      border="0"
                                                      width="100%"
                                                      style="padding: 0px;"
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            align="center"
                                                            class="i-tltd"
                                                            bgcolor="transparent"
                                                            style="padding: 24px 0px 0px; direction: ltr; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(244, 244, 244); font-size: 16px; text-align: center; line-height: 150%;"
                                                          >
                                                            <div>
                                                              <div class="inline-redactor-wrapper">
                                                                <p>Kryptofighters by Maxma Games Studio .co.,ltd</p>
                                                              </div>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td
                                                            class="i-tltd social-block text-hover"
                                                            align="center"
                                                            bgcolor="transparent"
                                                            style="padding: 24px 0px 0px; line-height: 0;"
                                                          >
                                                            <table
                                                              width="auto"
                                                              border="0"
                                                              cellpadding="0"
                                                              cellspacing="0"
                                                              style="margin: 0px !important;"
                                                            >
                                                              <tbody>
                                                                <tr align="center">
                                                                  <td>
                                                                    <table>
                                                                      <tbody>
                                                                        <tr>
                                                                          <td align="center">
                                                                            <a
                                                                              href="https://discord.gg/DGWXP84H"
                                                                              style="width: 32px; display: inline-block;"
                                                                            ><img
                                                                              src="https://cdn.sender.net/email-editor/static/img/social/64/v3/discord.png"
                                                                              width="32"
                                                                              alt="Discord"
                                                                            ></a>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                  <td><a href="https://discord.gg/DGWXP84H"><span style="font-size: 12px; line-height: 120%; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(182, 149, 149); text-decoration: none; font-weight: normal; font-style: normal;" /></a></td>
                                                                  <td width="26" />
                                                                  <td>
                                                                    <table>
                                                                      <tbody>
                                                                        <tr>
                                                                          <td align="center">
                                                                            <a
                                                                              href="https://twitter.com/KryptoFighters"
                                                                              style="width: 32px; display: inline-block;"
                                                                            ><img
                                                                              src="https://cdn.sender.net/email-editor/static/img/social/64/v3/twitter.png?v=1"
                                                                              width="32"
                                                                              alt="Twitter"
                                                                            ></a>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                  <td><a href="https://twitter.com/KryptoFighters"><span style="font-size: 12px; line-height: 120%; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(182, 149, 149); text-decoration: none; font-weight: normal; font-style: normal;" /></a></td>
                                                                  <td width="26" />
                                                                  <td>
                                                                    <table>
                                                                      <tbody>
                                                                        <tr>
                                                                          <td align="center">
                                                                            <a
                                                                              href="https://kryptofighters.io/"
                                                                              style="width: 32px; display: inline-block;"
                                                                            ><img
                                                                              src="https://cdn.sender.net/email_images/230394/images/all/fafafa.png"
                                                                              width="32"
                                                                              alt="Website"
                                                                              style="width: auto; max-width: 32px; max-height: 32px; height: auto;"
                                                                            ></a>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                  <td><a href="https://kryptofighters.io/"><span style="font-size: 12px; line-height: 120%; font-family: Arial, &quot;Helvetica Neue&quot;, Helvetica, sans-serif; color: rgb(182, 149, 149); text-decoration: none; font-weight: normal; font-style: normal;" /></a></td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->
                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td
                              class="s-sp_r"
                              width="48"
                              style="padding-left: 48px; padding-right: 0px;"
                            />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          <!--[if mso]>
              </td>
            </tr>
          </table>
          <![endif]-->
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>