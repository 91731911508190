<template>
  <div
    id="withdrawItemPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="withdrawItemPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Withdraw
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
                
        <form @submit.prevent="withdraw">
          <div class="modal-body">
            <div
              class="row"
              style="height: 80px; margin-bottom: 10px;"
            >
              <div class="col-3 item-center">
                In Game :
              </div>
              <div
                class="col-9 item-center"
                style="height: 100%; display: grid; grid-auto-rows: auto; padding: 0 1rem 0 0;"
              >
                <div
                  class="no-space-around"
                  style="border: 1px solid #495057; border-radius: 5px; width: 100%; padding: 0.5rem 1rem 0.5rem 0.5rem; display: flex; align-items: center; height: fit-content"
                >
                    <img
                      :src="getItemImage()"
                      alt="Owner Icon"
                      class="stat-icon"
                      style="height: 60px;"
                    >
                    <div style="display: flex; flex-direction: column; width: 100%; gap: 5px;">
                      <div style="display: flex; justify-content: space-between; width: 100%;">
                        <span class="badge bg-custom-secondary">
                          # {{ inGameItem?.item_id?? 0 }}
                        </span>
                        <span class="badge" :class="['text-rarity-' + (inGameItem?.rarity?? '1')]">
                          {{ getItemRarityName(inGameItem?.rarity?? '1') }}
                        </span>
                      </div>
                      <div style="display: flex; justify-content: space-between; width: 100%;">
                        <span style="width: 80%">
                          {{ inGameItem?.item_name?? '' }}
                        </span>
                        <span style="font-weight: bold; ">
                          x {{ inGameItem?.amount?? '' }}
                        </span>
                      </div>
                      
                    </div>
                    
                </div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 10px;">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Withdraw : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="">
                  <input
                    id="withdrawAmount"
                    v-model="withdrawAmount"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="1"
                    required
                    :disabled="true"
                  >
                </div>
                <div
                  v-if="showErrorAlert"
                  class="alert alert-danger mt-3"
                >
                  Invalid Total format.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading || isWithdrawDisable"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Withdraw </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref,computed } from 'vue';
import {useToast} from 'vue-toast-notification';
import PortalContractABI from '@/contracts/PortalContractABI.json';
import { getSignature } from '@/utils/web3Utils';

import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        inGameItem: {
            type: Object,
            required: true
        }
    },
    setup() {
        const store = useStore()
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
            withdrawAmount: 1,
            message: {},
            isWithdrawDisable: false
        };
    },
    computed: {
        jabBalance: () => useStore().wallet.jab.toString(),
    },
    // watch: {
    //   withdrawAmount(newVal) {
    //     console.log('withdrawAmount', newVal);
    //     if (newVal == 0 || newVal == '' || newVal > (this.inGameItem?.amount?? 0) ) {
    //       this.isWithdrawDisable = true;
    //     } else {
    //       this.isWithdrawDisable = false;
    //     }
    //   }
    // },
    methods: {
        getItemRarityName(rarityId){
          switch(rarityId){
            case 1: return 'Common'; break;
            case 3: return 'Rare'; break;
            case 4: return 'Epic'; break;
            default: return ''; break;
          }
        },
        closeModal() {
            // console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        async withdraw() {
          if(!this.inGameItem){
            useToast().open({
                    message: 'Error: Can not withdraw this item.',
                    position: 'top-right',
                    type: 'error',
                });
            
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('hide');
            });

            this.closeModal();
            return;
          }

            var $ = jQuery.noConflict();

            this.isLoading = true;
            this.closeModal();
            this.message = {
                    title: 'Withdraw pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            try {
                const { signature, challengeMessage, address } = await getSignature();
                const accountAddress = useStore().getWalletAddress;
                console.log("accountAddress" + accountAddress);

                const requestBody = {
                    type: this.inGameItem?.item_type?? '',
                    itemId: this.inGameItem?.item_id?? 0,
                    wallet_address: address,
                    amount: this.withdrawAmount,
                    signature,
                    challengeMessage,
                    address
                };
                console.log('requestBody:', requestBody);

                const apiUrl = `https://reward-portal.kryptofighters.io/api/protect/testnet/withdraw-nft`;
                //console.log('apiUrl:', apiUrl);

                const response = await axios.post(apiUrl, requestBody);
                console.log('response:', response.data);

                this.isLoading = false;
                useToast().open({
                        message: 'Withdraw completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                
            } catch (error) {
                console.error('Error withdraw:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                this.isLoading = false;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.closeModal();
            }
        },
        getItemImage(){
          var path = `/images/item/`;
          if(this.inGameItem?.item_code.includes('ITM_SS')?? ''){
            path += `stone`;
          }
          else if(this.inGameItem?.item_code.includes('ITM_ATF')?? ''){
            path += `artifact`;
          }

          path += `/${this.inGameItem?.item_code?? ''}.png`;
          return path;
        }
    }
};
</script>

<style scoped>
.no-space-around {
    padding: 0;
    margin: 0;
}

.item-center {
    align-self: center;
}

.text-rarity-1 {
    background-color: #B4B4B4; 
}
.text-rarity-3 {
    background-color: #06D8DB;
}
.text-rarity-4 {
    background-color: #B069FF; 
}
</style>
  
