//import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';



import { createApp } from 'vue'
import { createPinia } from 'pinia'

import VueFeather from 'vue-feather';

import App from './App.vue'
import router from './router/index.ts'
import analytics from '@/firebase.js';
import { logEvent } from "firebase/analytics";

const app = createApp(App)
app.component("VueFeather", VueFeather);
app.use(createPinia())
app.use(router)

import 'vuetify/styles'
import './assets/css/styles.css';
import './assets/css/styles.scss'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const vuetify = createVuetify({
    components,
    directives
})

app.use(vuetify);
app.use(ToastPlugin);
logEvent(analytics, 'app_start');

app.mount('#app')


