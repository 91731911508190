export function timeAgo(timestamp) {
    const currentDate = new Date();
    const saleDate = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript timestamp
  
    const timeDifference = Math.abs(currentDate - saleDate);
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (days > 7) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return saleDate.toLocaleDateString(undefined, options);
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'just now';
    }
  }

  export function convertToTimestamp(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    return dateObject.getTime() / 1000; // Convert to Unix timestamp (in seconds)
  }

  export function convertDateString(timestamp) {
    const saleDate = new Date(timestamp * 1000); // Convert Unix timestamp to JavaScript timestamp
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return saleDate.toLocaleDateString(undefined, options);
  }