export function getContractAddressByItemId(id) {
    let contract = "";
    switch (id) {
        case 100:
            contract = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            break;
        case 102:
            contract = process.env.VITE_TICKET_V2_CONTRACT_ADDRESS;
            break;
        case 10:
            contract = process.env.VITE_FRAME_CONTRACT_ADDRESS;
            break;
        case 1,2,3,4:
            contract = process.env.VITE_FRAGMENT_CONTRACT_ADDRESS;
            break;
    }
    return contract;
}