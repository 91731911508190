<template>
  <div style="background-color: #0E043D; min-height: 100vh;">
              <div class="card" style="background-color: unset; border: unset;">
                  <div class="card-body">
                      <div v-if="isLoading">
                          <div class="horizontal-scroll">
                              <div v-for="index in 6" :key="index" class="product-item">
                                  <v-skeleton-loader
                                      :type="'card'"
                                      :animation="true"
                                      :width="200"
                                      :height="360"
                                      theme="dark"
                                  ></v-skeleton-loader>
                              </div>
                          </div>
                      </div>
                      <div v-else>
                          <div class="horizontal-scroll">
                              <FighterItem v-for="(product, index) in recentProducts" :key="index" :product="product" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
</template>



<script>
import axios from 'axios';
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import FighterItem from '@/components/FighterItem.vue';

export default {
  components: {
    FighterItem
  },
  data() {
    return {
      recentProducts: []
    };
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  },
  async mounted() {
    try {
      const store = useExchangeStore();
      store.fetchExchangeRates();

      const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;

      const response = await axios.get(`https://testnet-api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${fighterCollection}&size=32`, {
        headers: {
          'accept': 'application/json',
          'x-api-key': process.env.VITE_RARIBLE_API_KEY
        }
      });

      const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
      const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

      console.log("response" , response);

      const combinedData = [...response.data.activities];

      const recentSell = combinedData.filter(activity => activity['@type'] == 'SELL');
      const recentList = combinedData.filter(activity => activity['@type'] == 'LIST');
      const cancelList = combinedData.filter(activity => activity['@type'] == 'CANCEL_LIST');

      console.log("recentSell: " , recentSell);
      console.log("recentList: " , recentList);

      const sellMap = new Map();
      recentSell.forEach(sell => {
        const tokenId = sell.nft.type.tokenId;
        const lastUpdatedAt = new Date(sell.lastUpdatedAt);
        if (!sellMap.has(tokenId) || new Date(sellMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
          sellMap.set(tokenId, sell);
        }
      });

      const cancelMap = new Map();
      cancelList.forEach(sell => {
        const tokenId = sell.make.tokenId;
        const lastUpdatedAt = new Date(sell.lastUpdatedAt);
        if (!cancelMap.has(tokenId) || new Date(cancelMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
          cancelMap.set(sell.hash, sell);
        }
      });

      // Remove items in recentList that are also in recentSell
      const filteredList = recentList.filter(list => {
        const tokenId = list.make.type.tokenId;
        const lastUpdatedAt = new Date(list.lastUpdatedAt);
        const sellItem = sellMap.get(tokenId);
        const cancelItem = cancelMap.get(list.hash);

        return (!sellItem  || lastUpdatedAt > new Date(sellItem.lastUpdatedAt)) && (!cancelItem || lastUpdatedAt > new Date(cancelItem.lastUpdatedAt) );
      });

      const latestMap = new Map();
      filteredList.forEach(item => {
        if (!latestMap.has(item.make.type.tokenId)) {
          latestMap.set(item.make.type.tokenId, item);
        } else {
          const existingItem = latestMap.get(item.make.type.tokenId);
          if (new Date(item.lastUpdatedAt) > new Date(existingItem.lastUpdatedAt)) {
          // if (Number(item.price) < Number(existingItem.price)) {
            latestMap.set(item.make.type.tokenId, item);
          }
        }
      });
      const uniqueList = Array.from(latestMap.values());
      console.log("itemResponse:", uniqueList);

      const ethList = uniqueList.filter(f=>f.take.type["@type"] == 'ETH');

      console.log("ethList: " , ethList);

      ethList.sort((a, b) => {  
          const date1 = new Date(b.lastUpdatedAt);
          const date2 = new Date(a.lastUpdatedAt);
          return date1 - date2;
      });

      console.log("response recentProducts" , ethList);

      this.recentProducts = ethList.map(listing => {
        const date = new Date(listing.lastUpdatedAt);
        const unixTimestamp = Math.floor(date.getTime() / 1000);
        const tokenID = listing.make.type.tokenId;
        const dateString = timeAgo(unixTimestamp);
        const price = parseFloat(listing.price);
        const currency = (listing.take.type["@type"] !== 'ETH')? "JAB":"ETH";
        const usdPrice = convertToUSD(price, currency, ethToUsdExchangeRate, maticToUsdExchangeRate);
        
        return {
          id: tokenID,
          price: price,
          currency: currency,
          listDate: listing.lastUpdatedAt,
          dateString: dateString,
          usdPrice: usdPrice,
          isOwner: false
        };
      });

      // Sort by listDate (newest first) and pick 5 random products
      this.recentProducts.sort((a, b) => b.listDate - a.listDate);
      this.recentProducts = this.shuffleArray(this.recentProducts).slice(0, 10);
    } catch (error) {
      console.error('Error fetching data from OpenSea:', error);
    }
  }
};
</script>

<style scoped>


html, body {
    overflow: hidden; /* Prevent scrolling on the entire page within the iframe */
    height: 100%; /* Ensure the body takes the full height of the iframe */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.horizontal-scroll {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the items horizontally */
    overflow: hidden; /* Prevent both horizontal and vertical scroll */
    white-space: nowrap; /* Prevents items from wrapping */
    padding: 20px 0; /* Adds some padding around the items */
    height: 100%; /* Ensure the container doesn't overflow */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.product-item {
    flex: 0 0 auto; /* Prevent shrinking */
    width: 200px; /* Set fixed width */
    margin-right: 20px; /* Space between items */
}

.product-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}




</style>