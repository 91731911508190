<template>
  <div class="product-item">
    <!-- <div v-if="isLoading" style="margin-bottom: 50px;">
            <v-skeleton-loader :type="'card'" :animation="true" :width="200" :height="300" theme="dark"></v-skeleton-loader>
        </div>
        <div v-else> -->
    <div class="card product-card-container">
      <div class="card-body">
        <div class="product-header">
          <div class="left-content">
            <div class="product-id">
              # {{ getRewardName(reward.item_id) }}
            </div>
          </div>
        </div>
        <div
          class="image-container"
          style="width: 160px; height: 160px; margin-bottom: 10px;"
        >
          <img
            :src="`/images/item/blockchain_id/${reward.item_id}.png`"
            alt="reward image"
            class="product-image"
          >
        </div>

        <div v-if="reward.amount">
          <h4 class="product-usd-price">
            {{ reward.amount }} PCS
          </h4>
        </div>

        <div v-if="!reward.claimed">
          <button
            type="button"
            class="btn btn-primary"
            @click="clickClaim"
          >
            Claim
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>

import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useStore } from '@/stores/index.ts';
import {useToast} from 'vue-toast-notification';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        reward: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: {}
        }
    },
    mounted() {
        //this.fetchNftData();
    },
    methods: {
        async fetchNftData() {
            if (this.product.itemId == 10) {
                //Hall of Frame
                this.isLoading = true;
                const apiUrl = `https://api.opensea.io/api/v2/chain/matic/contract/0xe3934ea095a0fa3971a692b3355808c7fe5a406c/nfts/${this.product.id}`;
                const headers = {
                    'accept': 'application/json',
                    'x-api-key': process.env.VITE_OPENSEA_API_KEY,
                };

                const response = await axios.get(apiUrl, { headers });

                let effects = [];
                let rarity = null;

                // Loop through the traits array to find the "Effect" and "Rarity"
                response.data.nft.traits.forEach((trait) => {
                    if (trait.trait_type === 'Rarity') {
                        rarity = trait.value;
                    } else {
                        effects.push(trait.trait_type);
                    }
                });
                console.log(effects);
                this.product.image = response.data.nft.image_url;
                this.product.name = response.data.nft.name;
                /*this.item = {
                            token_id: this.tokenId,
                            description: 'A Border/Frame for your in-game user profile picture in the form of NFT which allows you to collect different rarity tiers and unique designs each tier having its own in-game benefits apart from the collectible value.',
                            image: response.data.nft.image_url,
                            name: response.data.nft.name,
                            effects: effects,
                            rarity: rarity
                        };*/

            }else if(this.product.itemId == 4 || this.product.itemId == 1|| this.product.itemId == 2 || this.product.itemId == 3){
                //Fragment and Lootbox
                this.isLoading = true;
                const item = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fragment/${this.product.itemId}.json`);
                console.log(item);
                
                this.product.image = item.data.image;
                this.product.name = item.data.name;
                this.product.type = item.data.name;
            }

            const image = new Image();

            image.onload = () => {
                this.isLoading = false;
            };
            image.src = this.product.image ;
        },
        getRewardName(id){
            let name = '';
            switch(id){
                case 0: name = 'Goldbar'; break;
                case 1: name = 'Holobar'; break;
                case 2: name = 'Microchip'; break;
                case 3: name = 'Loot box'; break;
                case 11: name = 'Jab'; break;
                case 102: name = 'Fighter Ticket'; break;
            }
            return name;
        },
        async clickClaim(){
            this.message = {
                    title: 'Transaction pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: ""
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            try {
                const accountAddress = useStore().getWalletAddress;
                console.log("accountAddress" + accountAddress);

                const requestBody = {
                    reward_id: this.reward.reward_id,
                    wallet: useStore().getWalletAddress
                };
                //console.log('requestBody:', requestBody);

                const apiUrl = `https://reward-portal.kryptofighters.io/rewards/claim`;
                //console.log('apiUrl:', apiUrl);

                const response = await axios.post(apiUrl, requestBody);
                //console.log("response reward " , response);

                useToast().open({
                        message: 'Claim reward completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardClaimed');
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardClaimed');
            }
        }
    }
};
</script>
  
<style scoped>
.product-item {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
    align-items: center;
}

.product-image {
    max-width: 100%;
    height: 100%;
    width: auto;
    object-fit: cover;
}

.product-name {
    font-size: 16px;
    text-align: center;
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}



.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}



.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('@/assets/icon-stats-kroma.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.open-button {
    height: 20%;
    width: 100%;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    font-size: 16px;
    border: 2px solid #2980b9;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s, transform 0.3s;
}

.open-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.clickable-image {
    cursor: pointer;
}

@media (max-width: 768px) {
    .product-image {
    width: 120px;
    margin-bottom: 0px;
}
}

</style>