<template>
  <div class="page-content content-wrap">
    <div class="main-content">
      <div class="d-flex align-items-left">
      </div>
      <div
        class=""
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding-top: 0px; "
      >
      <img
    :src="`../images/free-mint-cover.png`"
    alt="Jab"
    class="material-icon"
    style="width: 360px; height: 360px; max-width: 100%; object-fit: contain; padding: 0 0 0 0; transform: scale(1.8); position: relative; z-index: 1;"
  />

        <h5 class="text-white" style="position: relative; z-index: 2;">Welcome to KryptoFighters</h5>

        <p class="text-white" style="position: relative; max-width: 800px; z-index: 2; text-align: center;">
        Claim your first fighter for free and start building your ultimate team now!
        
        </p>
        
        
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 20px;">
            <div v-if="wallet === ''" style="position: relative; z-index: 2; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px;">
                <div style="color: yellow ;position: relative; z-index: 2; text-align: center;">Please connect your wallet to Kroma Testnet </div>
                
                    <button
                        style=""
                        @click="addKromaSepoliaNetwork"
                        class="btn default-button"
                    >
                        <span style="font-weight: 400; "> Add Kroma Sepolia Network </span>
                    </button>
            </div>
            <button
                style="min-width: 120px; position: relative; z-index: 2;"
                type="submit"
                class="btn btn-primary"
                @click="mint"
                :disabled="false"
            >
                <span style="font-weight: 400;"> Mint </span>
            </button>
        </div>
        <p class="text-solf-light" style="position: relative; padding-top: 20px; z-index: 2; text-align: center;"> This free-mint campaign is limited to one mint per wallet, <br>primarily for ecosystem testing, with no gas fees required.</p>

      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
  <SuccessPopup :type="open"></SuccessPopup>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useStore } from '@/stores/index.ts';
import NFTERC1155ABI from '@/contracts/NFTERC1155ABI.json';
import { getSignature } from '@/utils/web3Utils';
import {useToast} from 'vue-toast-notification';

import { ethers , verifyMessage} from 'ethers';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";
import SuccessPopup from "@/components/popup/SuccessPopup.vue";

export default {
    components: {
      TransactionPendingPopup,
      SuccessPopup
    }, data() {
        return {
            showModal: false,
            message: {},
            wallet: ''
        };
    },
    mounted() {
        this.wallet = useStore().getWalletAddress;
        if(useStore().getWalletAddress != ''){
            this.wallet = useStore().getWalletAddress;
        }else{
            watch(() => useStore().getWalletAddress, () => {
                this.wallet = useStore().getWalletAddress;
            });
        }
    },
    methods: {
        async mint() {
            try {
                this.message = {
                    title: 'Minting',
                    image: '../images/loading/transaction-loading.gif',
                    text: ""
                }
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('show');
                });

                const accountAddress = this.wallet;
                const { signature, challengeMessage, address } = await getSignature();
                const requestBody = {
                    verifyWalletOwnership:accountAddress,
                    address: address,
                    signature: signature,
                    challengeMessage: challengeMessage
                };
                console.log("requestBody", requestBody);
                const response = await axios.post('https://reward-portal.kryptofighters.io/api/protect/testnet/free-mint', requestBody);
                console.log("response.data", response.data);

                useToast().open({
                        message: 'Mint completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });

                $(document).ready(function() {
                    $('#successPopup').modal('show');
                });
                // You can also perform any additional actions here after obtaining the signature
            } catch (error) {
                var errorMessage = '';
                console.error('Error mint:', error);
                if(this.wallet){
                    errorMessage = error.response.data.message.length > 64 ? error.response.data.message.slice(0, 64) + '...' : error.response.data.message;
                }
                else{
                    errorMessage = 'Wallet is not connect.';
                }
                
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
            }
        } ,
        async addKromaSepoliaNetwork() {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: '0x936',
                        chainName: 'Kroma Sepolia Testnet',
                        rpcUrls: ['https://api.sepolia.kroma.network/'],
                        nativeCurrency: {
                            name: 'Kroma Sepolia ETH',
                            symbol: 'ETH', // 3-6 characters long
                            decimals: 18
                        },
                        blockExplorerUrls: ['https://sepolia.kromascan.com/']
                    }]
                });
                useToast().open({
                    message: 'Kroma Sepolia Network added successfully.',
                    position: 'top-right',
                    type: 'success',
                });
            } catch (error) {
                console.error('Error adding network:', error);
                useToast().open({
                    message: 'Error adding Kroma Sepolia Network.',
                    position: 'top-right',
                    type: 'error',
                });
            }
        },
        openModal() {
            this.showModal = true;
        }
    }
}
</script>
  
<style scoped>

.page-content {
  background-image: url('../images/free-mint-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content-wrap {
}


.no-space {
    margin: 0;
    padding: 0;
}

.material-container {
    display: flex;
    justify-content: center;
    align-content: center;
    /* border: 1px solid green;  */
}

.body-content {
    border: 1px solid green;
    height: 100%; 
    width: 25%;
    justify-content: center;
    align-content: center;
}

.material-box {
    border: 1px solid lightslategrey;
    border-radius: 10px;
    height: 130px;
    width: 130px;
    justify-self: center;
    align-self: center;
    padding: 15px;
}

.material-use {
    /* border: 1px solid lightslategrey; */
    height: 100%;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.material-icon {
    /* border: 1px solid red;  */
    max-width: 400px;
    height: 60%; 
    width: 60%; 
    margin: 0; 
    padding: 0;
}

 .text-white-2{
  color:rgb(255, 255, 255); 
}

.material-total {
    /* border: 1px solid red;  */
    height: 25%; 
    width: 100%; 
    text-align: center; 
    align-items: center; 
    display: flex; 
    justify-content: center;
    font-size: 100%;
    color: white;
}

.material-total .current-fragment {
    color: lightgreen;
}

.craft-btn {
    width: 10%;
}

@media (max-width: 768px) {
    .craft-btn {
        width: 30%;
    }
}

</style>