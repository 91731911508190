<template>
  <div
    v-if="showModal == true"
    id="transactionPendingPopup"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="transactionPendingPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-body"
          style="padding: 30px; display: grid;"
        >
          <div class="text-header"> 
            {{ message.title }}
          </div>
          <div
            class=""
            style="margin: 0; margin-bottom: 20px; justify-self: center; position: relative;"
          >
            <!-- <audio controls id="openingItemSFX" class="hidden-audio" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
                            <source src="~@/assets/sound/SFX_HitCharge.mp3" type="audio/mp3">
                        </audio> -->
            <img
              :src="message.image"
              alt="Transaction pending image"
              class="loading-image"
            >
          </div>
          <div class="text-recieved">
            <span v-html="message.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';

export default {
    components: {
        
    },
    props: {
      message: {
        type: Object,
        default: () => ({
          title: '',
          image: '',
          text: ''
        })
      }
    },
    data() {
        return {
          showModal: false
        };
    },
    watch: {
      message: {
          handler(newVal) {
            console.log('Message prop updated:', newVal);
            this.showModal = true;
            // const modalElement = document.getElementById('transactionPendingPopup');
            // if (modalElement) {
            //   const modal = new bootstrap.Modal(modalElement);
            //   modal.show();
            // }
          },
          deep: true
        }
    },
    methods: {
    }
};
</script>

<style scoped>
.modal-content {
    border: 0px;
    background-color: transparent;
}

.modal.zoom-in .modal-dialog {
    transform: scale(0.8);
    transition: transform 0.3s ease-out;
}

.modal.show.zoom-in .modal-dialog {
    transform: scale(1);
}

.text-recieved {
    font-size: 16px;
    font-weight: 500;
    justify-content: center; 
    display: flex; 
    flex-direction: column; 
    text-align: center;
}

.text-header {
    justify-self: center; 
    font-size: 28px; 
    font-weight: 600;
}

.loading-image {
    height: 200px; 
    width: auto;
}

.modal {
    background-color: rgba(3, 3, 3, 0.8);
}

.hidden-audio {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

</style>
  
