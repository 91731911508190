import { defineStore } from 'pinia';
import axios from 'axios';

export const kfServiceStore = defineStore({
  id: 'kfservice',
  state: () => ({
    _accessToken: '',
  }),
  actions: {
    async fetchAccessToken() {
      if (this._accessToken!='') {
        return;
      }
      try {
        const apiUrl = process.env.VITE_KF_SERVICE_BASE_URL + '/authen';

        const requestBody = {
            username: 'multiverseexpert_prd',
            password: 'BLLJDUuy0HAILZ7'
        };

        const response = await axios.post(apiUrl, requestBody, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        //console.log('Response data:', response.data);

        this._accessToken = response.data.access_token;

        } catch (error) {
            console.error('Error:', error);
        }   
    },
  },
  getters: {
    accessToken: (state) => state._accessToken,
  },
  
});
