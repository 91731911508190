<!-- RewardTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ rewards.length }} Rewards </h4>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="rewards.length > 0">
          <div class="product-list">
            <div v-for="(reward, index) in rewards">
              <RewardItem
                :key="index"
                :reward="reward"
                @rewardClaimed="fetchData"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import RewardItem from '@/components/RewardItem.vue';
import { useStore } from '@/stores/index.ts';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        RewardItem,
    },
    props: {
        amounts: {}
    }, 
    data() {
        return {
            rewards: []
        };
    },
    watch: {
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }

        // watch(() => this.rewards.length, () => {this.fetchData();});
    },
    methods: {
        async fetchData() {
            try {
                const store = useStore();

                const accountAddress =  store.getWalletAddress;

                
                //console.log("accountAddress" + accountAddress);

                // const requestBody = {
                //     reward_id: '660e60ed513bd912900d2af7',
                //     wallet: '0x41A35914aC03a15Eb68f98a19Cc9e2700D281B5D'
                // };

                // console.log('apiUrl:', apiUrl);
                const apiUrl = `https://reward-portal.kryptofighters.io/rewards?wallet=${accountAddress}`;
                // console.log('apiUrl:', apiUrl);
                const response = await axios.get(apiUrl);
                console.log("response reward " , response);

                this.rewards = response.data.filter(item => !item.claimed).map(item => {
                    // console.log(item);
                    return {
                        reward_id: item._id,
                        claimed: item.claimed,
                        item_id: item.item_id,
                        amount: item.amount
                    };
                });

                //console.log("response reward " , this.rewards.length);

            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  