export function getClassImageUrl(id) {
    let path = "";
    switch (id) {
        case 1:
            path = new URL('/src/assets/class-icon-allRounder.png', import.meta.url);
            break;
        case 2:
            path = new URL('/src/assets/class-icon-bodyguard.png', import.meta.url);
            break;
        case 3:
            path = new URL('/src/assets/class-icon-duelist.png', import.meta.url);
            break;
        case 4:
            path = new URL('/src/assets/class-icon-stalker.png', import.meta.url);
            break;
        case 5:
            path = new URL('/src/assets/class-icon-acrobat.png', import.meta.url);
            break;
        case 6:
            path = new URL('/src/assets/class-icon-trickster.png', import.meta.url);
            break;
        case 7:
            path = new URL('/src/assets/class-icon-destroyer.png', import.meta.url);
            break;
        case 8:
            path = new URL('/src/assets/class-icon-brawler.png', import.meta.url);
            break;
    }
    return path;
}

export function getElementImageUrl(element) {
    let path = "";
    switch (element) {
      case "FIRE":
        path = new URL('@/assets/icons/icon-element-fire.png', import.meta.url);
        break;
      case "EARTH":
        path = new URL('@/assets/icons/icon-element-earth.png', import.meta.url);
        break;
      case "WIND":
        path = new URL('@/assets/icons/icon-element-wind.png', import.meta.url);
        break;
      case "WATER":
        path = new URL('@/assets/icons/icon-element-water.png', import.meta.url);
        break;
      case "DARK":
        path = new URL('@/assets/icons/icon-element-dark.png', import.meta.url);
        break;
      case "ELECTRIC":
        path = new URL('@/assets/icons/icon-element-electric.png', import.meta.url);
        break;
      case "CHAOS":
        path = new URL('@/assets/icons/icon-element-chaos.png', import.meta.url);
        break;
      case "LIGHT":
        path = new URL('@/assets/icons/icon-element-light.png', import.meta.url);
        break;
      case "METAL":
        path = new URL('@/assets/icons/icon-element-metal.png', import.meta.url);
        break;
    }
    return path;
  }
  