<template>
  <div
    id="filterPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="filterPopup"
    aria-hidden="true"
    @click.self="refreshFilter">
    <div class="modal-dialog modal-dialog-right modal-dialog-scrollable">
      <div class="modal-content" style="border-radius: 0;">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Filters
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="refreshFilter"
          />
        </div>
        <div class="modal-body" style="padding: 1.5rem; display: flex; flex-direction: column; gap: 10px;">
          <div id="Parts" class="" style="width: 100%">
            <button
              class="btn btn-secondary d-flex justify-content-between align-items-center" 
              style="background-color: #181C27; border-color: #5A5D66; color: #ffffff; padding: 10px; border-radius: 8px; width: 100%"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="mx-auto">Search body parts</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed" style="right: 0">
                <path d="M480-360 280-559.33h400L480-360Z"/>
              </svg>
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="classDropdown"
              style="max-height: 300px; overflow-y: auto; width: 90%;"
            >
              <li
                v-for="option in getAllPart()"
                :key="option.part_id"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="isSelectedPart(option.part_id)"
                  style="margin-left: 10px; width: 1.5rem; height: 1.5rem;"
                  @change="toggleSelectedPart(option)"
                >
                <img
                  :src="`../images/parts/${option.part_id}.png`"
                  alt="Product Icon"
                  class="img-fluid"
                  style="margin-left: 10px; width: 30px; height: auto;"
                >
                <label
                  class="form-check-label"
                  style="margin-left: 6px;"
                >{{ option.part_name }}</label>
              </li>
            </ul>
            <div v-if="selectedParts.length > 0" style="display: flex; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
              <div v-for="(part, index) in selectedParts" :key="index" class="detail-text content-text" style="">
                <span class="">
                  <img class="" :src="`../images/parts/${part.part_id}.png`" width="20" alt="" />
                </span>
                <span class="">
                  {{ part.part_name }}
                </span>
                <button @click="toggleSelectedPart(part)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed">
                    <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z"/>
                  </svg>
                </button>
                
              </div>
            </div>
          </div>
          <div id="Elements" class="container-box">
            <div class="collapse-trigger" data-bs-toggle="collapse" data-bs-target="#elementsContent" aria-expanded="false" aria-controls="elementsContent" @click="toggleCollapse(0)">
              <div class="content-box content-header">Element</div>
              <div :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': isElementsCollapsed }]">
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                  <path d="M480-360 280-559.33h400L480-360Z"/>
                </svg>
              </div>
            </div>
            <div class="collapse content-box" id="elementsContent" :class="{ show: isElementsCollapsed }">
              <div style="display: flex; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
                <label
                  v-for="element in allElements"
                  :key="element"
                  class="btn btn-secondary dropdown-filter-toggle"
                  :class="{ active: isSelectedElement(element) }"
                  style="padding: 5px 10px; cursor: pointer; display: inline-flex; align-items: center;"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    :value="element.toUpperCase()"
                    v-model="selectedElements"
                    @change="toggleSelectedElement(element)"
                    style="display: none;"
                  />
                  <span style="padding-right: 5px;">
                    <img width="15" :src="getElementImageUrl(element)" alt="Element icon" />
                  </span>
                  <span class="content-text">{{ element }}</span>
                </label>
              </div>
            </div>
          </div>
          <div id="Classes" class="container-box">
            <div class="collapse-trigger" data-bs-toggle="collapse" data-bs-target="#classesContent" aria-expanded="false" aria-controls="classesContent" @click="toggleCollapse(1)">
              <div class="content-box content-header">Class</div>
              <div :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': isClassesCollapsed }]">
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                  <path d="M480-360 280-559.33h400L480-360Z"/>
                </svg>
              </div>
            </div>
            <div class="collapse content-box" id="classesContent" :class="{ show: isClassesCollapsed }">
              <div style="display: flex; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
                <label
                  v-for="target in allClasses"
                  :key="target.id"
                  class="btn btn-secondary dropdown-filter-toggle"
                  :class="{ active: isSelectedClass(target.id) }"
                  style="padding: 5px 10px; cursor: pointer; display: inline-flex; align-items: center;"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    :value="target.id"
                    v-model="selectedClasses"
                    @change="toggleSelectedClass(target.id)"
                    style="display: none;"
                  />
                  <span style="padding-right: 5px;">
                    <img width="15" :src="getClassImageUrl(target.id)" alt="Class icon" />
                  </span>
                  <span class="content-text">{{ target.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div id="Sockets" class="container-box">
            <div class="collapse-trigger" data-bs-toggle="collapse" data-bs-target="#socketsContent" aria-expanded="false" aria-controls="socketsContent" @click="toggleCollapse(2)">
              <div class="content-box content-header">Socket</div>
              <div :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': isSocketsCollapsed }]">
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                  <path d="M480-360 280-559.33h400L480-360Z"/>
                </svg>
              </div>
            </div>
            <div class="collapse content-box" id="socketsContent" :class="{ show: isSocketsCollapsed }">
              <div style="display: flex; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
                <label
                  v-for="target in allSockets"
                  :key="target.id"
                  class="btn btn-secondary dropdown-filter-toggle"
                  :class="{ active: isSelectedSocket(target.id) }"
                  style="padding: 5px 10px; cursor: pointer; display: inline-flex; align-items: center;"
                >
                  <input
                    type="checkbox"
                    class="btn-check"
                    :value="target.id"
                    v-model="selectedSockets"
                    @change="toggleSelectedSocket(target.id)"
                    style="display: none;"
                  />
                  <span style="padding-right: 5px;">
                    <img width="15" :src="`/images/runes/shape-${target.id}.png`" alt="Class icon" />
                  </span>
                  <span class="content-text">{{ target.name }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
  
<script>
import { useCardStore } from '@/stores/card.ts';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';

export default {
  props: {
    showModal: Boolean,
    selectedFilter: {
        type: Array,
        required: true,
        default: () => [{type: 'element', data: []},
                        {type: 'class', data: []},
                        {type: 'part', data: []},
                        {type: 'socket', data: []}]
    }
  },
  watch: {
    selectedFilter: {
      handler(newVal, oldVal) {
        // Handle changes to selectedFilter here
        console.log('selectedFilter changed:', newVal);
        this.selectedElements = newVal.find(e=>e.type === 'element').data;
        this.selectedClasses = newVal.find(c=>c.type === 'class').data;
      },
      deep: true // Use deep watch if selectedFilter contains objects or nested arrays
    }
  },
  data() {
    return {
      partsOption: [],
      selectedParts: [],

      allElements:['Fire', 'Water', 'Electric', 'Wind', 'Earth'],
      selectedElements: [],
      isElementsCollapsed: true,

      allClasses:[{ id: 1, name: 'AllRounder' },
                  { id: 2, name: 'Bodyguard' },
                  { id: 3, name: 'Duelist' },
                  { id: 4, name: 'Stalker' },
                  { id: 5, name: 'Acrobat' },
                  { id: 6, name: 'Trickster' },
                  { id: 7, name: 'Destroyer' },
                  { id: 8, name: 'Brawler' }],
      selectedClasses: [],
      isClassesCollapsed: true,

      allSockets:[{ id: 1, name: 'Star' },
                  { id: 2, name: 'Rhombus' },
                  { id: 3, name: 'Triangle' },
                  { id: 4, name: 'Circle' },
                  { id: 5, name: 'Hexagon' }],
      selectedSockets: [],
      isSocketsCollapsed: true,
    };
  },
  methods: {
    closeModal(){
      console.log('closeModal');
    },
    refreshFilter() {
      const _data = [{type: 'element', data: this.selectedElements},
                      {type: 'class', data: this.selectedClasses},
                      {type: 'part', data: this.selectedParts},
                      {type: 'socket', data: this.selectedSockets}];
        this.$emit('refreshFilter', _data);
        this.closeModal();
    },
    toggleCollapse(index) {
      switch(index){
        case 0: this.isElementsCollapsed = !this.isElementsCollapsed; break;
        case 1: this.isClassesCollapsed = !this.isClassesCollapsed; break;
        case 2: this.isSocketsCollapsed = !this.isSocketsCollapsed; break;
      }
    },
    getAllPart(){
      return useCardStore().getAllPartData();
    },
    isSelectedPart(partId) {
      return this.selectedParts.some(part => part.part_id === partId);
    },
    toggleSelectedPart(part) {
      if (this.selectedParts.some(p => p.part_id === part.part_id)) {
          this.selectedParts = this.selectedParts.filter(f => f.part_id !== part.part_id);
      } else {
          this.selectedParts.push(part);
      }
    },
    getElementImageUrl(element){
        return getElementImageUrl(element.toUpperCase());
    },
    isSelectedElement(element) {
      return this.selectedElements.includes(element.toUpperCase());
    },
    toggleSelectedElement(element) {
      console.log('toggleSelectedElement', this.selectedElements);
    },
    getClassImageUrl(classId){
        return getClassImageUrl(classId);
    },
    isSelectedClass(classId) {
      return this.selectedClasses.includes(classId);
    },
    toggleSelectedClass(classId) {
      console.log('toggleSelectedClass', this.selectedClasses);
    },
    isSelectedSocket(socketId) {
      return this.selectedSockets.includes(socketId);
    },
    toggleSelectedSocket(socketId) {
      console.log('toggleSelectedSocket', this.selectedSockets);
    },
  },
  async mounted() {
    try {
      // await useCardStore().fetchPartMapper().then(() => {
      //   this.partsOption = useCardStore().getAllPartData();
      // // });
      // console.log('this.selectedFilter', this.selectedFilter);
      // this.selectedElements = this.selectedFilter.find(e=>e.type === 'element').data;
      // this.selectedClasses = this.selectedFilter.find(c=>c.type === 'class').data;
      await useCardStore().fetchPartMapper();
    } 
    catch (error) {
      console.error('Error fetching card data:', error);
    }
  },
};
</script>

<style scoped>
  .modal-dialog{
    transform: none !important;
  }

  .modal-dialog-right {
    position: fixed;
    /* top: 50%; */
    right: 0;
    /* transform: translateY(-50%); */
    height: 100%;
    width: 40%;
    margin: 0;
  }

  .modal-content {
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateX(100%);
    opacity: 0;
    height: 100%;
  }

  .modal.show .modal-content {
    transform: translateX(0);
    opacity: 1;
  }

  .collapse-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
  }

  .collapse-trigger-toggle {
    transition: transform 0.3s;
  }

  .collapse-trigger-toggle.collapse-trigger-toggle-transform {
    transform: rotate(180deg);
  }

  .detail-text {
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    padding: 4px 8px;
    gap: 5px; 
  }

  .content-text{
    color: white;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }

  .container-box{
    border-bottom: 0.5px solid darkgray;
    padding-top: 10px;
    /* padding-bottom: 20px; */
  }

  .content-box{
    padding-bottom: 20px;
  }

  .content-header{
    font-size: 18px;
    font-weight: 500;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .collapse.show {
    display: block;
  }

  @media (max-width: 768px) {
    .modal-dialog-right {
      position: fixed;
      /* top: 50%; */
      right: 0;
      /* transform: translateY(-50%); */
      height: 100%;
      width: 100%;
      margin: 0;
    }
  }
</style>