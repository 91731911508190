<template>
  <div class="page-content content-wrap">
    <!-- <img class="content-bg" src="/src/assets/backgrounds/background-default.png" alt="">-->
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div class="collection-info">
        <div class="d-flex align-items-left">
          <img
            :src="`../images/item/artifact-logo.png`"
            alt="NFT Icon"
            class="header-icon mr-3"
          >
          <h1>Artifacts</h1>
        </div>
        <!-- <div class="text-secondary">
          The Fighter is represented as a NFT character, a unique digital asset that can
          be
          <br>battled, coach, collected, and even used to earn resources & collectibles.
        </div> -->
        <div class="d-flex justify-content-between align-items-center default-row flex-wrap">
          <div v-if=" total_supply">
            <h4>{{ total_supply }} Tickets </h4>
          </div>
        </div>


        <div class="card">
          <div class="card-body">
            <div v-if="isLoading">
              <div style="display: flex; flex-direction: row; overflow-x: auto;">
                <div
                  v-for="index in 6"
                  :key="index"
                >
                  <v-skeleton-loader
                    :type="'card'"
                    :animation="true"
                    :width="200"
                    :height="360"
                    theme="dark"
                    style="margin-right: 24px;"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="products.length > 0" class="product-list">
                <ProductItem
                  v-for="(product, index) in products"
                  :key="product.id"
                  :product="product"
                />
              </div>
              <div v-else>
                <img
                  src="~@/assets/no-data.png"
                  alt="no data"
                  style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

import Web3 from 'web3';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import { useStore } from '@/stores/index.ts';

import {shortenedWalletAddress_rarible} from '@/utils/walletUtils.js';

export default {
    components: {
        ProductItem,
        getClassImageUrl,
        
    }, data() {
        return {
            products: [],
            
            selectedOrderBy: 1,
            isLoading: true,
            total_supply: null
            
        };
    },
    mounted() {
        this.fetchNFTData();
        this.fetchStats();
    },
    methods: {
        async fetchStats() {
            const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
            const products = [];
            try{

                const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_ARTIFACT_CONTRACT_ADDRESS);

                const totalSupply = await nftContract.methods.totalSupply().call();
                console.log(`Total NFTs in the contract: ${totalSupply}`);
                this.total_supply = totalSupply;

            } catch (error) {
                console.error('Error fetching total supply:', error);
            }
        },
        async fetchNFTData() {
            try {
                const store = useExchangeStore();
                store.fetchExchangeRates();
                const wallet = useStore().getWalletAddress;

                const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

                // const response = await axios.get('https://api.opensea.io/api/v2/listings/collection/kf-genesis-ticket/all?limit=100', {
                //     headers: {
                //         'accept': 'application/json',
                //         'x-api-key': process.env.VITE_OPENSEA_API_KEY
                //     }
                // });

                const collection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
                const apiUrl = `https://testnet-api.rarible.org/v0.1/items/byCollection?collection=KROMA:${collection}`;
                const response = await axios.get(apiUrl, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });

                console.log("atfResponse market" , response);

                this.products = response.data.items.filter(f=>f.bestSellOrder && f.bestSellOrder !== null && f.bestSellOrder !== undefined).map(listing => {
                  const date = new Date(listing.lastUpdatedAt);
                  const unixTimestamp = Math.floor(date.getTime() / 1000);
                  const tokenID = listing.tokenId;
                  const dateString = timeAgo(unixTimestamp);
                  const price = parseFloat(listing.bestSellOrder.makePrice);
                  const usdPrice = convertToUSD(price, "JAB", ethToUsdExchangeRate, maticToUsdExchangeRate);
                  const collection = listing.collection.slice(6).toLowerCase();
                  const isFighterProduct = false;
                  var itemID = 0;

                  switch(collection){
                    case process.env.VITE_TICKET_CONTRACT_ADDRESS.toLowerCase(): itemID = 100; break;
                    case process.env.VITE_STONE_CONTRACT_ADDRESS.toLowerCase():
                    case process.env.VITE_ARTIFACT_CONTRACT_ADDRESS.toLowerCase(): itemID = tokenID; break;
                  }

                  return {
                    id: tokenID,
                    price: price,
                    currency: "JAB",
                    listDate: listing.lastUpdatedAt,
                    dateString : dateString,
                    usdPrice : 0,
                    isFighter: isFighterProduct,
                    itemId: itemID,
                    isOwner: shortenedWalletAddress_rarible(listing.maker) == wallet,
                    totalStock: listing.totalStock
                  };
                });
                //this.products = this.products.slice(0, 50);
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.product-item {
    /* Add additional styling for each grid item if needed */
}

.text-secondary {
    font-size: 16px;
    color: #A4A5A5 !important;
    font-weight: 300;
}


</style>