<template>
  <div
    id="fittingPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="fittingPopup"
    aria-hidden="true"
    @click.self="selectButton"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 600px;">
      <div class="modal-content" style="background-color: #262252;">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Fitting room
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <div class="" style="background-color: #101327; height: 70px; width: 100%; display: inline-flex; align-items: center; justify-content: center; font-weight: 600; color: #df4bd3;">
            Equip your gear to increase your score!
          </div>
          <div class="d-flex" style="width: 100%; padding: 15px 25px; gap: 10px;">
            <div style="width: 55%; display: flex; flex-direction: column; justify-content: space-between; gap: 5px;">
              <div v-for="(suit, index) in selectedSuit" class="d-flex" style="gap: 10px;">
                <div class="d-flex justify-content-center align-items-center selected-suit" style="">
                  <div class="d-flex justify-content-center align-items-center" style="position: relative; width: 100%; max-width: 57px; height: 100%; background-color: #101327; border-radius: 8px;">
                    <img 
                      width="80%" 
                      :src="(suit.item)? `../images/suits/${suit.item.item_code}.png`:`../images/suits/default/ITM_${suit.part}_000.png`" 
                      alt="Head icon" 
                      @error="this.src=`../images/suits/default/ITM_${suit.part}_000.png`" 
                      style="" 
                    />
                  </div>
                  <div style="font-weight: 500; font-size: 15px;">{{ defaultPartName[index] }}</div>
                </div>
                <div class="d-flex justify-content-center align-items-center" style="background-color: #101327; border-radius: 8px; width: 100%; font-size: 15px; flex-direction: column; line-height: 1.3rem; font-weight: 500;">
                  <div style="color: yellow;"> [Rarity] </div>
                  <div> Name </div>
                  <div> Score </div>
                </div>
              </div>
            </div>
            <div class="d-flex" style="width: 45%; flex-direction: column;">
              <div style="font-size: 16px; font-weight: 600; text-align: center;">
                Your body gear
              </div>
              <div  style="background-color: #101327; height: 100%; border-radius: 8px; margin-top: 5px;">
                <div class="item-container">
                  <label v-for="item in totalSuit"
                    :key="item.item_code"
                    class="item"
                    :class="{ active: isSelectedSuit(item.item_code) }"
                    style=""
                  >
                    <input
                      type="checkbox"
                      class="btn-check"
                      @change="toggleSelectedSuit(item)"
                      style=""
                    />
                    <!-- <span style="padding-right: 5px;">
                      <img width="50px" :src="`../images/suits/${item.item_code}.png`" alt="Head icon" style="" />
                    </span> -->
                    <img class="item-image" width="auto" :src="`../images/suits/${item.item_code}.png`" alt="Head icon" style="" />
                  </label>
                </div>
                
              </div>
            </div>
          </div>
          <div class="" style="background-color: #101327; height: 40px; width: 100%; display: inline-flex; align-items: center; justify-content: center; font-weight: 600; color: yellow; margin-bottom: 20px;">
            Total score : {{ score }}
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';


export default {
    components: {
    },
    props: {
        showModal: Boolean,
        totalFragment: {
            type: Object,
            required: true
        },
        totalSuit: {
            type: Object,
            required: true
        },
        selectedSuit: {
            type: Object,
            required: true
        }
        // selectedDefault: {
        //     type: Number,
        //     required: true,
        //     default: () => 0
        // }
    },
    data() {
        return {
          score: 0,
          defaultPartName: ['HeadSet', 'Armor', 'Gloves', 'Shoes']
        };
    },
    mounted() {
      this.calculateScore();
    },
    methods: {
        closeModal(){
          console.log('closeModal');
          // this.$refs.Close.click();
        },
        selectButton() {
            console.log('Closing modal'); // Add this line
            this.$emit('selectButton', 1);
            this.closeModal();
        },
        isSelectedSuit(code) {
          return this.selectedSuit.some(suit => (suit.item?.item_code?? "") === code);
        },
        toggleSelectedSuit(item) {
          if(item.item_code.includes("H")){
            var hPart = this.selectedSuit.find(h => h.part === 'H');
            if(hPart){
              const currentItemCode = hPart.item?.item_code ?? "";
              hPart.item = (currentItemCode === item.item_code) ? null : item;
            }
          }
          else if(item.item_code.includes("A")){
            var aPart = this.selectedSuit.find(a => a.part === 'A');
            if(aPart){
              const currentItemCode = aPart.item?.item_code ?? "";
              aPart.item = (currentItemCode === item.item_code) ? null : item;
            }
          }
          else if(item.item_code.includes("G")){
            var gPart = this.selectedSuit.find(g => g.part === 'G');
            if(gPart){
              const currentItemCode = gPart.item?.item_code ?? "";
              gPart.item = (currentItemCode === item.item_code) ? null : item;
            }
          }
          else if(item.item_code.includes("S")){
            var sPart = this.selectedSuit.find(s => s.part === 'S');
            if(sPart){
              const currentItemCode = sPart.item?.item_code ?? "";
              sPart.item = (currentItemCode === item.item_code) ? null : item;
            }
          }

          this.calculateScore();
          console.log("toggleSelectedSuit ", this.selectedSuit);
        },
        calculateScore(){
          this.score = 0;
          this.selectedSuit.forEach(suit => {
            if(suit.item){
              this.score += suit.item.score;
            }
          });
        }
    }
};
</script>

<style scoped lang="scss">
  .no-padding {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
  }
  
  .dropdown-filter-toggle {
    background-color: #101327;
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 600;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

  .item-container {
    // display: flex;
    // flex-wrap: wrap;
    // margin: 10px;
    // justify-content: space-between;
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    width: 100%;
    grid-gap: 5px;
  }

  .item {
    width: auto; /* Fixed width for each item */
    height: auto; /* Fixed height for each item */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    text-align: center;
    // padding: 5px 5px; 
    cursor: pointer; 
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  .item.active {
    background-color: #ff65fc55;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
  }
  
  .item-image {
    width: auto;
    max-width: 50px;
  }

  .selected-suit {
    flex-direction: column; 
    height: 80px; 
    width: 80px;
  }

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }

    .item-container {
      grid-template-columns: auto auto;
    }

    .selected-suit {
      width: 100px;
    }
}
</style>
  
