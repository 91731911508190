<template>
  <div
    id="profilePicturePopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="profilePicturePopup"
    aria-hidden="true"
  >
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Generate Profile Picture
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <div class="modal-body">
          <div style="display: flex; justify-content: center; align-items: center; flex-direction:column; gap: 20px;">
            <div class="crop-container">
              <img id="genProfile" :src="imgUrl" alt="order Image">
            </div>
            <button class="btn btn-primary" @click="downloadCroppedImage" :disabled="true">Download</button>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';


export default {
    components: {
    },
    props: {
        showModal: Boolean,
        imgUrl: {
            type: String,
            default: ''
        },
        classId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
        };
    },
    mounted(){
      if(this.classId != 0){
          this.getImageCropSize();
      }else{
          watch(() => this.classId, () => {
              this.getImageCropSize();
          });
      }
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        getImageCropSize(){
          const getElement = document.getElementById("genProfile");
          var crop = {};
          // console.log(this.classId);
          switch(this.classId){
              case 1: crop = { top: '-75px', left: '10px', height: '800%'}; break;// ard
              case 2: crop = { top: '-55px', left: '10px', height: '800%'}; break; // bdg
              case 3: crop = { top: '-100px', left: '10px', height: '800%'}; break; // dul
              case 7: crop = { top: '-70px', left: '10px', height: '800%'}; break; // dty
              case 4: crop = { top: '-85px', left: '10px', height: '800%'}; break; // stk
              default: crop = { top: '-90px', left: '10px', height: '800%'}; break;
          }
          if (getElement && crop) {
            getElement.style.top = crop.top;
            getElement.style.left = crop.left;
            getElement.style.height = crop.height;
          }
        }
    }
};
</script>

<style scoped lang="scss">

.modal-dialog {
    max-width: 650px;
    max-height: 500px;
}

.modal-content {
    border: 0px;
}
.modal-body {
    // padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.crop-container {
    margin: 5px 25px;
    width: 120px; /* Width of the cropped area */
    height: 120px; /* Height of the cropped area */
    overflow: hidden;
    // border-radius: 50%;
    border: 0.5px solid white;
    position: relative;
}
.crop-container img {
    top: -50px; /* Adjust this to position the image within the cropped area */
    left: -50px; /* Adjust this to position the image within the cropped area */
    width: auto; /* Ensure the image scales properly */
    height: 100%; /* Ensure the image scales properly */
    /* background-color: #2c2a3d; */
    position: absolute;
}

#genProfile {
    /* Ensure the image covers the container */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop image to cover the container */
  }

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }
}
</style>
  
