<template>
    <div class="page-content content-wrap" style="/*padding: 50px 0px;*/">
      <!-- <div class="vertical-opacity-overlay" /> -->
      <!-- <div class="" style="width: 100%; height: 100%; backdrop-filter: blur(5px); position: absolute;"/> -->
      <img class="icon-image-kf" :src="`../images/workshop/fw-icon.png`" alt="workshop icon" style="z-index: 1;" />
      <div class="main-content" style="z-index: 1;">
        <!-- <div class="d-flex align-items-left">
          <h1>Fighter Workshop</h1>
        </div> -->
        <div class="menu-content" style="">
            <div>
                <div class="justify-content-end" style="display: flex;">
                    <h4 class="content-font">YOUR SCORE : 0</h4>
                </div>
                <div class="" style="width: 100%; height: auto; display: flex; justify-content: end; margin-top: 10px;">
                    <div 
                        class="center-content d-flex align-items-end" 
                        style="min-height:400px;/* width:30%; */flex-direction:column;align-items:center; gap: 30px; justify-content: space-between;">
                        <div 
                            class="d-flex" 
                            style="/* width:auto; *//* height: auto; *//* display: flex; */">
                            <button 
                                class="workshop-menu-list" 
                                id="forgeBtn" 
                                data-bs-toggle="modal" 
                                data-bs-target="#craftPopup">
                                <img 
                                    class="workshop-menu-list-img" 
                                    :src="`../images/workshop/forge-button.png`"
                                    alt="forge icon" style="height: 140px;">
                            </button>
                        </div>
                        <div 
                            class="d-flex" 
                            style="height: 118px;/* margin-top: -118px; */width: 100%;">
                            <div 
                                style="width:50%;">
                                <button 
                                    class="workshop-menu-list" 
                                    id="outfitBtn" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#fittingPopup" 
                                    style="height:100%;clip-path:polygon(0 0, 100% 0, 77% 100%, 0% 100%);width: 110%;">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="`../images/workshop/outfit-button.png`"
                                        alt="outfit icon" 
                                        style="">
                                </button>
                            </div>
                            <div 
                                style="width: 50%;position: relative;">
                                <button 
                                    class="workshop-menu-list" 
                                    id="inventoryBtn" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#craftPopup" 
                                    style="height: 118px;clip-path:polygon(23% 0, 100% 0, 100% 100%, 0% 100%);width: 110%;position: absolute;right: 0;">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="`../images/workshop/inventory-button.png`"
                                        alt="inventory icon" 
                                        style="" >
                                </button>
                            </div>
                        </div>
                        <div 
                            class="d-flex" 
                            style="height: 118px;/* margin-top: -118px; */width: 100%;">
                            <div 
                                style="width:50%;">
                                <button 
                                    class="workshop-menu-list" 
                                    id="questBtn" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#fittingPopup" 
                                    style="height:100%;clip-path:polygon(0 0, 100% 0, 77% 100%, 0% 100%);width: 110%;">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="`../images/workshop/quest-button.png`"
                                        alt="quest icon" 
                                        style="">
                                </button>
                            </div>
                            <div 
                                style="width: 50%;position: relative;">
                                <button 
                                    class="workshop-menu-list" 
                                    id="rewardBtn" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#fittingPopup" 
                                    style="height: 118px;clip-path:polygon(23% 0, 100% 0, 100% 100%, 0% 100%);width: 110%;position: absolute;right: 0;">
                                    <img 
                                        class="workshop-menu-list-img" 
                                        :src="`../images/workshop/reward-button.png`"
                                        alt="reward icon" 
                                        style="" >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%; margin-top: -20px">
                    <ul class="d-flex menu-bottom-content" style="">
                        <li class="menu-bottom-list">
                            <img class="menu-bottom-list-icon" :src="`../images/workshop/guide-icon.png`" alt="guide icon" style="" />
                            <span class="menu-bottom-list-font"> Guide </span>
                        </li>
                        <li class="menu-bottom-list">
                            <img class="menu-bottom-list-icon" :src="`../images/workshop/about-icon.png`" alt="about icon" style="" />
                            <span class="menu-bottom-list-font"> About </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <img class="icon-image-brl" :src="`../images/workshop/BRL.png`" alt="brawler" style="" />
    </div>
    <CraftPopup @selectButton="selectButton" @refreshTotal="fetchData"/>
    <FittingPopup :totalSuit="totalSuit" :selectedSuit="selectedSuit" @selectButton="selectButton"/>
</template>
    
<script>
import { useStore } from '@/stores/index.ts';
import CraftPopup from "@/components/popup/CraftPopup.vue";
import FittingPopup from "@/components/popup/FittingPopup.vue";
import { watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        CraftPopup,
        FittingPopup
    }, data() {
        return {
            isCraftSelected : false,
            isFittingSelected: false,
            // totalFragment: [{code:'ITM_FM_MCC', amount:0},
            //                 {code:'ITM_FM_GLB', amount:0},
            //                 {code:'ITM_FM_HLB', amount:0},
            //                 {code:'ITM_FM_FST', amount:0}],
            totalSuit: [{item_code:'ITM_H_001', item_id: 1, score: 10},
                        {item_code:'ITM_G_001', item_id: 2, score: 10},
                        {item_code:'ITM_H_002', item_id: 5, score: 20},
                        {item_code:'ITM_S_002', item_id: 7, score: 20},
                        {item_code:'ITM_A_003', item_id: 7, score: 30},
                        {item_code:'ITM_S_004', item_id: 7, score: 40}],
            selectedSuit: [{part:'H', item: null},
                            {part:'A', item: null},
                            {part:'G', item: null},
                            {part:'S', item: null}]
        };
    },
    mounted() {
        // if(useStore().getWalletAddress!=''){
        //     this.fetchData();
        // }else{
        //     watch(() => useStore().getWalletAddress, () => {
        //         this.fetchData();
        //     });
        // }
    },
    methods: {
        selectButton(index){
            if(index == 0){
                this.isCraftSelected = !this.isCraftSelected;
            }
            else if(index == 1){
                this.isFittingSelected = !this.isFittingSelected;
            }
        },
        async fetchData() {
            try {
                const fragmentCollection = "0x6c8Eb0f85D7Fa338C267b559cC8B5055E8e26754";
                const fragmentRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${useStore().getWalletAddress}`],
                        collections: [`KROMA:${fragmentCollection}`]
                    }
                };
                const fragmentApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const fragmentResponse = await axios.post(fragmentApiUrl, fragmentRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("fragmentResponse ",fragmentResponse);

                fragmentResponse.data.ownerships.forEach(nft => {
                    var itemCode = '';
                    switch(nft.tokenId){
                        case "0": itemCode = 'ITM_FM_HLB'; break;
                        case "1": itemCode = 'ITM_FM_GLB'; break;
                        case "2": itemCode = 'ITM_FM_MCC'; break;
                    }

                    if(itemCode !== ''){
                        this.totalFragment.find(f=>f.code === itemCode).amount = Number(nft.value);
                    }
                });

                // this.totalFragment = await Promise.all(fragmentResponse.data.ownerships.map(nft => {
                //     var itemCode = '';
                //     switch(nft.tokenId){
                //         case "0": itemCode = 'ITM_FM_HLB'; break;
                //         case "1": itemCode = 'ITM_FM_GLB'; break;
                //         case "2": itemCode = 'ITM_FM_MCC'; break;
                //     }

                //     if(itemCode !== ''){
                //         return {
                //             code: itemCode,
                //             amount: nft.value
                //         }
                //     }

                //     return null;
                // }));
                // this.totalFragment = this.totalFragment.filter(item => item !== null);
                console.log(this.totalFragment);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>
  
<style scoped>
    @font-face {
        font-family: 'PressStart';
        src: url('/fonts/PressStart.ttf') format('truetype');
    }

    @font-face {
        font-family: 'LilitaOne';
        src: url('/fonts/LilitaOne.ttf') format('truetype');
    }

    @font-face {
        font-family: 'OpenSans';
        src: url('/fonts/OpenSans.ttf') format('truetype');
    }

    .content-font {
        font-family: 'OpenSans'; 
        /* text-shadow: 
                    -.5px -.5px 0 #FF11D9,
                    .5px -.5px 0 #FF11D9,
                    -.5px .5px 0 #FF11D9,
                    .5px .5px 0 #FF11D9; */
        /* margin-left: 20px; */
        text-align: center;
        font-size: 20px;
    }

    .head-font {
        font-family: 'LilitaOne'; 
        color: #FFDE02;
        text-shadow: 
                    3px 3px 0 black, 
                    .5px -.5px 0 black, 
                    -.5px .5px 0 black, 
                    .5px .5px 0 black;
        text-align: center;
        font-size: 60px;
    }

    .menu-bottom-list-font {
        font-family: 'OpenSans'; 
        color: white;
        font-size: 20px;
        font-weight: 900 !important;
    }

    .page-content {
        position: relative; /* Ensure positioning context */ 
        background-image: url('../images/workshop/workshop-bg.png'); /* For browsers that do not support backdrop-filter */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        overflow-y: hidden;
    }

    .content-bar-bg {
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        padding-top: 0px !important;
        background-image: url('../images/workshop/workshop-content-bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
    }

    .workshop-menu-list {
        /* padding: 10px 15px;
        background-color: rgba(46, 27, 91, 0.8);
        border: 4px solid #ccc;
        margin: 5px 0;  */
        cursor: pointer;
        transition: background-color 0.3s;
        width: auto;
        height: 150px;
    }

    .workshop-menu-list:hover {
        /* background-color: #a33af4; */
        /* background: linear-gradient(20deg, #df4bd3 20%, #a33af4 60%); */
        transform: scale(1.05);
    }

    .workshop-menu-list-img {
        /* max-width: 100%;  */
        height: inherit; 
        display: block;
    }

    .center-content {
        min-height: 400px; 
        /* width: 100%; */
        flex-direction: column;
    }
    
    /* .content-image-icon {
        position: relative;
        height: auto; 
        min-height: 400px;
        overflow: hidden;
    }

    .content-image-icon img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%); 
        width: auto;
        height: 100%; 
    }

    .content-image-icon img:first-child {
        top: 0; 
    }

    .content-image-icon img:last-child {
        bottom: 0;
    } */

    .menu-content{
        /* width: 100%;  */
        display: flex; 
        /* justify-content: center;  */
        /* align-items: center;  */
        /* margin-top: 20px;  */
        /* margin-bottom: 30px;  */
        gap: 10%;
        flex-direction: column;
        margin: 10px 30px 0 30px;
    }

    .btn-menu{
        width: 220px; 
        height: 330px; 
        transform: skew(-10deg);
        font-size: 30px;
        font-weight: 600;
        background-color: #2c2a3d;
        border: 3px solid #24203a;
        display: flex; 
        flex-direction: column; 
        justify-content: center; 
        align-items: center; 
        gap: 10px;
    }

    .btn-menu:hover{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .btn-menu-selected{
        border: 3px solid lightgoldenrodyellow;
        background: linear-gradient(135deg, #f526b6, #920bf9);
    }

    .icon-image {
        width: 100px;
    }

    .menu-bottom-content {
        width: 100%; 
        justify-content: start; 
        /* padding: 0 40px; */
        gap: 20px;
    }

    .menu-bottom-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 7%;
    }

    .menu-bottom-list-icon {
        height: 50px;
        margin-bottom: 5px;
    }

    .icon-image-brl {
        width: 360px;
        position: absolute;
        left: 26%;
        transform: translate(-40%, -76%);
    }

    .icon-image-kf {
        width: 260px;
        position: absolute;
        left: 12%;
        transform: translate(-50%, 20%);
    }

    .main-content {
        padding: 30px 20px !important;
    }

    .ul {
        margin: 0;
    }

@media (max-width: 768px) {
    .main-content {
        padding: 50px 0 0 0 !important;
    }

    .btn-menu{
        width: 132px; 
        height: 198px; 
        transform: skew(-10deg);
        font-size: 20px;
        font-weight: 600;
    }

    .icon-image {
        width: 50px;
    }

    .icon-image-kf {
        width: 180px; 
    }

    .menu-content {
        margin-top: 60px;
    }

    .head-font {
        font-size: 40px;
    }

    .content-font {
        font-size: 1rem;
    }

    .icon-image-brl {
        display: none;
    }

    .menu-bottom-list {
        width: 50%;
    }

    .menu-bottom-content {
        flex-wrap: wrap;
        row-gap: 20px;
    }

}

</style>