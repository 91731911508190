<template>
  <div class="page-content content-wrap">
    <!-- <img
            class="content-bg"
            src="../assets/backgrounds/background-default.png"
            alt=""
        >-->
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <h1>Under Construction</h1>
      <p>We're working on something awesome! Please check back later.</p>
    </div>
  </div>
</template>
