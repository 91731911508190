<template>
  <div
    id="depositItemPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="depositItemPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Deposit
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
                
        <form @submit.prevent="deposit">
          <div class="modal-body">
            <div
              class="row"
              style="height: 80px; margin-bottom: 10px;"
            >
              <div class="col-3 item-center">
                In Wallet :
              </div>
              <div
                class="col-9 item-center"
                style="height: 100%; display: grid; grid-auto-rows: auto; padding: 0 1rem 0 0;"
              >
                <div
                  class="no-space-around"
                  style="border: 1px solid #495057; border-radius: 5px; width: 100%; padding: 0.5rem 1rem 0.5rem 0.5rem; display: flex; align-items: center; height: fit-content"
                >
                <img
                      :src="inWalletItem?.image?? ''"
                      alt="Owner Icon"
                      class="stat-icon"
                      style="height: 60px;"
                    >
                    <div style="display: flex; flex-direction: column; width: 100%; gap: 5px;">
                      <div style="display: flex; justify-content: space-between; width: 100%;">
                        <span class="badge bg-custom-secondary">
                          # {{ inWalletItem?.id?? '0' }}
                        </span>
                        <span class="badge" :class="['text-rarity-' + (inWalletItem?.rarity?? '1')]">
                          {{ getItemRarityName(inWalletItem?.rarity?? '1') }}
                        </span>
                      </div>
                      <div style="display: flex; justify-content: space-between; width: 100%;">
                        <span style="width: 80%">
                          {{ inWalletItem?.name?? '' }}
                        </span>
                        <span style="font-weight: bold; ">
                          x {{ inWalletItem?.amount?? '1' }}
                        </span>
                      </div>
                      
                    </div>

                </div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 10px;">
              <div
                class="col-3"
                style="display: flex; align-items: center;"
              >
                <label
                  for="eventTotal"
                  class="form-label no-space-around"
                >
                  <span> Deposit : </span>
                </label>
              </div>
              <div
                class="col-9"
                style="padding: 0 1rem 0 0;"
              >
                <div class="">
                  <input
                    id="depositAmount"
                    v-model="depositAmount"
                    type="number"
                    min="0"
                    class="form-control"
                    placeholder="Enter Amount"
                    required
                    :disabled="true"
                  >
                </div>
                <div
                  v-if="showErrorAlert"
                  class="alert alert-danger mt-3"
                >
                  Invalid Total format.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading || isDepositDisable"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Deposit </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref,computed } from 'vue';
import {useToast} from 'vue-toast-notification';
import PortalContractABI from '@/contracts/NFTPortalABI.json';

import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        inWalletItem: {
            type: Object,
            required: true
        }
    },
    setup() {
        const store = useStore()
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
            isDepositDisable: false,
            depositAmount: 1,
            message: {}
        };
    },
    computed: {
        jabBalance: () => useStore().wallet.jab.toString(),
    },
    watch: {
      // depositAmount(newVal) {
      //   console.log('depositAmount', newVal);
      //   if (newVal == 0 || newVal == '' || newVal > (this.inWalletItem?.amount?? 0) ) {
      //     this.isDepositDisable = true;
      //   } else {
      //     this.isDepositDisable = false;
      //   }
      // }
    },
    methods: {
        getItemRarityName(rarityId){
          switch(rarityId){
            case 1: return 'Common'; break;
            case 3: return 'Rare'; break;
            case 4: return 'Epic'; break;
            default: return ''; break;
          }
        },
        closeModal() {
            // console.log('Closing modal', this.inWalletItem); // Add this line
            this.$refs.Close.click();

        },
        async deposit() {
            var $ = jQuery.noConflict();

            const web3 = new Web3(window.ethereum);
            var contractAddress = process.env.VITE_STONE_PORTAL_CONTRACT_ADDRESS;
            if(this.inWalletItem.type.toUpperCase() == "ARTIFACT"){
              contractAddress = process.env.VITE_ARTIFACT_PORTAL_CONTRACT_ADDRESS;
            }
            console.log("contractAddress", contractAddress);
            const recipientAddress = this.destWalletAddress;

            this.isLoading = true;
            this.closeModal();
            this.message = {
                    title: 'Deposit pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            const senderAddress = await web3.eth.getAccounts();

            try {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)
                // Create an ethers.js contract instance
                const contract = new ethers.Contract(contractAddress, PortalContractABI, signer);
                const depositAmountInEther = ethers.utils.parseEther(this.depositAmount.toString());
                const tx = await contract.deposit(this.inWalletItem.id, 1);
                this.message.text = "Your transaction is pending. please do not close this page.";
                await tx.wait();

                useToast().open({
                        message: 'Deposit completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                
            } catch (error) {
                console.error('Error deposit:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.closeModal();
            }
        }
    }
};
</script>

<style scoped>
.no-space-around {
    padding: 0;
    margin: 0;
}

.item-center {
    align-self: center;
}

.text-rarity-1 {
    background-color: #B4B4B4; 
}
.text-rarity-3 {
    background-color: #06D8DB;
}
.text-rarity-4 {
    background-color: #B069FF; 
}
</style>
  
