<template>
  <div
    id="transferPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="transferPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Transfer
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <form @submit.prevent="transferNFT">
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="DestWalletAddress"
                class="form-label"
              >Destination Address</label>
              <input
                id="DestWalletAddress"
                v-model="destWalletAddress"
                type="text"
                class="form-control"
                placeholder="Enter Destination Wallet Address"
                required
              >
            </div>
            <div
              v-if="showErrorAlert"
              class="alert alert-danger mt-3"
            >
              Invalid Address format.
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else>Transfer</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <TransactionPendingPopup :message="transferMessage" />
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';
import {useToast} from 'vue-toast-notification';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        showModal: Boolean,
        product: {},
        type: 'fighter'
    },
    data() {
        return {
            destWalletAddress: '', // Initialize with a default value
            isLoading: false,
            showErrorAlert: false,
            transferMessage: {}
        };
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
            // console.log('tokenIdToTransfer', this.product.properties.token_id);
        },
        async transferNFT() {
            var $ = jQuery.noConflict();

            const web3 = new Web3(window.ethereum);
            const contractAddress = (this.type === 'fighter')? process.env.VITE_FIGHTER_CONTRACT_ADDRESS:this.product.collection;
            const tokenIdToTransfer = (this.type === 'fighter')? this.product.properties.token_id:this.product.token_id;
            const recipientAddress = this.destWalletAddress;

            // console.log(contractAddress);
            //     console.log(recipientAddress);
            //     console.log(tokenIdToTransfer);

            if (!web3.utils.isAddress(recipientAddress)) {
                console.log('Address Error');
                this.showErrorAlert = true;
                return;
            }

            this.isLoading = true;
            this.closeModal();
            this.transferMessage = {
                    title: 'Transfer pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: "Go to your wallet <br> You'll be asked to approve this transfer from your wallet."
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            const senderAddress = await web3.eth.getAccounts();

            try {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)
                

                // Create an ethers.js contract instance
                const contract = new ethers.Contract(contractAddress, NFTContractABI, signer);

                // Define the parameters for safeTransferFrom
                const from = senderAddress[0];
                const to = recipientAddress;
                const tokenId = tokenIdToTransfer; 
                // Call the safeTransferFrom function
                const tx = await contract.safeTransferFrom(senderAddress[0], recipientAddress, tokenIdToTransfer);
                this.transferMessage.text = "Your transaction is pending. please do not close this page.";
                await tx.wait();

                useToast().open({
                        message: 'Your NFT has been successfully transferred.',
                        position: 'top-right',
                        type: 'success',
                    });
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                

                
            } catch (error) {
                console.error('Error transferring NFT:', error);

                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.closeModal();
            }

        }
    }
};
</script>
  
