<template>
  <div class="product-item">
    <!-- <div v-if="isLoading" style="margin-bottom: 50px;">
            <v-skeleton-loader :type="'card'" :animation="true" :width="200" :height="300" theme="dark"></v-skeleton-loader>
        </div>
        <div v-else> -->
    <div class="card product-card-container">
      <div class="card-body">
        <div class="product-header">
          <div class="left-content">
            <div class="product-id">
              # {{ item.name }}
            </div>
          </div>
        </div>
        <div
          class="image-container"
          style="width: 124px; height: 124px; margin-bottom: 10px;"
        >
          <img
            :src="`/images/item/${item.item_code}.png`"
            alt="reward image"
            class="product-image"
          >
        </div>

        <div v-if="item.amount">
          <h4 class="product-usd-price">
            {{ item.amount }} PCS
          </h4>
        </div>

        <div v-if="!item.claimed">
          <button
            type="button"
            class="btn btn-primary"
            @click="clickSend"
          >
            Send
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>

import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useStore } from '@/stores/index.ts';
import {useToast} from 'vue-toast-notification';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";
import { ethers } from 'ethers';
import PortalContractABI from '@/contracts/PortalContractABI.json';

export default {
    components: {
        TransactionPendingPopup
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: {}
        }
    },
    mounted() {
        //this.fetchNftData();
    },
    methods: {
        async clickSend() {
            var $ = jQuery.noConflict();

            const contractAddress = "0xf3e0E795CEbC5bf41cB521E22ef7D0F5769921D5";

            this.message = {
                    title: 'Transaction pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: ""
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            try {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)
                // Create an ethers.js contract instance
                console.log("this.item.id", this.item.id);
                console.log("this.item.amount", this.item.amount);
                const contract = new ethers.Contract(contractAddress, PortalContractABI, signer);
                const depositAmountInEther = ethers.utils.parseEther("1");
                const tx = await contract.deposit(depositAmountInEther);
                console.log("tx", tx);
                await tx.wait();

                useToast().open({
                        message: 'Send item completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardSended');
            } catch (error) {
                console.error('Error deposit:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardSended');
            }
        }
    }
};
</script>
  
<style scoped>
.product-item {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
    align-items: center;
}

.product-image {
    max-width: 100%;
    height: 100%;
    width: auto;
    object-fit: cover;
}

.product-name {
    font-size: 16px;
    text-align: center;
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}



.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}



.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('@/assets/icon-stats-kroma.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.open-button {
    height: 20%;
    width: 100%;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    font-size: 16px;
    border: 2px solid #2980b9;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s, transform 0.3s;
}

.open-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.clickable-image {
    cursor: pointer;
}

@media (max-width: 768px) {
    .product-image {
    width: 120px;
    margin-bottom: 0px;
}
}

</style>