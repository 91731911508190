<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Items </h4>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';
import NFTERC1155ABI from '@/contracts/NFTERC1155ABI.json';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';



export default {
    components: {
        ProductItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
            store: null
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
        //this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const exchangeStore = useExchangeStore();
                exchangeStore.fetchExchangeRates();
                const store = this.store;
                

                const accountAddress = store.getWalletAddress;
                //console.log("accountAddress" + accountAddress);
                /*const apiKey = '25a60ca4f3004870b4094104969a2e9c'; // Replace with your API key
                const accountAddress = store.getWalletAddress;
                console.log("accountAddress" + accountAddress);
                const collection = 'kf-fighter-fragment';
                const apiUrl = `https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=${collection}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': '25a60ca4f3004870b4094104969a2e9c'
                    }
                });*/

                
                const web3 = new Web3(window.ethereum);

                // Replace with your ERC1155 contract address
                const contractAddress =  process.env.VITE_FRAGMENT_CONTRACT_ADDRESS; 
                // Create an instance of your ERC1155 contract
                const contract = new web3.eth.Contract(NFTERC1155ABI, contractAddress);

                // Replace 'ids' and 'accountAddress' with the specific token IDs and account address you want to query
                const ids = [0,1,2,3]; // Example token IDs
                // const balances = await contract.methods.balanceOfBatch([accountAddress,accountAddress,accountAddress,accountAddress], ids).call();

                // // Handle the returned balances array
                // console.log("Token Balances:", balances);

                var totalAmount = 0n;

                // for (let i = 0; i < 4; i++) {
                //     //console.log("Token Balance " + i + ": " + balances[i]);
                //     const tokenID = i;
                //     const price = 0;
                //     const usdPrice = 0;
                //     if (balances[i] > 0) {
                //         totalAmount += balances[i];
                //         const nft = await axios.get(`https://metadata.kryptofighters.io/fragment/${i + 1}.json`);
                //         console.log( this.products);
                //         const product = {
                //             id: tokenID,
                //             itemId: nft.data.item_id,
                //             price: price,
                //             currency: '',
                //             listDate: '',
                //             dateString: '',
                //             usdPrice: usdPrice,
                //             image: nft.data.image,
                //             type: nft.data.name,
                //             description: nft.data.description,
                //             amount: balances[i],
                //             isOwner: true
                //         };
                //         this.products.push(product);
                //     }
                    
                // }

                const fragmentCollection = process.env.VITE_FRAGMENT_CONTRACT_ADDRESS;
                const fragmentRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${fragmentCollection}`]
                    }
                };
                const fragmentApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const fragmentResponse = await axios.post(fragmentApiUrl, fragmentRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("fragmentResponse ",fragmentResponse);

                const lootboxCollection = process.env.VITE_LOOTBOX_CONTRACT_ADDRESS;
                const lootboxRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${lootboxCollection}`]
                    }
                };
                const lootboxApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const lootboxResponse = await axios.post(lootboxApiUrl, lootboxRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("lootboxResponse ",lootboxResponse);

                const combinedData = [...fragmentResponse.data.ownerships, ...lootboxResponse.data.ownerships];

                this.products = await Promise.all(combinedData.map(async (nft) => {
                    totalAmount += balances[nft.tokenId];
                    const price = 0;
                    const usdPrice = 0;

                    try {
                        const response = await axios.get(`https://metadata.kryptofighters.io/fragment/${parseInt(nft.tokenId) + 1}.json`);
                        console.log("response ",response);
                        return {
                            id: nft.tokenId, 
                            itemId: response.data.item_id,
                            price: price,
                            currency: '',
                            listDate: '', 
                            dateString: '',
                            usdPrice: usdPrice, 
                            image: response.data.image,
                            type: response.data.name,
                            description: response.data.description,
                            amount: balances[nft.tokenId],
                            isOwner: true
                        };
                    } catch (error) {
                        console.error(`Error fetching metadata for tokenId ${nft.tokenId}:`, error);
                        return null;
                    }
                }));

                console.log("misc" + totalAmount);
                this.amounts["misc"] = totalAmount;
                return;

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  