<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Frames </h4>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';


import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        ProductItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
    },
    methods: {
        async fetchData() {
            try {
                const exchangeStore = useExchangeStore();
                exchangeStore.fetchExchangeRates();
                const store = useStore();


                const apiKey = process.env.VITE_OPENSEA_API_KEY; // Replace with your API key
                const accountAddress = store.getWalletAddress;
                console.log("accountAddress" + accountAddress);
                const collection = 'krypto-fighters-hall-of-frames';
                const apiUrl = `https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=${collection}`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_OPENSEA_API_KEY
                    }
                });

                const ethToUsdExchangeRate = exchangeStore.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = exchangeStore.getMaticToUsdExchangeRate;

                // Extract relevant data from the API response and update recentProducts
                this.products = response.data.nfts.map(nft => {
                    const tokenID = nft.identifier;
                    const dateString = timeAgo(convertToTimestamp(nft.updated_at));
                    const price = 0;
                    const usdPrice = 0;
                    console.log(nft);
                    //console.log("usdPrice" + usdPrice);
                    return {
                        id: tokenID,
                        itemId: 10,
                        price: price,
                        currency: '',
                        listDate: '',
                        dateString: dateString,
                        usdPrice: usdPrice,
                        image: nft.image_url,
                        type: "Frame",
                        isOwner: true
                    };
                });

                console.log("selectedClasses" + this.selectedClasses);

                this.products.sort((a, b) => {
                    return b.listDate - a.listDate;
                });

                this.products = this.products.slice(0, 36);

                this.amounts["frame"] =  this.products.length;
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  