<template>
    <div class="" style="display: flex; background-color: var(--secondary-background-color); flex-direction: column; justify-content: center; align-items: center; padding-bottom: 20px; padding-top: 20px; margin-top: auto;">

        <!-- <img src="https://kryptofighters.io/wp-content/uploads/2024/08/image-13-2.png" alt="image 13 (2)" style="height: 84px; width: auto; margin: 0 0 50px 0;"> --> 

          <div class="elementor-widget-container">
            <ul class="elementor-inline-items">
							<li class="elementor-icon-list-item elementor-inline-item">
											<a href="https://kryptofighters.gitbook.io/krypto-fighters">

											<span class="elementor-icon-list-text">White Paper</span>
											</a>
									</li>
								<li class="elementor-icon-list-item elementor-inline-item">
											<a href="https://discord.com/invite/2cSNrE9t7P">

											<span class="elementor-icon-list-text">Discord</span>
											</a>
									</li>
								<li class="elementor-icon-list-item elementor-inline-item">
											<a href="https://x.com/KryptoFighters">

											<span class="elementor-icon-list-text">Twitter</span>
											</a>
									</li>
								<li class="elementor-icon-list-item elementor-inline-item">
											<a href="https://guidebook.kryptofighters.io/">

											<span class="elementor-icon-list-text">Game Guide</span>
											</a>
									</li>
						</ul>
          </div>
          <div class="">
            <div class="elementor-heading-title">2022 © MAXMA GAME STUDIO. ALL RIGHTS RESERVED.</div>
          </div>
        </div>
</template>
  
<style>
.elementor-inline-items {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.elementor-inline-item {
    margin-right: calc(28px / 2);
    margin-left: calc(28px / 2);
}

.elementor-icon-list-text {
   /* font-family: 'Rajdhani', sans-serif;*/
    font-size: 14px;
    color: var(--secondary-border-color);
}

.elementor-inline-item a {
    box-shadow: none;
    text-decoration: none;
}

ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0;
}

.elementor-icon-list-items {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  display: flex; /* Align list items horizontally */
}

/* Style for individual list items */
.elementor-icon-list-item {
  position: relative; /* Ensure pseudo-element positioning is relative to this item */
  margin-right: 20px; /* Space between items, adjust as needed */
}

/* Add separator line between list items except the last one */
.elementor-icon-list-item:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 12px; /* Adjust height as needed */
  border-left: 2px solid #ddd; /* Set color and width of the separator line */
  right: -10px; /* Adjust right positioning to align with the item */
  top: 50%; /* Center the line vertically */
  transform: translateY(-50%); /* Center the line vertically */
  right: calc(-35px / 2);
}

/* Ensure the links inside list items are styled as needed */
.elementor-icon-list-item a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color from parent */
}

.elementor-heading-title {
    color: var(--secondary-border-color);
    /*font-family: "Rajdhani", Sans-serif;*/
    font-size: 14px;
    text-align: center;
}

@media (max-width: 768px) {

  .elementor-icon-list-text {
   /* font-family: 'Rajdhani', sans-serif;*/
    font-size: 12px; 
  }

    .elementor-heading-title {
      font-size: 12px;
  }
    
}
</style>
  