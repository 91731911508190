import { defineStore } from 'pinia';

const walletBalance = {
  jab: 0,
  kf: 0,
};

export const useStore = defineStore('store', {
  state: () => ({
    walletAddress: '', 
    wallet: { ...walletBalance },
    ingameWallet: { ...walletBalance },
  }),
  getters: {
    getWalletAddress: (state) => {
      console.log('getWalletAddress' + state.walletAddress);
      return state.walletAddress;
    },
    getWalletBalance: (state) => {
      return { ...state.wallet }; // Return a copy of the wallet object
    },
    getIngameWalletBalance: (state) => {
      return { ...state.wallet }; // Return a copy of the wallet object
    },
  },
  actions: {
    setWalletAddress(address: string) {
      console.log('setWalletAddress' + address);
      this.walletAddress = address;
    },
    setWalletBalance(walletData: { jab: number; kf: number }) {
      this.wallet = { ...walletData }; // Set the wallet object
    },
    setIngameWalletBalance(walletData: { jab: number; kf: number }) {
      this.wallet = { ...walletData }; // Set the wallet object
    },
  },
});
