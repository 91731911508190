<template>
  <div class="sprite">
    <img
      :src="spriteSheetUrl"
      alt="sprite"
    >
  </div>
</template>
  
<script>
import anime from 'animejs';

export default {
    props: {
        spriteSheetUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            totalFrames: 15,
            spriteWidth: 190, // Set initial sprite width
            spriteHeight: 190, // Set initial sprite height
        };
    },
    computed: {
        spriteClass() {
            return {
                'sprite-animation': true,
            };
        },
    },
    mounted() {
        const animationDuration = 1000; // in milliseconds
        const easing = 'steps(' + this.totalFrames + ')';

        anime({
            targets: `.${this.spriteClass['sprite-animation']}`,
            backgroundPosition: `-${this.totalFrames * this.spriteWidth}px 0px`,
            width: this.spriteWidth + 'px', // Set the dynamic sprite width
            height: this.spriteHeight + 'px', // Set the dynamic sprite height
            easing: easing,
            duration: animationDuration,
            loop: true,
        });

        const animation = anime({
  targets: ".sprite > img",
  keyframes: [
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: 0, duration: 150, easing: "steps(1)" },
    { translateX: -190*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -380*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5, duration: 150, easing: "steps(1)" },
    { translateX: -570*1.5,  translateY: -380*1.5,  duration: 1000, easing: "steps(1)" },
  ],
  loop: true
});



    },
};
</script>
  
<style scoped>

.sprite {
  width: 285px;
  height: 285px;
  overflow: hidden;
}

.sprite > img {
  width: auto;
  height: 854px;
}
.sprite-animation {
    width: 100px;
    /* Set the width of each frame */
    height: 100px;
    /* Set the height of each frame */
    /* Set the dynamic sprite sheet URL */
    background-image: url('https://kf-asset-object.s3.ap-southeast-1.amazonaws.com/nft-fighter/4x/trickster/rare/584.png');
}
</style>