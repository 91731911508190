<!-- SentTab.vue -->
<template>
    <div>
       <div class="d-flex align-items-center justify-content-between default-row">
        <h4>Sent Offer </h4>
      </div>
      <div class="card">
          <div class="card-body" style="width: 100%; overflow-x: auto; white-space: nowrap;">
            
            <div v-if="sentList.length > 0">
                <div class="row" style="margin-bottom: 20px; font-weight: 600; color: #8e97a8; font-size: 11px; text-transform: uppercase;">
                <div class="col-4"> Item </div>
                <div class="col-2"> Amount </div>
                <div class="col-2"> To </div>
                <div class="col-2"> Expiration Date </div>
                <div class="col-2"> </div>
            </div>
                <SentDetail
                    v-for="(list, index) in sentList"
                    :key="index"
                    :product="list"
                    :index="index"
                    @refreshData = "refreshData"
                />
            </div>
            <div v-else style=" padding: 20px 0;">
                <img
                src="~@/assets/no-data.png"
                alt="no data"
                style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                >
            </div>
          </div>
      </div>
    </div>
  </template>
    
  <script>
  import Web3 from 'web3';
  import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
  import { convertToUSD } from '@/utils/exchangeUtils.js';
  import { useExchangeStore } from '@/stores/exchangeRate.ts';
  import { useStore } from '@/stores/index.ts';
  import SentDetail from '@/components/SentDetail.vue'; 
  import { ref, onMounted, watch } from 'vue';
  import { ethers } from 'ethers';
  import axios from 'axios';
  
  export default {
      components: {
        SentDetail
      },
      props: {
          amounts: {}
      }, data() {
          return {
            sentList: [],
          };
      },
      mounted() {
          this.store = useStore();
          if(this.store.getWalletAddress!=''){
              this.fetchData();
          }else{
              watch(() => this.store.getWalletAddress, () => {
                  this.fetchData();
              });
          }
      },
      methods: {
          async fetchData() {
              try {
                  const store = this.store;
                  const accountAddress = store.getWalletAddress;

                  const stoneCollection = `KROMA:${process.env.VITE_STONE_CONTRACT_ADDRESS.toLowerCase()}`;
                  const artifactCollection = `KROMA:${process.env.VITE_ARTIFACT_CONTRACT_ADDRESS.toLowerCase()}`;
  
                  const apiUrl = `https://testnet-api.rarible.org/v0.1/orders/bids/byMaker?maker=ETHEREUM:${accountAddress}&status=ACTIVE`;
                  const response = await axios.get(apiUrl, {
                      headers: {
                          'accept': 'application/json',
                          'x-api-key': process.env.VITE_RARIBLE_API_KEY
                      }
                  });
                  console.log("response sent order" , response);
                  const filter = response.data.orders.filter(f=>f.take.type.collection !== stoneCollection && f.take.type.collection !== artifactCollection);
                  for (const data of filter) {
                    try{
                        const apiUrl = `https://testnet-api.rarible.org/v0.1/items/${data.take.type.collection}:${data.take.type.tokenId}`;
                        const headers = {
                            'accept': 'application/json',
                            'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                        };

                        const itemResponse = await axios.get(apiUrl, { headers });
                        // console.log("itemResponse:", itemResponse);

                        if(itemResponse.data.bestSellOrder){
                            const temp = {
                                order: data,
                                item: itemResponse.data
                            }
                            
                            this.sentList.push(temp);
                        }
                    }
                    catch (error) {
                        console.error(`Error fetching data for token ID ${data.make.type.tokenId}:`, error);
                    }
                }
  
                //   for (const data of response.data.orders) {
                //       if(data.make.type.tokenId){
                //           try{
                //               const apiUrl = `https://testnet-api.rarible.org/v0.1/items/${data.make.type.collection}:${data.make.type.tokenId}`;
                //               const headers = {
                //                   'accept': 'application/json',
                //                   'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                //               };
  
                //               const itemResponse = await axios.get(apiUrl, { headers });
                //               console.log("itemResponse:", itemResponse);
  
                //               if(itemResponse.data.bestBidOrder){
                //                   this.receivedList.push(itemResponse);
                //               }
                //           }
                //           catch (error) {
                //               console.error(`Error fetching data for token ID ${data.make.type.tokenId}:`, error);
                //           }
                //       }
                //   }
              } catch (error) {
                  console.error("Error:", error);
              }
          },
          refreshData(){
              // this.fetchData();
              window.location.reload();
          }
      }
  }
  </script>
  
  <style scoped>
      
  </style>
    