<!-- RecievedTab.vue -->
<template>
  <div>
     <div class="d-flex align-items-center justify-content-between default-row">
      <h4>Received Offers </h4>
    </div>
    <div class="card">
        <div class="card-body" style="width: 100%; overflow-x: auto; white-space: nowrap;">
            
            <div v-if="receivedList.length > 0">
                <div class="row" style="margin-bottom: 20px; font-weight: 600; color: #8e97a8; font-size: 11px; text-transform: uppercase;">
                <div class="col-4"> Item </div>
                <div class="col-2"> Active Offers </div>
                <div class="col-3"> Highest Offer </div>
                <div class="col-3"> </div>
                </div>
                <OrderDetail
                    v-for="(list, index) in receivedList"
                    :key="index"
                    :product="list"
                    :index="index"
                    @refreshData = "refreshData"
                />
            </div>
            <div v-else style="padding: 20px 0;">
                <img
                src="~@/assets/no-data.png"
                alt="no data"
                style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                >
            </div>
        </div>
        
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';
import OrderDetail from '@/components/OrderDetail.vue'; 
import { ref, onMounted, watch } from 'vue';
import { ethers } from 'ethers';
import axios from 'axios';

export default {
    components: {
        OrderDetail
    },
    props: {
        amounts: {}
    }, data() {
        return {
            receivedList: [],
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
    },
    methods: {
        async fetchData() {
            try {
                console.log("fetchData ");
                const store = this.store;
                const accountAddress = store.getWalletAddress;

                const stoneCollection = `KROMA:${process.env.VITE_STONE_CONTRACT_ADDRESS.toLowerCase()}`;
                const artifactCollection = `KROMA:${process.env.VITE_ARTIFACT_CONTRACT_ADDRESS.toLowerCase()}`;

                const apiUrl = `https://testnet-api.rarible.org/v0.1/orders/sell/byMaker?maker=ETHEREUM:${accountAddress}&status=ACTIVE`;
                const response = await axios.get(apiUrl, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("response recieved order" , response);
                const filter = response.data.orders.filter(f=>f.make.type.collection !== stoneCollection && f.make.type.collection !== artifactCollection);

                for (const data of filter) {
                    if(data.make.type.tokenId){
                        try{
                            const apiUrl = `https://testnet-api.rarible.org/v0.1/items/${data.make.type.collection}:${data.make.type.tokenId}`;
                            const headers = {
                                'accept': 'application/json',
                                'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                            };

                            const itemResponse = await axios.get(apiUrl, { headers });
                            console.log("itemResponse:", itemResponse);

                            const eventApiUrl = `https://testnet-api.rarible.org/v0.1/activities/byItem?type=&itemId=${data.make.type.collection}:${data.make.type.tokenId}`;
                            const eventResponse = await axios.get(eventApiUrl, {
                            headers: {
                                'accept': 'application/json',
                                'x-api-key': process.env.VITE_RARIBLE_API_KEY
                            }
                            });

                            // console.error('Error fetching image:', eventResponse);
                            eventResponse.data.activities.sort((a, b) => {  
                                const date1 = new Date(b.lastUpdatedAt);
                                const date2 = new Date(a.lastUpdatedAt);
                                return date1 - date2;
                            });
                            // console.error('Error fetching image:', eventResponse);
                            const transferIndex = eventResponse.data.activities.findIndex(item => item["@type"] === "LIST" || item["@type"] === "SELL" || item["@type"] === "TRANSFER");
                            const filteredTransactions = transferIndex !== -1 ? eventResponse.data.activities.slice(0, transferIndex) : eventResponse.data.activities;
                            // console.error('Error fetching image:', filteredTransactions);
                            const cancelBidHashes = new Set(
                            filteredTransactions
                                .filter(tx => tx["@type"] === "CANCEL_BID")
                                .map(tx => tx.hash)
                            );

                            const updatedTransactions = filteredTransactions.filter(tx => !cancelBidHashes.has(tx.hash) && tx['@type'] === 'BID');

                            // console.error('Error fetching image:', updatedTransactions);
                            
                            if(updatedTransactions.length > 0){
                                const temp = {
                                    item: itemResponse,
                                    activities: updatedTransactions.filter(event => event['@type'] == 'BID')
                                }
                                this.receivedList.push(temp);
                            }
                        }
                        catch (error) {
                            console.error(`Error fetching data for token ID ${data.make.type.tokenId}:`, error);
                        }
                    }
                }
            } catch (error) {
                console.error("Error:", error);
            }
        },
        refreshData(){
            // this.fetchData();
            window.location.reload();
        }
    }
}
</script>

<style scoped>
    
</style>
  