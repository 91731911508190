<template>
  <div class="page-content content-wrap">
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div
        class="d-flex align-items-left"
        style="margin-bottom: 40px;"
      >
        <img
          :src="require('@/assets/icons/icon-matic.png')"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >
        <h1>Polygon Bridge</h1>
      </div>

      <!-- <div style="width: 100%; height: 300px; overflow: hidden; display: flex; align-items: center; position: relative; border-radius: 10px;">
        <img
          :src="require('@/assets/backgrounds/training-room.png')"
          alt="BG Image"
          style="width: 100%; height: auto;"
        >
        <div
          class="image-gradient"
          style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; padding: 18px 24px;"
        >
          <div style="height: 100%; display: flex; flex-direction: column; justify-content: end;">
            <h3
              class=""
              style="margin-bottom: 5px;"
            >
              Complete Quest to claim rewards
            </h3>
            <div
              class=""
              style="margin-bottom: 10px; font-size: 14px; font-weight: 200;"
            >
              Embark on quests, complete challenges, and claim exclusive NFT rewards. Start assembling your team today!
            </div>
            <div style="">
              <a
                href="https://beta.dequest.io/realms/kryptofighters"
                class="btn btn-primary"
                style="margin-right: 10px;"
              >Get Started</a>
              <a
                href="https://beta.dequest.io/realms/kryptofighters"
                class="btn btn-secondary"
              >Learn more</a>
            </div>
          </div>
        </div>
      </div> -->

      <div>
        <BridgeTab />
      </div>
    </div>
  </div>
</template>

<script>

import Web3 from 'web3';
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import BridgeTab from '@/components/reward/BridgeTab.vue';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
      BridgeTab,
    }, data() {
        return {
        };
    },
    methods: {
        // formattedFrameAmount(amount) {
        //     if (amount > 0) {
        //         return `(${amount})`;
        //     } else {
        //         return "";
        //     }
        // },
        // activateTab(tabName) {
        //     this.activeTab = tabName;
        // }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.nav-tabs {
    color: gray;
    border: 0;
    border-bottom: 1px solid var(--primary-border-color);
}

.nav-tabs .nav-link.active {
    background-color: var(--soft-background-color);
}

.nav-tabs .nav-link.inactive {
    background-color: var(--soft-background-color);
}


/* Change the background color of inactive tabs */
.nav-tabs .nav-link :hover {
    border-color: var(--primary-border-color);

}

.image-gradient{
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 50%);
}
</style>