<template>
  <div class="top-bar text-white d-flex align-items-center justify-content-between ">
    <BreadcrumbView style="margin-top: 6px;" />

    <div class="connect-button">
      <div v-if="!connected">
        <button
          class="btn btn-primary"
          @click="connectWallet"
        >
          Connect
        </button>
      </div>
      <div
        v-else
        class="dropdown"
      >
        <div v-if="isUsePolygon">
          <button v-if="storeNetwork.state.isPolygonConnected"
            id="dropdownMenuButton"
            class="btn btn-warning"
            type="button"
            disabled
            style="opacity: unset; background-color: #171527; border-color: #ff65ed; color: #ffffff;"
          >
            <img
              src="../assets/class-users-default.png"
              style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
              alt="Sample Image"
            >
            {{ shortenedWalletAddress + " " }}
          </button>
          <button v-else
              class="btn btn-primary"
              @click="switchToPolygonNetwork"
            >
              <!-- <img
                :src="require('@/assets/icons/icon-kroma.png')"
                alt="NFT Icon"
                class=""
                style="width:24px;"
              >
              <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="30px" fill="#e8eaed">
                <path d="M280-160 80-360l200-200 56 57-103 103h287v80H233l103 103-56 57Zm400-240-56-57 103-103H440v-80h287L624-743l56-57 200 200-200 200Z"/>
              </svg> -->
              
              <img
                :src="require('@/assets/icons/icon-matic.png')"
                alt="NFT Icon"
                class=""
                style="width:26px; padding: 5px 0;"
              >
              Switch to Polygon
          </button>
        </div>
        <div v-else>
          <button
            id="dropdownMenuButton"
            class="btn btn-warning dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../assets/class-users-default.png"
              style="padding: 0px; width: auto; height: 36px; margin-right: 6px;"
              alt="Sample Image"
            >
            {{ shortenedWalletAddress + " " }}
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
          >
            <li>
              <router-link
                to="/inventory"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center align-items-center">
                  <vue-feather
                    type="trello"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a style="">Dashboard</a>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                to="/inventory"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="package"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Inventory</a>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                to="/offers"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="file"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Offers</a>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                to="/dojo"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="user-plus"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Coaching Lab</a>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                to="/dojo-simulation"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="user-plus"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Dojo (Simulator)</a>
                </div>
              </router-link>
            </li>
            <li>
              <router-link
                to="/grand_dojo"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="user-plus"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Grand Dojo (Simulator)</a>
                </div>
              </router-link>
            </li>
            <!-- <li>
              <router-link
                to="/reward"
                class="router-link"
              >
                <a class="dropdown-item">Rewards</a>
              </router-link>
            </li> -->
            <li>
              <router-link
                to="/portal"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="refresh-cw"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Portal</a>
                </div>
              </router-link>
            </li>

            <li>
              <router-link
                to="/account_setting"
                class="router-link"
              >
                <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="settings"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a class="dropdown-item" style="padding: 0;">Account Settings</a>
                </div>
              </router-link>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <div class="dropdown-item d-flex align-items-center">
                  <vue-feather
                    type="log-out"
                    class="menu-icon-special"
                    style="margin-right: 6px; width: 20px; height: 100%; overflow: unset;"
                  />
                  <a
                  class="dropdown-item"
                  href="#"
                  @click="disconnectWallet"
                  style="padding: 0;"
                >Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Web3 from 'web3';
import { ref, onMounted, computed, watch } from 'vue';
import BreadcrumbView from '@/components/BreadcrumbView.vue';
import Cookies from 'js-cookie';
import { useStore } from '@/stores/index.ts';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import { useNetwork } from '@/stores/network.ts';

const connected = ref(false);
const walletAddress = ref('');
const store = useStore();

const isConnected = Cookies.get('connected') === 'true';
console.log("isConnected" + isConnected);

const route = useRoute();
const isUsePolygon = computed(() => {
  return route.path === '/polygon_bridge';
});

const currentChainId = ref(null); // To hold the current chain ID
const storeNetwork = useNetwork();
const fetchChainId = async () => {
  try {
    currentChainId.value = await window.ethereum.request({ method: 'eth_chainId' });
    
  } catch (error) {
    console.error(error);
  }
  storeNetwork.setPolygonConnection(currentChainId.value === '0x89');
};

// const isPolygonConnected = computed(() => {
//   return currentChainId.value === '0x89';
// });

// watch([connected, isPolygonConnected, isUsePolygon], ([newConnected, newIsPolygonConnected, newIsUsePolygon]) => {
//   if (newConnected && newIsUsePolygon && !newIsPolygonConnected) {
//     alert('Please switch to the Polygon network.');
//   }
// });

const apiKey = process.env.VITE_JAB_CONTRACT_ADDRESS;
console.log(apiKey);

const jabContractAbi = [
    {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function',
    },
];

onMounted(async () => {
  // Check wallet connection when the component is mounted
  if (isConnected) {
    await checkWalletConnection(false);
  }
  
});

const connectWallet = async () => {
  await checkWalletConnection();
};

const checkWalletConnection = async (isShowError = true) => {
  try {
    if (typeof window.ethereum !== 'undefined') {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const networkId = await window.ethereum.request({ method: 'net_version' });
      console.log("networkId" ,networkId);
      console.log("networkId" ,process.env.VITE_NETWORK_ID);

      if (networkId === process.env.VITE_NETWORK_ID || networkId === '80001' || (networkId === '137'&&isUsePolygon)) {
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        connected.value = true;
        walletAddress.value = accounts[0];
        store.setWalletAddress(walletAddress.value);
        Cookies.set('connected', connected.value);

        if( networkId === '137'){
          fetchChainId();
          return;
        }
          

        const jabContractAddress = process.env.VITE_JAB_CONTRACT_ADDRESS;
        console.log("jabContractAddress" +jabContractAddress);
        const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
        const jabBalanceWei = await jabTokenContract.methods.balanceOf(accounts[0]).call();
        const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);

        store.setWalletBalance( {  jab: jabBalanceWith2DecimalPlaces , kf: 0});
        //console.log("Jab Balance" + jabBalanceWith2DecimalPlaces);
      }else {
        console.error('Please switch to the Kroma Sepolia network in MetaMask');
        alert('Please switch to the Kroma Sepolia network in MetaMask');
        return 0;
      }
    } else {
      Cookies.set('connected', false);
      if (isShowError) alert('Please install MetaMask to use this feature.');
    }
  } catch (error) {
    console.error(error);
    Cookies.set('connected', false);
    if (isShowError) alert('Failed to connect to MetaMask. Please try again.');
  }

  fetchChainId();
};

const disconnectWallet = async () => {
  try {
    if (window.ethereum && window.ethereum.isConnected()) {
      await window.ethereum.request({ method: 'eth_requestAccounts', params: [] });
    }
    connected.value = false;
    walletAddress.value = '';
    Cookies.set('connected', connected.value);
    console.error('Wallet has been disconnected');
  } catch (error) {
    console.error(error);
    alert('Failed to disconnect from MetaMask. Please try again.');
  }
  fetchChainId();
};

const shortenedWalletAddress = computed(() => {
  if (walletAddress.value) {
    return `${walletAddress.value.slice(0, 6)}...${walletAddress.value.slice(-3)}`;
  }
  return '';
});

const switchToPolygonNetwork = async () => {  
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x89', // Hexadecimal for 137
        chainName: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-rpc.com/'], // Valid RPC URL
        nativeCurrency: {
          name: 'Polygon', // The native currency for Polygon is MATIC
          symbol: 'POL',
          decimals: 18
        },
        blockExplorerUrls: ['https://polygonscan.com/']
      }]
    });
    useToast().open({
      message: 'Polygon Network swap completed successfully.',
      position: 'top-right',
      type: 'success',
    });
  } catch (error) {
    console.error('Error adding network:', error);
    useToast().open({
      message: 'Switch failed on the Polygon Network.',
      position: 'top-right',
      type: 'error',
    });
  }
  fetchChainId();
};
</script>



<style scoped>
.top-bar {
  position: fixed;
  background-color: var(--dark-background-color);
  height: max-content;
  padding-left: 36px !important;
  padding: 12px;
  top: 0;
  width: calc(100% - 260px); 
  z-index: 2;
  margin-left: 246px;
}

.menu-items {
  display: flex;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: white;
}

.top-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px; /* Set the height of your gradient line */
  background: linear-gradient(to right, #14121f, #4d4e59,#14121f); /* Gradient colors */
}

.connect-button {
  padding-right: 60px; /* Add padding to the right side of the Connect button */
}

.dropdown-toggle {
    background-color: #171527; /* Set background color */
    border-color: #ff65ed; /* Set border color */
    color: #ffffff; /* Set text color */
}

.dropdown-toggle:hover {
    background-color: #fa65ff55; /* Set hover background color */
    border-color: #f565ff; /* Set hover border color */
    color: #ffffff; /* Set hover text color */
}

.router-link{
  text-decoration: none; 
}

.a {
  line-height: 2rem;
}

.svg {
    width: 20px;
}

@media (max-width: 768px) {
  .top-bar {
    width: 100% !important; /* Set the width to 100% minus the width of the left menu */
    padding-left: 80px !important;
    margin-left: 0px !important;
  }

  .connect-button {
    padding-right: 20px; /* Add padding to the right side of the Connect button */
  }
}

</style>
