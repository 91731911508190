<template>
  <div
    id="listForSalePopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="listForSalePopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            List for sale
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <form @submit.prevent="listNFTForSale">
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="maticSalePrice"
                class="form-label"
              >Price</label>
              <input
                id="maticSalePrice"
                v-model="maticSalePrice"
                type="number"
                class="form-control"
                placeholder="Enter price in Matic"
                required
                :step="selectedCurrencyOption === 'ETH' ? 'any' : '1'"
                min="0"
              />
            </div>
            <div
              v-if="showPriceAlert"
              class="alert alert-danger mt-3"
            >
              Price cannot be less than 0.
            </div>
            <div style="display: flex; justify-content: space-evenly;"> 
              <select v-model="selectedCurrencyOption" class="form-select">
                <option v-for="option in currencyOptions" :key="option" :value="option">
                  {{ option }}
                </option>
              </select>
              <!-- <div class="" v-for="option in currencyOptions" :key="option" style="display: inline-flex; gap: 5px;">
                <input 
                  class="form-check-input" 
                  type="radio" 
                  name="flexRadioDefault" 
                  :id="option" 
                  :value="option" 
                  v-model="selectedCurrencyOption"
                >
                <label class="form-check-label" :for="option">
                  {{ option }}
                </label>
              </div> -->
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else>Complete Listing</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { createRaribleSdk } from "@rarible/sdk";
import { useToast } from 'vue-toast-notification';
import { getContractAddressByItemId } from '@/utils/contractUtils.js';
import { toCurrencyId, toItemId, toOrderId ,toCollectionId} from "@rarible/types"

export default {
  props: {
    showModal: Boolean,
    product: {},
    type: "fighter",
  },
  data() {
    return {
      maticSalePrice: 0,
      isLoading: false,
      showPriceAlert: false,
      currencyOptions: ['JAB', 'ETH'],
      selectedCurrencyOption: ''
    };
  },
  mounted(){
    console.log(this.type);
    if(this.type === "fighter"){
      this.currencyOptions = ['JAB', 'ETH'];
    }
    else{
      this.currencyOptions = ['ETH'];
    }

    this.selectedCurrencyOption = this.currencyOptions[0];
  },
  methods: {
    closeModal() {
      this.$refs.Close.click();
    },
    async listNFTForSale() {
      try {
        const store = useStore();
        const accountAddress = store.getWalletAddress;

        if (this.maticSalePrice <= 0) {
          this.showPriceAlert = true;
          return;
        } else {
          this.showPriceAlert = false;
        }

        this.isLoading = true;

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const apiKey = process.env.VITE_RARIBLE_API_KEY; 
        const raribleSdk = createRaribleSdk(signer, "testnet", { apiKey });

        // Define the NFT asset details
        let tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        let tokenId = 0;

        // console.log("this.product:", this.product);

        if (this.type === 'item') {
          tokenAddress = getContractAddressByItemId(parseInt(this.product.itemId));
          if(this.product.token_id >= 20000){
            tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
          }
          else if(this.product.token_id >= 10000){
            tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
          }
          tokenId = this.product.token_id;
        } else {
          tokenId = this.product.properties.token_id;
        }

        console.log("this.product:", tokenAddress);

        const price = parseFloat(this.maticSalePrice);
        const currency = (this.selectedCurrencyOption == 'JAB')? `KROMA:${process.env.VITE_JAB_CONTRACT_ADDRESS}`:`ETHEREUM:0x0000000000000000000000000000000000000000`
        const sellRequest = {
          itemId: `KROMA:${tokenAddress}:${tokenId}`,
          amount: 1, // amount of NFTs to sell
          price: price, // Use the price from the input
          currency: toCurrencyId(currency), // Ensure the environment variable is correct
        };

        console.log("sellRequest:", sellRequest);


        const orderId = await raribleSdk.order.sell(sellRequest);

        console.log("Listing successful:", orderId);

        this.isLoading = false;
        useToast().open({
          message: 'Your NFT has been successfully listed.',
          position: 'top-right',
          type: 'success',
        });
        this.closeModal();
      } catch (error) {
        console.error('Error listing NFT on Rarible:', error);
        useToast().open({
          message: 'Error: ' + error.message,
          position: 'top-right',
          type: 'error',
        });
        this.closeModal();
        this.isLoading = false;
      }
    },
  }
};
</script>
