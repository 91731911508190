<template>
  <div class="col-container">
    <div
      v-if="false"
      style="margin-bottom: 50px;"
    >
      <v-skeleton-loader
        :type="'card'"
        :animation="true"
        :width="200"
        :height="300"
        theme="dark"
      />
    </div>
    <div
      v-else
      class="card-container"
      style="padding: 15px;"
    >
      <div
        class="center-content card-container"
        style="position: relative;"
      >
        <img
          class="overlay-content"
          :src="`/images/cards/card/${cardId}.png`"
          alt="card image"
          style="height: 71%; top:14%;"
          onerror="this.onerror=null; this.src='/images/cards/card/0.png';"
        >
        <img
          class="overlay-content image-full-height"
          src="/images/cards/card-frame.png"
          alt="card frame image"
        >
        <img
          class="overlay-content image-full-height"
          src="/images/cards/card-frame-gradient.png"
          alt="card frame gradient image"
        >
        <img
          v-if="card.card_type !== ''"
          class="overlay-content image-full-height"
          :src="`/images/cards/card-type/${card.card_type}.png`"
          alt="card type image"
        >
        <img
          v-if="card.card_sp_use > 0"
          class="overlay-content image-full-height"
          :src="`/images/cards/sp-use/${card.card_sp_use}.png`"
          alt="card SP image"
        >
        <div
          class="overlay-content image-full-height side-space desc-size"
          style="display: flex; flex-direction: column; justify-content: space-between;"
        >
          <div style="height: 18%; width: auto; display: flex; justify-content: space-between; align-items: center;">
            <span
              class="text-header text-shadow"
              style=" margin-bottom: -4px;"
            > {{ card.card_name }} </span>
            <img
              :src="`../images/cards/card-element/${card.card_element}.png`"
              alt="card element image"
              style="height: 60%;"
            >
          </div>
          <div
            class="text-content text-shadow"
            style="height: 35%; width: auto; position: relative;"
          >
            <div
              class="overlay-content text-desc-pos"
              v-html="GetCardDescription()"
            />
            <div
              class="overlay-content text-part-pos"
              style="color: rgb(179, 179, 179);"
            >
              {{ bodyPart.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { kfServiceStore } from '@/stores/kfServiceStore.ts';

import { useCardStore } from '@/stores/card.ts';
import { useSkillStore } from '@/stores/skill.ts';

export default {
    props: {
        cardId: Number,
        bodyPart: {}
    },
    data() {
        return {
            isLoading: false,
            card:{}
        }
    },
    mounted(){
        console.error('card id render:', this.cardId);
        this.card = useCardStore().getCardData(this.cardId);
    },
    methods: {
        GetCardDescription(){
            //var desc = this.GetCardData(id).card_description;
            //console.log("desc ", desc);
            return useSkillStore().getReplaceSkillDescription(this.card?.card_description?? '');
        },
    }
};
</script>

<style scoped>

.image-full-height {
    height: 100%;
    min-height: 300px;
}

.overlay-content {
    position: absolute;
}

.text-header {
    font-family: 'The imposter';
    font-size: 11px;
}

.text-content {
    font-family: 'Timmana';
    font-size: 16px;
    line-height: 17px;
    overflow: hidden;
}

.text-shadow {
    text-shadow: 
        -1.5px  -1.5px  0 #000,  
        1.5px   -1.5px  0 #000,
        -1.5px  1.5px   0 #000,
        1.5px   1.5px   0 #000;
}

.text-part-pos {
    bottom: 0; 
    right: 0; 
    margin-bottom: -4px;
}

.text-desc-pos {
    top: 0; 
    padding-top: 3px;
}

.center-content {
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.card-container {
    height: 100%; 
    /* min-width: 206px; */
    width: auto;
}

.side-space {
    padding: 0 6px 10px 12px;
}

.col-container {
    height: 300px;
    width: 240px; /*250px; */
    /* min-width: 300px; */
    min-height: 300px;
    padding-left: 1.5rem*0.5; 
    padding-right: 1.5rem*0.5;
    /* margin-bottom: 10px; */
}

.desc-size {
    width: 100%; 
    height: 100%;
}

.text-yellow {
    color: yellow;
}

@font-face {
    font-family: 'The imposter';
    src: url('/fonts/The Impostor.ttf') format('truetype');
}

@font-face {
    font-family: 'Timmana';
    src: url('/fonts/Timmana.ttf') format('truetype');
}

@media (max-width: 768px) {
    /* .side-space {
        padding: 0 30px 5px 33px;
    } */

    .text-part-pos {
        margin-bottom: 0px;
    }
}

</style>