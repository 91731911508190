<template>
  <div class="product-shelves">
    <div class="card">
      <div class="card-body">
        <div class="head-title">
          <div class="title">
            <h5 class="card-title">
              {{ title }}
            </h5>
          </div> 
          <a
            href=""
            class="link"
          >
            <router-link
              :to="{ name: 'marketplace_fighters', query: { method: 'recent_list' } }"
              class="link"
            >
              <div class="next-nav">
                <h6 class="card-title-next-nav text-white">View all</h6>
                <img
                  src="../assets/right-chevron.png"
                  class="icon-next-nav"
                >
              </div>
            </router-link>
          </a>
        </div>
        <div v-if="products.length<=0">
          <div style="display: flex; flex-direction: row; overflow-x: auto;">
             <div v-for="index in 6" :key="index">
              <v-skeleton-loader
                :type="'card'"
                :animation="true"
                :width="200"
                :height="360"
                theme="dark"
                style="margin-right: 24px;"
              ></v-skeleton-loader>
            </div> 
          </div>
        </div>
        <div v-else>
          <div class="product-list">
            <!-- <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            /> -->
            <div v-for="(product, index) in products" :key="index">
              <ProductFighter v-if="product.isFighter"
                :product="product"
              />
              <ProductItem v-else
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductFighter from '@/components/ProductFighterItem.vue'; // Update the path based on your project structure
import ProductItem from '@/components/NftItem.vue';

export default {
  components: {
    ProductItem,
    ProductFighter
  },
  props: {
    title: String,
    products: Array,
  }
};
</script>
  
<style scoped>
.product-shelves {
  margin-bottom: 40px;
  width: 100%;
}

.list-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.product-list {
  display: flex;
  overflow-x: auto;
}

.product-item {
  flex: 0 0 auto;
  margin-right: 20px;
  margin-bottom: 10px;
  /* Style individual product items here */
}

.head-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* border: 1px solid red; */
  /* align-items: center; */
}

.title {
  display: flex;
  /* border: 1px solid red; */
  /* align-items: center; */
}

.next-nav {
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
}

.card-title-next-nav {
  font-size: 18px;
  width: 70px;
  height: 20px;
  /* border-style: solid; */
  margin: 0px;
  text-decoration: none;
}

.icon-next-nav {
  width: 20px;
  height: 20px;
  /* border: 1px solid red; */
}

.link {
  text-decoration: none;
  color: white;
}

@media (max-width: 768px) {
    .product-item {
        margin-right: 10px;
    }
    .card-title-next-nav {
      font-size: 16px;
    }
}
</style>
  