<template>
    <div
      id="successPopup"
      class="modal fade zoom-in"
      tabindex="-1"
      aria-labelledby="successPopup"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body" style="padding: 30px; display: grid;">
            <div class="text-header">Coaching Successfully</div>
            
            <!--<div class="text-recieved" style="justify-self: center;">
                <img src="~@/assets/images/obtain-reward.png">
            </div>-->
  
            <div
              style="margin-top: -10px; margin-bottom: 0; height: 200px; justify-self: center; position: relative;"
            >
      
              <img src="~@/assets/images/checkmark.png" alt="Product Image" class="product-image">
            </div>
  
            <span style="justify-self: center; font-size: 22px;">
              {{ message }}
            </span>
            <span style="justify-self: center; font-size: 22px;">
              {{ subMessage }}
            </span>
            
            <div style="justify-self: center; margin-top: 40px;">
              <button
                ref="Close"
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                Go to Inventory
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showModal: Boolean,
      type: {
        type: String,
        default: 'coach',
        required: true
      },
      item: {
        type: Object,
        required: true
      }
    },
    methods: {
      closeModal() {
        console.log('Closing modal');
        this.$refs.Close.click();
        this.$router.push('/inventory');
      }
    },
    computed: {
      message() {
        console.log("this.type", this.type);
        switch (this.type) {
          case 'coach':
            return 'An invitation pass has been sent to your new fighter ';
          case 'open':
            return 'New Fighter Successfully Onboarded';
          default:
            return 'Default message';
        }
      },
      subMessage() {
        console.log("this.type", this.type);
        switch (this.type) {
          case 'coach':
            return 'You will receive the Fighter Ticket soon';
          case 'open':
            return 'the Fighter will be deliver to you soon';
          default:
            return 'Default message';
        }
      }
    }
  };
  </script>
  <style scoped>
  .modal-dialog {
    max-width: 1080px;
    max-height: 500px;
  }
  .modal-content {
    border: 0px;
    background-color: transparent;
  }
  .modal.zoom-in .modal-dialog {
    transform: scale(0.8);
    transition: transform 0.3s ease-out;
  }
  .modal.show.zoom-in .modal-dialog {
    transform: scale(1);
  }
  .modal {
    background-color: rgba(3, 3, 3, 0.8);
  }
  .hidden-audio {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .product-image {
    height: 170px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-header {
    justify-self: center;
    font-size: 28px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    .product-image {
      height: 120px;
      width: auto;
    }
  }
  </style>
  