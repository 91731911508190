<template>
  <div
    id="craftPopup"
    class="modal fade no-padding"
    tabindex="-1"
    aria-labelledby="craftPopup"
    aria-hidden="true"
    @click.self="selectButton"
  >
    <div class="modal-dialog modal-dialog-centered" style="--bs-modal-width: 500px;">
      <div class="modal-content" style="background-color: #151030; border: 1px solid #7A37E6; border-radius: 0;">
        <div class="modal-header">
          <!-- <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Craft Your Materials
          </h5> -->
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="selectButton"
          />
        </div>
        <div class="modal-body" style="padding: 0;">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Craft Your Materials
          </h5>
          <div class="" style="width: 100%; display: inline-flex; align-items: center; justify-content: center; font-weight: 500; color: white; font-size: 18px;">
            Choose your desired outcome
          </div>
          <div style="padding: 10px 20px; display: flex; align-items: center; justify-content: center; flex-direction: column;">
            <!-- <div style="font-weight: 600;">
              recipes
            </div> -->
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin: 10px; overflow-x: auto; gap: 15px; flex-wrap: wrap;">
              <template v-for="recp in recipesList">
                <label
                  v-if="recp.id > 0"
                  :key="recp.id"
                  class="btn btn-secondary dropdown-filter-toggle"
                  :class="{ active: isSelectedRecipe(recp.id) }"
                  style="padding: 10px; cursor: pointer; display: inline-flex; align-items: center; width: 20%;"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    v-model="selectedRecipe"
                    @change="selectRecipe(recp)"
                    style="display: none;"
                  />
                  <!-- <img width="30" :src="`../images/item/${recp.code}.png`" alt="Element icon" style="padding-right: 5px;" /> -->
                  <span class="content-text">{{ recp.name }}</span>
                </label>
              </template>
            </div>  
            <div class="d-flex justify-content-center align-items-center" style="margin: 0 30px; background-color: #101327; width: 100%; border-radius: 8px; flex-direction: column;">
              <!-- <div style="color: #B6B6BB; margin: 5px; font-weight: 500;">material</div> -->
              <div class="d-flex justify-content-center align-items-center" style="gap: 20px;">
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${getMaterialUse()?.code || ''}.png`" alt="material 1 icon" style="" />
                  <div class="detail-text width-detail-text">
                    <!-- <span id="amountId">{{ getMatetialAmount(getMaterialUse()?.code?? '') }}</span>/{{ materialUseTotal }} -->
                    {{ materialUseTotal }}
                  </div>
                </div>
                <img width="20" :src="`../images/arrow-icon.png`" alt="icon" style="" />
                <div class="d-flex justify-content-center align-items-center" style="flex-direction: column;">
                  <img class="material-icon" :src="`../images/item/${selectedRecipe.code || ''}.png`" alt="material 1 icon" style="" />
                  <div id="resultAmountId" class="detail-text width-detail-text">
                    {{ craftAmount }}
                  </div>
                </div>
              </div>
              <div style="color: #FFD600; margin: 5px; font-weight: 500; padding: 10px; font-size: 18px;">success rate {{ getMaterialUse()?.rate?? 0 }}%</div>
            </div>
            <div class="d-flex align-items-end" style="width: 90%; margin: 10px 0; height: auto; justify-content: center; background-color: #241D4F; border-radius: 8px; padding: 15px 30px;">
              Tip: If crafting fails, Meterial will break down into {{ getMaterialUse()?.name || '' }}.
            </div>
            <div class="d-flex align-items-end" style="width: 100%; margin: 10px 0; height: 40px; justify-content: center;">
              <!-- <div class="detail-text" style="background-color: black; width: 100px; height: 80%;"> total: </div> -->
              <div class="d-flex justify-content-end align-items-end" style="height: 100%; gap: 10px;">
                <!-- <div class="d-flex justify-content-center align-items-center" style="width: auto; height: 80%;">
                  <button 
                    :class="['amount-content', 'btn-decrease', { 'btn-disabled': isDecreaseDisable }]" 
                    :disabled="isDecreaseDisable" 
                    @click="updateCraftAmount(false)" 
                    style="border-top-left-radius: 8px; border-bottom-left-radius: 8px;"> - 
                  </button>
                  <span class="amount-content d-flex justify-content-center align-items-center" style=""> {{ craftAmount }} </span>
                  <button 
                    :class="['amount-content', 'btn-increase', { 'btn-disabled': isIncreaseDisable }]" 
                    :disabled="isIncreaseDisable" 
                    @click="updateCraftAmount(true)" 
                    style="border-top-right-radius: 8px; border-bottom-right-radius: 8px;"> + 
                  </button>
                </div>
                <button class="detail-text" @click="getMaxCraft()" style="background-color: black; width: 50px; height: 80%; padding: 0 10px;"> max </button> -->
                <button :class="['btn', 'btn-primary', { 'btn-disabled': isCraftDisable }]" :disabled="isCraftDisable" @click="craft" style="background-color: black; padding: 0 50px; height: 100%; font-size: 20px; font-weight: 500;"> 
                  <div v-if="isLoading">
                    <i class="fa fa-circle-o-notch fa-spin" />
                  </div>
                  <div v-else>
                    craft
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { getSignature } from '@/utils/web3Utils';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import {useToast} from 'vue-toast-notification';
import { ref, onMounted, watch } from 'vue';
import CraftABI from '@/contracts/CraftABI.json';
import { ethers } from 'ethers';

export default {
    components: {
    },
    props: {
        showModal: Boolean,
        // totalFragment: {
        //     type: Object,
        //     required: true
        // }
        // selectedDefault: {
        //     type: Number,
        //     required: true,
        //     default: () => 0
        // }
    },
    data() {
        return {
          totalFragment: [{code:'ITM_FM_MCC', amount:0},
                            {code:'ITM_FM_GLB', amount:0},
                            {code:'ITM_FM_HLB', amount:0},
                            {code:'ITM_FM_FST', amount:0}],
          recipesList: [{id:0, code:'ITM_FM_MCC', name:'Microship', material_use:5, rate:100}, 
                        {id:1, code:'ITM_FM_GLB', name:'Gold Bar', material_use:2, rate:80}, 
                        {id:2, code:'ITM_FM_HLB', name:'Holo Bar', material_use:2, rate:70}, 
                        {id:3, code:'ITM_FM_FST', name:'Suit'}],
          selectedRecipe: {},
          craftAmount: 1,
          materialUseTotal: 0,
          isDecreaseDisable: false,
          isIncreaseDisable: false,
          isCraftDisable: false,
          isLoading: false
        };
    },
    // watch: {
    //   selectedDefault: {
    //     handler(newVal, oldVal) {
    //       console.log('selectedDefault :', newVal);
    //       this.selectedRecipe = this.recipesList.find(f=>f.id == newVal);
    //     },
    //     immediate: true
    //   }
    // },
    mounted() {
      // this.selectedRecipe = this.recipesList.find(f=>f.id == 1);
      // console.log(this.totalFragment);
      this.selectRecipe(this.recipesList.find(f=>f.id == 1));
      if(useStore().getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => useStore().getWalletAddress, () => {
                this.fetchData();
            });
        }
    },
    methods: {
      async fetchData() {
            try {
                const fragmentCollection = "0x6c8Eb0f85D7Fa338C267b559cC8B5055E8e26754";
                const fragmentRequestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${useStore().getWalletAddress}`],
                        collections: [`KROMA:${fragmentCollection}`]
                    }
                };
                const fragmentApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const fragmentResponse = await axios.post(fragmentApiUrl, fragmentRequestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("fragmentResponse ",fragmentResponse);

                fragmentResponse.data.ownerships.forEach(nft => {
                    var itemCode = '';
                    switch(nft.tokenId){
                        case "0": itemCode = 'ITM_FM_HLB'; break;
                        case "1": itemCode = 'ITM_FM_GLB'; break;
                        case "2": itemCode = 'ITM_FM_MCC'; break;
                    }

                    if(itemCode !== ''){
                        this.totalFragment.find(f=>f.code === itemCode).amount = Number(nft.value);
                    }
                });
                console.log(this.totalFragment);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        closeModal(){
          console.log('closeModal');
          // this.$refs.Close.click();
        },
        selectButton() {
            console.log('Closing modal'); // Add this line
            // this.$emit('selectButton', 0);
            // this.closeModal();
            this.$emit('refreshTotal');
        },
        isSelectedRecipe(id){
          // console.log(this.selectedRecipe);
          return this.selectedRecipe ? (this.selectedRecipe.id === id) : false;
        },
        selectRecipe(recp){
          this.selectedRecipe = recp;

          this.craftAmount = 1;
          this.materialUseTotal = this.getMaterialUse()?.material_use?? 0;
          this.updateButton();
        },
        updateCraftAmount(isIncrease){
          if (isIncrease) {
            this.craftAmount++; // Increment
          } else {
            this.craftAmount--; // Decrement
          }

          this.materialUseTotal = (this.getMaterialUse()?.material_use?? 0)*this.craftAmount;
          
          this.updateButton();
        },
        updateButton(){
          if(this.craftAmount == 1){
            this.isDecreaseDisable = true;
          }
          else{
            this.isDecreaseDisable = false;
          }

          // const hasTotal = this.getMatetialAmount(this.getMaterialUse()?.code?? '');

          // const amountIdElement = document.getElementById('amountId');
          // if(hasTotal < this.materialUseTotal){
          //   this.isCraftDisable = true;
          //   amountIdElement.style.color = 'red';
          // }
          // else{
          //   this.isCraftDisable = false;
          //   amountIdElement.style.color = 'lightgreen';
          // }
        },
        getMaterialUse(){
          return this.recipesList.find(f=>f.id == ((this.selectedRecipe?.id?? 0)-1));
        },
        getMatetialAmount(code){
          return this.totalFragment?.find(f=>f.code == code)?.amount?? 0;
        },
        getMaxCraft(){
          const materialUse = this.getMaterialUse()?.material_use?? 0;
          const maxResult = Math.floor(this.getMatetialAmount(this.getMaterialUse()?.code?? '') / materialUse); 

          this.craftAmount = maxResult;
          this.materialUseTotal = materialUse*this.craftAmount;
          this.updateButton();
        },
        async craft(){
          this.isCraftDisable = true;
          this.isLoading = true;
          const hasTotal = this.getMatetialAmount(this.getMaterialUse()?.code?? '');

          if(hasTotal < (this.getMaterialUse()?.material_use?? 0)){
          // if(false){
            useToast().open({
                        message: `Error: Can not craft ${this.selectedRecipe.name}.`,
                        position: 'top-right',
                        type: 'error',
                    });
          }
          else{
            try{
              const contractAddress = "0x07Ff76058F9DF2f299b0BEf80bEF3205F758DCb7";
              const provider = new ethers.providers.Web3Provider(window.ethereum);
              const signer = await provider.getSigner();
              const craftContract = new ethers.Contract(contractAddress, CraftABI, signer);
              const web3 = new Web3(window.ethereum);
              const accounts = await web3.eth.getAccounts();
              // console.log(accounts);

              // var isSuccess = false;
              if(accounts){
                switch(this.selectedRecipe.id){
                  case 1: const receipt1 = await craftContract.craftGoldBar({ from: accounts[0] });
                          console.log('Crafted Gold Bar', receipt1);

                          // const event1 = receipt1.logs.find(log => log.event === 'GoldBarCrafted');
                          // console.log(event1);
                          // if (event1) {
                          //     const success = event1.args.success;
                          //     console.log(`Crafted GoldBar - Success: ${success}`);
                          //     isSuccess = true;
                          // } else {
                          //     console.log('Crafted GoldBar - Event not found');
                          // }
                          break;
                  case 2: const receipt2 = await craftContract.craftHoloBar({ from: accounts[0] });
                          console.log('Crafted Holo Bar', receipt2);

                          // const event2 = receipt2.logs.find(log => log.event === 'HoloBarCrafted');
                          // if (event2) {
                          //     const success = event2.args.success;
                          //     console.log(`Crafted HoloBar - Success: ${success}`);
                          //     isSuccess = true;
                          // } else {
                          //     console.log('Crafted HoloBar - Event not found');
                          // }
                          break;
                  case 3: const receipt3 =  await craftContract.craftFighterSuit({ from: accounts[0] });
                          console.log('Crafted Fighter Suit', receipt3);

                          // const event3 = receipt3.logs.find(log => log.event === 'FighterSuiteCrafted');
                          // if (event3) {
                          //     const success = event3.args.success;
                          //     console.log(`Crafted Fighters Suit - Success: ${success}`);
                          //     isSuccess = true;
                          // } else {
                          //     console.log(`Crafted Fighters Suit - Event not found`);
                          // }
                          break;
                }

                useToast().open({
                        message: `Craft ${this.selectedRecipe.name} completed.`,
                        position: 'top-right',
                        type: 'success',
                    });

                // if(true) {
                //   const materialUse = this.getMaterialUse();
                //   if(materialUse){
                //     const material = this.totalFragment?.find(f => f.code === materialUse.code);
                //     if (material) {
                //         material.amount -= materialUse.material_use || 0;
                //     }
                //   }

                //   const craftedMaterial = this.totalFragment?.find(f => f.code === this.selectedRecipe.code);
                //   if (craftedMaterial) {
                //     craftedMaterial.amount++;
                //   }
                // }
                await this.fetchData();

                // console.log(this.totalFragment);
              }
            } catch (error) {
                console.error('Error deposit:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
            }
          }
          this.isCraftDisable = false;
          this.isLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
  .no-padding {
    padding: 0 !important;
  }

  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .dropdown-filter-toggle {
    background-color: rgb(68 64 88);
  }

  .modal-title {
    text-align: center;
    width: 100%;
    font-weight: 500;
    color: #ff65fa;
    font-size: 28px;
    margin-bottom: 5px;
  }

  .dropdown-filter-toggle.active {
    background-color: #ff65fc55;
    border-color: #ff65fa;
    color: white;
  }

  .detail-text {
    color: white;
    display: inline-flex;
    align-items: center;
    background-color: var(--light-background-color);
    border-radius: 8px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    width: 60px;
    font-weight: 500;
  }

  .width-detail-text{
    width: 100px;
  }

  .material-icon {
    width: 100px;
  }

  .btn-decrease {
    background-color: black;
  }

  .btn-increase {
    background-color: black;
  }

  .btn-decrease:hover:not(.btn-disabled){
    background-color: darkred;
  }

  .btn-increase:hover:not(.btn-disabled) {
    background-color: darkgreen;
  }

  .btn-disabled{
    background-color: #e0e0e0; /* Grey */
    color: darkgray; /* Light grey */
    cursor: not-allowed;
    opacity: 0.6;
  }

  .amount-content {
    width: 40px; 
    height: 100%; 
    background-color: black;
    color: white;
  }

  .content-text {
    width: 100%;
    text-align: center;
  }

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }

    .material-icon {
      width: 70px;
    }
}
</style>
  
