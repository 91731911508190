<template>
  <div class="page-content content-wrap">
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div class="d-flex align-items-left">
        <img
          src="../assets/icon-dojo.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >
        <h1>Coaching Lab</h1>
      </div>
      <div
        class="no-space"
        style="display: flex; flex-direction: column;"
      >
        <form @submit.prevent="Coach">
          <div
            class="row no-space material-container"
            style="height: auto;"
          >
            <div class="material-box">
              <div style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">
                Master 1
              </div>
              <div style="display: flex; width: 72.5%; display: inline-flex; justify-content: center; gap: 10px; align-items: center; margin-bottom: 15px;">
                <div style="width: auto; white-space: nowrap; flex:5 1;">
                  Token ID
                </div>
                <div
                  class=""
                  style="flex: 10 1; width: auto;"
                >
                  <div
                    class="dropdown"
                    style="display: flex; justify-content: center;"
                  >
                  <button class="btn btn-secondary dropdown-filter-toggle" type="button"
                                            id="classDropdown1" data-bs-toggle="modal" data-bs-target="#pickMasterPopup" @click="SelectOption(1)" style="width: 100%;">
                                            {{ GetMasterToken(1) }}
                                        </button>
                    <!-- <ul
                      class="dropdown-menu"
                      aria-labelledby="selectDropdown1"
                    >
                      <li
                        v-for="option in GetAllToken(1)"
                        :key="option.id"
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="SelectOption(1, option.id)"
                        >
                          {{ option.id }}
                        </a>
                      </li>
                    </ul> -->
                  </div>
                </div>
                <!-- <form class="input-form">
                                    <div class="" style="flex: 5 1;">
                                        <div class="" style="box-sizing: border-box;">
                                            <div class="" style="position: relative;">
                                                <input v-model="token_master1" type="text" id="token_master1" class="input-field" @input="GenereateMasterImage(1)" placeholder="Enter token ID" required>
                                            </div>
                                        </div>
                                    </div>
                                </form> -->
              </div>
              <div class="material-use">
                <img
                  v-if="isShow_img_master1"
                  id="image_master1"
                  class="material-icon"
                  alt="master 1 image"
                >
                <div v-else>
                  <span v-if="isLoading_img_master1">
                    <i class="fa fa-circle-o-notch fa-spin" />
                  
                  </span>
                  <span
                    v-else
                    style="height: auto;"
                  >Master 1 Image</span>
                </div>
              </div>
            </div>
            <div
              class="material-box"
              style="display: flex; justify-content: center; width: 400px; padding: 0; gap: 5px;"
            >
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <img
                    v-if="master1?.properties?.class_name?? '' !== ''"
                    :src="GetClassImage(master1.properties.class_id)"
                    alt="Class Icon"
                    class="info-img-icon"
                  >
                  {{ master1?.properties?.class_name?? "" }}
                </div>
                <div class="col-5 text-center info-text">
                  Class
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <img
                    v-if="master2?.properties?.class_name?? '' !== ''"
                    :src="GetClassImage(master2.properties.class_id)"
                    alt="Class Icon"
                    class="info-img-icon"
                  >
                  {{ master2?.properties?.class_name?? "" }}
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <img
                    v-if="GetElementImage(master1?.properties?.element?? '') !== ''"
                    :src="GetElementImage(master1.properties.element)"
                    alt="Element Icon"
                    class="info-img-icon"
                  >
                  {{ master1?.properties?.element?? "" }}
                </div>
                <div class="col-5 text-center info-text">
                  Element
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <img
                    v-if="GetElementImage(master2?.properties?.element?? '') !== ''"
                    :src="GetElementImage(master2.properties.element)"
                    alt="Element Icon"
                    class="info-img-icon"
                  >
                  {{ master2?.properties?.element?? "" }}
                </div>
              </div>
              
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <span v-if="(master1?.properties?.coaching_count?? '') !== ''"> {{ master1?.properties?.coaching_count?? 0 }} / 6 </span>
                </div>
                <div class="col-5 text-center info-text">
                  Coaching Count
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <span v-if="(master2?.properties?.coaching_count?? '') !== ''"> {{ master2?.properties?.coaching_count?? 0 }} / 6 </span>
                </div>
              </div>
            </div>
            <div class="material-box">
              <div style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">
                Master 2
              </div>
              <div style="display: flex; width: 72.5%; display: inline-flex; justify-content: center; gap: 10px; align-items: center; margin-bottom: 15px;">
                <div style="width: auto; white-space: nowrap; flex:5 1;">
                  Token ID
                </div>
                <div
                  class=""
                  style="flex: 10 1; width: auto;"
                >
                  <div
                    class="dropdown"
                    style="display: flex; justify-content: center;"
                  >
                  <button class="btn btn-secondary dropdown-filter-toggle" type="button"
                                            id="classDropdown2" data-bs-toggle="modal" data-bs-target="#pickMasterPopup" @click="SelectOption(2)" style="width: 100%;">
                                            {{ GetMasterToken(2) }}
                                        </button>
                    <!-- <ul
                      class="dropdown-menu"
                      aria-labelledby="selectDropdown2"
                    >
                      <li
                        v-for="option in GetAllToken(2)"
                        :key="option.id"
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          @click="SelectOption(2, option.id)"
                        >
                          {{ option.id }}
                        </a>
                      </li>
                    </ul> -->
                  </div>
                </div>
              </div>
              <div class="material-use">
                <img
                  v-if="isShow_img_master2"
                  id="image_master2"
                  class="material-icon"
                  alt="master 2 image"
                >
                <div v-else>
                  <span v-if="isLoading_img_master2"><i class="fa fa-circle-o-notch fa-spin" /></span>
                  <span
                    v-else
                    style="height: auto;"
                  >Master 2 Image</span>
                </div>
              </div>
            </div>
            <!-- <PickMasterPopup 
              :tokenOptions="tokenOptions" 
              @setMasterToken="SetMasterToken" 
            /> -->
          </div>
          <div
            class="row material-container"
            style="height: 15%; margin: -10px 0 0 0; flex-direction: column; align-items: center; gap: 10px;"
          >
            <div style="text-align: center; display: inline-flex; align-items: center; justify-content: center; gap: 5px;">
              <img
                    src="../assets/icons/icon-jab.png"
                    alt="Owner Icon"
                    style="width: 35px; height: 35px;"
                  >
              <span style="" v-html="GetCostText()"></span> 
            </div>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading || CheckDisableCoach()"
              style="width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Coach </span>
            </button>
          </div>
        </form>
        <div
          v-if="isShow_img_disciple"
          class="row no-space material-container"
          style="height: auto; display: flex;"
        >
          <div
            class="material-box"
            style="height: auto;"
          >
            <canvas
              ref="canvas"
              :width="canvasWidth"
              :height="canvasHeight"
              style="display:none"
            />
            <img
              v-if="mergedImage"
              :src="mergedImage"
              alt="Merged Image"
              style="width: 100%; height: auto;"
            >
          </div>
          <div
            v-if="disciple && Object.keys(disciple).length > 0"
            class=""
            style="display: flex; flex-direction: column; width: 20%; justify-content: flex-end; padding-top: 30px"
          >
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Class
                </p>
                <div style="display: flex;">
                  <img
                    v-if="disciple?.class?? '' !== ''"
                    :src="GetClassImage(disciple.class)"
                    alt="Class Icon" 
                    class="info-img-icon"
                    style="margin-right: 6px;"
                  >
                  <h6 class="">
                    {{ GetClassName(disciple.class) }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Element
                </p>
                <div style="display: flex;">
                  <img
                    v-if="disciple?.element?? '' !== ''"
                    :src="GetElementImage(disciple.element)"
                    alt="Element Icon" 
                    class="info-img-icon"
                    style="margin-right: 6px;"
                  >
                  <h6 class="">
                    {{ disciple?.element?? '' }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Spirit Socket
                </p>
                <div
                  class="info-img-display"
                  style="display: flex;"
                >
                  <img
                    v-if="(disciple?.spirit_socket1?? 0 > 0) && (disciple?.spirit_socket1?? 0 < 6)"
                    :src="`/images/runes/shape-${disciple.spirit_socket1}.png`"
                    alt="Spirit socket 1 Icon"
                    class="info-img-icon"
                  >
                  <img
                    v-if="(disciple?.spirit_socket2?? 0 > 0) && (disciple?.spirit_socket2?? 0 < 6)"
                    :src="`/images/runes/shape-${disciple.spirit_socket2}.png`"
                    alt="Spirit socket 2 Icon"
                    class="info-img-icon"
                  >
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Spirit Point
                </p>
                <div
                  v-if="(disciple?.spirit_points?? '') !== ''"
                  class="info-img-display"
                  style="display: flex;"
                >
                  <div
                    v-for="(value, key) in disciple.spirit_points"
                    :key="key"
                  >
                    <div v-if="key !== ''">
                      <img
                        v-if="GetElementImage(key) !== ''"
                        :src="GetElementImage(key)"
                        alt="Spirit Socket Icon"
                        class="info-img-icon"
                      >
                      <span> {{ value }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: auto;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Normal Skill
                </p>
                <div
                  v-if="skills_disciple.length > 0"
                  class="info-img-display"
                  style="justify-content: flex-end;"
                >
                  {{ skills_disciple[0]?.skill_name?? '' }},<br>
                  {{ skills_disciple[1]?.skill_name?? '' }},<br>
                  {{ skills_disciple[2]?.skill_name?? '' }}
                  <!-- <div v-for="(x, i) in skills_disciple">
                                        <img v-if="x" :src="`/images/skills/${x.skill_code}.png`" alt="Super Move Icon" class="info-img-icon" 
                                        onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                    </div> -->
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Super Move
                </p>
                <div
                  v-if="cards_disciple.length > 0"
                  class="info-img-display"
                  style="justify-content: flex-end;"
                >
                  <div v-for="(x, i) in cards_disciple">
                    <img
                      :src="`/images/cards/card/${x}.png`"
                      alt="Super Move Icon"
                      class="info-img-icon"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
  <PickMasterPopup 
              :tokenOptions="GetAllToken()" 
              @setMasterToken="SetMasterToken" 
            />
  <CoachLoadingPopup :message="message"
  :is-confirmed="isConfirmed" />
  <SuccessPopup :type="coach"></SuccessPopup>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { getClassImageUrl ,getElementImageUrl } from '@/utils/imageUtils.js';
import { useCardStore } from '@/stores/card.ts';
import {useToast} from 'vue-toast-notification';
import { useSkillStore } from '@/stores/skill.ts';
import { ethers } from 'ethers';
import { useStore } from '@/stores/index.ts';

import CoachABI from '@/contracts/CoachABI.json';
import PickMasterPopup from '@/components/popup/PickMasterPopup.vue';

import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';

import CoachLoadingPopup from "@/components/popup/CoachLoadingPopup.vue";
import SuccessPopup from "@/components/popup/SuccessPopup.vue";


export default {
    components: {
      PickMasterPopup,
      CoachLoadingPopup,
      SuccessPopup
    }, data() {
        return {
            isLoading: false,
            master1: {},
            isLoading_img_master1: false,
            isShow_img_master1: false,
            token_master1: "",
            cards_master1: [],
            skills_master1: [],
            isConfirmed: false,
            master2: {},
            isLoading_img_master2: false,
            isShow_img_master2: false,
            token_master2: "",
            cards_master2: [],
            skills_master2: [],
            disciple: {},
            cards_disciple: [],
            skills_disciple: [],
            isShow_img_disciple: false,
            isLoading_img_disciple: false,
            mergedImage: null,
            canvasWidth: 0,
            canvasHeight: 0,
            tokenOptions: [],
            selectMaster: 0,
            showModal: false,
            message: {}
        };
    },
    computed: {
    },
    async mounted() {
        try {
            if(useStore().getWalletAddress!=''){
                this.fetchFighterData();
            }else{
                watch(() => useStore().getWalletAddress, () => {
                    this.fetchFighterData();
                });
            }

            await useCardStore().fetchCardData();
            //await useCardStore().fetchPartMapper();
            await useSkillStore().fetchMapSkillData();
        } catch (error){ 
            console.error('Error:', error);
        }
    },
    methods: {
        GetCostText(){
          if(this.GetCoachingCost() > 0 && (this.isLoading || this.CheckDisableCoach())){
            return `<p style="color: red; margin-bottom: 0;">${this.GetCoachingCost()}</p>`;
          }
          else{
            return this.GetCoachingCost();
          }
        },
        CheckDisableCoach(){
          // console.log(useStore().getWalletBalance.jab);
          return (!(Object.keys(this.master1).length > 0) || !(Object.keys(this.master2).length > 0) || (useStore().getWalletBalance.jab < this.GetCoachingCost()));
        },
        GetCoachingCost(){
          var totalCost = 0;

          var coachList = [];

          if((this.master1?.properties?.coaching_count?? -1) != -1){
            coachList.push(this.master1.properties?.coaching_count);
          }

          if((this.master2?.properties?.coaching_count?? -1) != -1){
            coachList.push(this.master2.properties?.coaching_count);
          }

          if(coachList.length < 2){
            return 0;
          }

          coachList.forEach((data) => { 
            var cost = 0;
            for(let i=1; i<=6; i++) {
              if(data < i || i == 6){
                switch(i){
                  case 1: cost = 1000; break;
                  case 2: cost = 1500; break;
                  case 3: cost = 2200; break;
                  case 4: cost = 3300; break;
                  case 5: cost = 5000; break;
                  case 6: cost = 10000; break;
                }
                totalCost += cost;
                break;
              }
            }
          });

          return totalCost;
        },
        GetMasterToken(masterNo){
            var defaultText = "Select";
            if(masterNo == 1){
                return (this.token_master1 == "")? defaultText:this.token_master1;
            }
            else if(masterNo == 2){
                return (this.token_master2 == "")? defaultText:this.token_master2;
            }
        },
        SetMasterToken(tokenId){
            console.log("SetMasterToken "+tokenId);
            if(this.selectMaster == 1){
                this.token_master1 = tokenId;
            }
            else if(this.selectMaster == 2){
                this.token_master2 = tokenId;
            }

            this.GenereateMasterImage(this.selectMaster);
        },
        GetAllToken(){
            if(this.tokenOptions.length > 0){
                if(this.selectMaster == 1){
                    return this.tokenOptions.filter(token => token.id !== this.token_master2);
                }
                else{
                    return this.tokenOptions.filter(token => token.id !== this.token_master1);
                }
            }
            else{
                return [];
            }
        },
        SelectOption(masterNo){
            this.selectMaster = masterNo;
            this.showModal = true;
            // if(masterNo == 1){
            //     this.token_master1 = tokenId;
            // }
            // else{
            //     this.token_master2 = tokenId;
            // }

            // this.GenereateMasterImage(masterNo);
        },
        async fetchFighterData(){
            try {
                const accountAddress = useStore().getWalletAddress;
                const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
                const apiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                const requestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${collection}`]
                    }
                };
                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("gen1Response ",response);

                this.tokenOptions = response.data.ownerships.map(nft => {
                    return {
                        id: nft.tokenId
                    };
                });
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        },
        GetCards(selectedNo){
            var selectedFighter = {};
            if(selectedNo == 1){
                this.cards_master1 = [];
                selectedFighter = this.master1;
            }
            else if(selectedNo == 2){
                this.cards_master2 = [];
                selectedFighter = this.master2;
            }
            else{
                this.cards_disciple = [];
                selectedFighter = this.disciple;
            }

            var body_part = [];
            if(selectedNo == 1 || selectedNo == 2){
                body_part = [{id: selectedFighter.properties.body_part_top_id, name: selectedFighter.properties.body_part_top},
                            {id: selectedFighter.properties.body_part_body_id, name: selectedFighter.properties.body_part_body}];
            }
            else{
                body_part = [{id: selectedFighter.body_part_top_id, name: selectedFighter.body_part_top},
                            {id: selectedFighter.body_part_body_id, name: selectedFighter.body_part_body}];
            }

            body_part.forEach((data, index) => {
                // console.log("part idddddd"+data.id);
                // console.log("part nameeeee"+data.name);
                if(selectedNo == 1){
                    this.cards_master1.push(useCardStore().getCardIdFromPart(data.id));
                }
                else if(selectedNo == 2){
                    this.cards_master2.push(useCardStore().getCardIdFromPart(data.id));
                }
                else{
                    this.cards_disciple.push(useCardStore().getCardIdFromPart(data.id));
                }
            });

            // console.log("cards" + this.cards_master1.length);
        },
        GetSkills(selectedNo){
            var selectedFighter = {};
            if(selectedNo == 1){
                this.skills_master1 = [];
                selectedFighter = this.master1;
            }
            else if(selectedNo == 2){
                this.skills_master2 = [];
                selectedFighter = this.master2;
            }
            else{
                this.skills_disciple = [];
                selectedFighter = this.disciple;
            }

            const _newSkill = [];
        
            const _firstSkill = useSkillStore().getDefaultSkillData((selectedNo == 1 || selectedNo == 2)? selectedFighter.properties.class_id:selectedFighter.class);
            if((_firstSkill?.skill_code?? '') != ''){
                _newSkill.push(_firstSkill);
            }
            else{
                if(selectedNo == 1 || selectedNo == 2){
                    _newSkill.push(selectedFighter.properties.skills[0]);
                }
            }

            var body_part = [];
            if(selectedNo == 1 || selectedNo == 2){
                body_part = [{id: selectedFighter.properties.body_part_top_face_id, name: selectedFighter.properties.body_part_top_face},
                            {id: selectedFighter.properties.body_part_pant_id, name: selectedFighter.properties.body_part_pant}];
            }
            else{
                body_part = [{id: selectedFighter.body_part_face_id, name: selectedFighter.body_part_face},
                            {id: selectedFighter.body_part_pant_id, name: selectedFighter.body_part_pant}];
            }

            body_part.forEach((data, index) => {
                const _mapSkill = useSkillStore().getMapSkillData(data.id);
                if((_mapSkill?.skill_code?? '') != ''){
                    _newSkill.push(_mapSkill);
                }
                else{
                    if(selectedNo == 1 || selectedNo == 2){
                        _newSkill.push(selectedFighter.properties.skills[index+1]);
                    }
                }
            });

            // if(selectedNo == 1 || selectedNo == 2){
            //     var selectFighterSkill = selectedFighter.properties.skills;

            //     if(_newSkill.length < 3){
            //         const _diff = 3-_newSkill.length;
            //         for (let i=0; i<_diff; i++){
            //             _newSkill.push(selectFighterSkill[i]);
            //         }
            //     }
            // }

            if(selectedNo == 1){
                this.skills_master1 = _newSkill;
            }
            else if(selectedNo == 2){
                this.skills_master2 = _newSkill;
            }
            else{
                this.skills_disciple = _newSkill;
            }

            // body_part.forEach((data, index) => {
            //     console.log("part idddddd"+data.id);
            //     console.log("part nameeeee"+data.name);
            //     if(selectedNo == 1){
            //         this.skills_master1.push(useSkillStore().getMapSkillData(data.id));
            //     }
            //     else if(selectedNo == 2){
            //         this.skills_master2.push(useSkillStore().getMapSkillData(data.id));
            //     }
            //     else{
            //         this.skills_disciple.push(useSkillStore().getMapSkillData(data.id));
            //     }
            // });

            // console.log("cards" + this.cards_master1.length);
        },
        GetClassName(classId){
            var classList = ['AllRounder', 'Bodyguard', 'Duelist', 'Stalker', 'Acrobat', 'Trickster', 'Destroyer', 'Brawler'];
            return classList[classId-1];
        },
        GetClassImage(classId){
            return getClassImageUrl(classId);
        },
        GetElementImage(element){
            return getElementImageUrl(element);
        },
        ParseSpiritPoint(pointList){
            return JSON.parse(pointList);
        },
        async GenereateMasterImage(masterNo){
            var token = masterNo == 1 ? this.token_master1 : this.token_master2;

            if(masterNo == 1){
                this.isLoading_img_master1 = true;
            }
            else{
                this.isLoading_img_master2 = true;
            }

            if(token != ""){
                try {
                    
                    
                    const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${parseInt(token)}.json`);

                    console.log('Response data:', response);

                    const provider = new ethers.providers.JsonRpcProvider(process.env.VITE_RPC_URL);
                    const fighterContractAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
                    const fighterContract = new ethers.Contract(fighterContractAddress, FighterERC721ABI, provider);

                    const metadata = await fighterContract.getTokenMetadata(token);
                    console.log("Metadata:", metadata);
                    response.data.properties.coaching_count  =  metadata.c_count;

                    if(masterNo == 1){
                        this.master1 = response.data;
                    } else {
                        this.master2 = response.data;
                    }

                    this.$nextTick(() => {
                        var imgId = masterNo == 1 ? 'image_master1' : 'image_master2';
                        var imgElement = document.getElementById(imgId);
                        var imageUrl = response.data.image;
                        imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
                        imgElement.src = imageUrl;
                    });

                    this.GetCards(masterNo);
                    this.GetSkills(masterNo);

                    if(masterNo == 1){
                        this.isShow_img_master1 = true;
                    } else {
                        this.isShow_img_master2 = true;
                    }

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            else{
                this.disciple = {};
                this.isShow_img_disciple = false;
                if(masterNo == 1){
                    this.isShow_img_master1 = false;
                    this.master1 = {};
                    this.cards_master1 = [];
                    this.skills_master1 = [];
                } else {
                    this.isShow_img_master2 = false;
                    this.master2 = {};
                    this.cards_master2 = [];
                    this.skills_master2 = [];
                }
            }

            if(masterNo == 1){
                this.isLoading_img_master1 = false;
            }
            else{
                this.isLoading_img_master2 = false;
            }
        },
        async Coach(){
            if(this.token_master1 != "" && this.token_master2 != ""){
                this.message = {
                      title: '',
                      image: '../images/loading/coach-loading.gif',
                      text: ""
                  }
                  this.isConfirmed = false;
                $(document).ready(function() {
                    $('#coachLoadingPopup').modal('show');
                });


                if (typeof window.ethereum !== 'undefined') {

                    this.isLoading = true;
                    this.isShow_img_disciple = false;

                    try {
                        const contractAddress = process.env.VITE_COACH_CONTRACT_ADDRESS; 
                        const provider = new ethers.providers.Web3Provider(window.ethereum);
                        const signer = await provider.getSigner();
                        
                        //console.log("coachFighters", this.token_master1, this.token_master2);
                        const contract = new ethers.Contract(contractAddress, CoachABI, signer);
                        //console.log("contract", contract.address);


                        const tx = await contract.coachFighters(this.token_master1, this.token_master2);
                        //console.log("tx", tx);
                        this.isConfirmed = true;

                        await tx.wait();
                        const receipt = await provider.getTransactionReceipt(tx.hash);
                        console.log(receipt);

                        this.isLoading = false;
                        $(document).ready(function() {
                            $('#coachLoadingPopup').modal('hide');
                        });

                        $(document).ready(function() {
                            $('#successPopup').modal('show');
                        });
                        /*useToast().open({
                            message: 'Successfully coached.',
                            position: 'top-right',
                            type: 'success',
                        });*/
                    } catch (error) {
                        $(document).ready(function() {
                            $('#coachLoadingPopup').modal('hide');
                        });
                        var errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                        console.error("Error calling coachFighters:", error);
                        if (error.data) {
                            console.error("Error details:", error.data);
                            errorMessage =  error.data;
                        }

                        if (error.reason) {
                            console.error("Error reason:", error.reason);
                            errorMessage = error.reason;
                        }
                        if(error.reason== null && error.data == null){
                           errorMessage += "\n or you might not have enough gas fee";
                        }

                        useToast().open({
                            message: 'Error: ' + errorMessage,
                            position: 'top-right',
                            type: 'error',
                        });
                        this.isLoading = false;
                    }

                    
                } else {
                    console.error("MetaMask is not installed!");
                }
            }
        },
        async mergeImages(url1, url2, url3, url4, url5) {
            this.isLoading_img_disciple = true;
            try {
                const img1 = await this.loadImage(url1);
                const img2 = await this.loadImage(url2);
                const img3 = await this.loadImage(url3);
                const img4 = await this.loadImage(url4);
                const img5 = await this.loadImage(url5);

                // Create a canvas with the desired dimensions
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');

                // Clear the canvas before drawing the new image
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const scaleFactor = 2;

                // Set canvas size
                this.canvasWidth = img1.width/5 * scaleFactor;
                this.canvasHeight = img1.height/3 * scaleFactor;

                // Apply the new canvas size
                canvas.width = this.canvasWidth;
                canvas.height = this.canvasHeight;

                // Disable image smoothing for pixel-perfect scaling
                ctx.imageSmoothingEnabled = false;

                // Scale the canvas
                ctx.scale(scaleFactor, scaleFactor);

                // Draw images on the canvas
                ctx.drawImage(img3, 0, 0);
                ctx.drawImage(img2, 0, 0);
                ctx.drawImage(img1, 0, 0);
                ctx.drawImage(img4, 0, 0);
                ctx.drawImage(img5, 0, 0);

                // Convert canvas to data URL and set to mergedImage
                this.mergedImage = canvas.toDataURL('image/png');
                this.isLoading_img_disciple = false;
                this.isShow_img_disciple = true;
            } catch (error) {
                console.error('Error merging images:', error);
                this.isLoading_img_disciple = false;
            }
        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        }
    }
}
</script>
  
<style scoped>
.no-space {
    margin: 0;
    padding: 0;
}

.material-container {
    display: flex;
    justify-content: center;
    align-content: center;
    /* border: 1px solid green;  */
}

.body-content {
    border: 1px solid green;
    height: 100%; 
    width: 25%;
    justify-content: center;
    align-content: center;
}

.material-box {
    /* border: 1px solid lightslategrey; */
    border-radius: 10px;
    height: 400px;
    width: 300px;
    justify-self: center;
    align-self: center;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.material-use {
    border: 1.5px solid lightslategrey;
    border-radius: 10px;
    height: 70%;
    width: 79%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.material-icon {
    /* border: 1px solid red;  */
    height: 100%; 
    width: 100%; 
    margin: 0; 
    padding: 0;
}

.material-total {
    /* border: 1px solid red;  */
    height: 25%; 
    width: 100%; 
    text-align: center; 
    align-items: center; 
    display: flex; 
    justify-content: center;
    font-size: 100%;
    color: white;
}

.material-total .current-fragment {
    color: lightgreen;
}

.craft-btn {
    width: 10%;
}

.input-form{
    height: 40px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: contents;
    align-items: center;
    width: auto;
}

.input-field {
    border: 1px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 5px 10px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;
}

.info-text {
    font-weight: bold; 
    font-size: 20px; 
    color: grey;
}

.info-row {
    width: 100%; 
    display: flex; 
    align-items: center; 
    font-size: 15px;
}

.info-img-icon {
    width: 20px;
    height: 20px;
}

.info-img-display {
    display: inline-flex; 
    gap: 6px;
}

.trait-row .col-md-3 {
    width: 50%;
  }

  .dropdown-toggle::after {
      position: absolute;
      top: 50%;
      right: 10px; /* Adjust as needed */
      transform: translateY(-50%);
    }

@media (max-width: 768px) {
    .craft-btn {
        width: 30%;
    }

    .material-box {
        height: 300px;
        width: 300px;
    }

    .material-use {
        height: 60%;
        width: auto;
        min-width: 49%;
    }
}

</style>