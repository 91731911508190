<template>
  <div
    class="card"
    style="width: 100%;"
  >
    <div class="card-body">
      <h5 class="card-title">
        Recent Activity
      </h5>
      <div
        class="data-table"
        style="margin-top: 24px !important; "
      >
        <div class="data-table-inside">
          <div v-if="GetListCount(assetEvent) > 0">
            <div
              v-for="x in assetEvent"
              class="activity-object"
            >
              <div v-if="x.event_type == 'sale' || x.event_type == 'transfer'">
                <div class="activity-date">
                  {{ x.dateString }}
                </div>
                <div class="activity-list">
                  <div class="activity-row">
                    <div class="activity-content">
                      <figure class="activity-content-icon">
                        <div v-if="x.event_type == 'sale'">
                          <img
                            width="20"
                            height="20"
                            :src="`../images/icon-sale.png`"
                            alt="sale image"
                          >
                        </div>
                        <div v-else>
                          <img
                            width="20"
                            height="20"
                            :src="`../images/icon-transfer.png`"
                            alt="transfer image"
                          >
                        </div>
                      </figure>
                      <div class="">
                        <div v-if="x.event_type == 'sale'">
                          <div
                            class="activity-content-details"
                            style="align-items: center;"
                          >
                            <div class="details-title">
                              Sold to  
                              <a
                                target="_blank"
                                href=""
                                class=""
                              >
                                <!-- Pop Over -->
                                <div class="">
                                  <div class="details-title-popover">
                                    <span class="details-title-person">
                                      {{ GetWalletName(x.buyer) }}
                                    </span>
                                  </div>
                                </div>
                              </a>
                              for 
                              <div
                                class="details-title-activity"
                                style="height: 100%; display: inline-flex;"
                              >
                                <div
                                  class="details-title-activity-gap"
                                  style="flex-direction: row; gap: 4px; display: inline-flex; align-items: center;"
                                >
                                  <img
                                    :src="GetCurrencySymbol(x.payment.symbol)"
                                    height="16"
                                  >
                                  <div class="">
                                    {{ x.salePrice }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="activity-content-details"
                            style="align-items: center;"
                          >
                            <div class="details-title">
                              Transferred from
                              <a
                                target="_blank"
                                href=""
                                class=""
                              >
                                <!-- Pop Over -->
                                <div class="">
                                  <div class="details-title-popover">
                                    <span class="details-title-person">
                                      {{ GetWalletName(x.from_address) }}
                                    </span>
                                  </div>
                                </div>
                              </a>
                              to
                              <a
                                target="_blank"
                                href=""
                                class=""
                              >
                                <div class="">
                                  <div class="details-title-popover">
                                    <span class="details-title-person">
                                      {{ GetWalletName(x.to_address) }}
                                    </span>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="activity-content-datetime">
                          <div v-if="x.event_type== 'sale'">
                            {{ x.dateString }}
                          </div>
                          <div v-else>
                            NaN
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      href=""
                      rel="noopener noreferrer"
                      target="_blank"
                      class="link"
                    >
                      <button class="activity-link default-button bg-custom-secondary">
                        <div style="display: flex; justify-content: center; align-items: center;">
                          <img
                            :src="`../images/icon-openlink.png`"
                            height="18"
                            style=" margin: 0; "
                          >
                        </div>
                      </button>
                    </a>
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div v-else>
            <img
              src="../assets/no-data.png"
              alt="no data"
              style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl } from '@/utils/imageUtils.js';
import { timeAgo } from '@/utils/timeUtils.js';
import '@/assets/css/styles.css';

export default {
    props: {
        tokenId: 0,
        assetEvent: {}
    },
    data() {
        return {
           
        }
    },
    mounted() {
    },
    methods: {
        GetListCount(list){
            return list.length;
        },
        GetWalletName(wallet){
            return "Fighterian #"+wallet.substr(0, 4);
        },
        ConvertMilliSecondToTime(ms){
            console.log(ms);
            const date = new Date(ms);

            // const year = date.getFullYear();
            // const month = date.getMonth() + 1; // Months are zero-based
            // const day = date.getDate();
            // const hours = date.getHours();
            // const minutes = date.getMinutes();
            // const seconds = date.getSeconds();
            // const milliseconds = date.getMilliseconds();

            // const formattedDatetime = `${year}-${this.pad(month)}-${this.pad(day)} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}.${milliseconds}`;
            console.log(date.toLocaleDateString('en-US'));
        },
        pad(number) {
            return number < 10 ? `0${number}` : number;
        },
        GetCurrencySymbol(currency){
            let path = "";
            switch(currency){
                case 'USDC': path = new URL("/src/assets/currency/usd-coin.png", import.meta.url); break;
                case 'MATIC': path = new URL("/src/assets/currency/matic-coin.png", import.meta.url); break;
                case 'ETH': path =  new URL("/src/assets/currency/ETH.png", import.meta.url); break;
                case 'JAB': path =  new URL("/src/assets/currency/JAB.png", import.meta.url); break;
            }
            return path;
        }
    }
};
</script>
  
<style scoped>
.activity-object {
    margin-bottom: 24px;
}
.activity-list {
    display: flex;
    flex-direction: column;
    position: relative;
}

.activity-row {
    /* border: 1px solid red; */
    display: inline-flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.activity-date {
    /* border: 1px solid red; */
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
}

.activity-content {
    display: inline-flex;
}

.activity-content-icon {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #282c34;
    background-color: #1c1f25;
    margin-right: 12px;
}

.activity-content-icon img {
    max-height: 24px;
    max-width: 24px;
    -o-object-fit: contain;
    object-fit: contain;
}

.activity-content-details {
    /* border: 1px solid red; */
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.details-title {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.details-title-person {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    color: var(--secondary-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.activity-content-datetime {
    color: #8e97a8;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.activity-link {
    padding: 6px;
    /* background: #282c34; */
    border-radius: 8px;
    border: 1px transparent;
    height: 32px;
    width: 32px;
}

.activity-link-button-icon {
    height: 20px;
    width: 20px;
}

.activity-link-button-icon svg{
    vertical-align: middle;
}

.details-title-popover {
    width: 100%; 
    display: inline-flex; 
    align-items: center; 
    min-width: 0;
}
</style>