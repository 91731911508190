<template>
  <div class="page-content content-wrap">
    <!--<img class="content-bg" src="/src/assets/backgrounds/background-default.png" alt="">-->
    <div class="vertical-opacity-overlay" />
    <div class="main-content">
      <div class="collection-info">
        <div class="d-flex justify-content-between align-items-center default-row flex-wrap">
          <div v-if=" total_supply">
            <h4>{{ total_supply }} Items </h4>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div v-if="isLoading">
              <div style="display: flex; flex-direction: row; overflow-x: auto;">
                <div
                  v-for="index in 6"
                  :key="index"
                >
                  <v-skeleton-loader
                    :type="'card'"
                    :animation="true"
                    :width="200"
                    :height="360"
                    theme="dark"
                    style="margin-right: 24px;"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="product-list">
                <ProductItem
                  v-for="(product, index) in products"
                  :key="index"
                  :product="product"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';


import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

import Web3 from 'web3';
import NFTContractABI from '@/contracts/NFTContractABI.json';


export default {
    components: {
        ProductItem,
        getClassImageUrl,
        
    }, data() {
        return {
            products: [],
            
            selectedOrderBy: 1,
            isLoading: true,
            total_supply: null
            
        };
    },
    mounted() {
        this.fetchNFTData();
        this.fetchStats();
    },
    methods: {
        async fetchStats() {
            const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
            const products = [];
            try{

                const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_FRAGMENT_CONTRACT_ADDRESS);

                const totalSupply = await nftContract.methods.totalSupply().call();
                console.log(`Total NFTs in the contract: ${totalSupply}`);
                this.total_supply = totalSupply;

            } catch (error) {
                console.error('Error fetching total supply:', error);
            }
        },
        async fetchNFTData() {
            try {
                const store = useExchangeStore();
                store.fetchExchangeRates();

                const response = await axios.get('https://api.opensea.io/api/v2/listings/collection/kf-fighter-fragment/all?limit=100', {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_OPENSEA_API_KEY
                    }
                });

                const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

                // Extract relevant data from the API response and update recentProducts
                this.products = response.data.listings.map(listing => {
                    const tokenID = listing.protocol_data.parameters.offer[0].identifierOrCriteria;
                    const dateString = timeAgo(listing.protocol_data.parameters.startTime);
                    const price = parseFloat(listing.price.current.value) / Math.pow(10, listing.price.current.decimals);
                    const usdPrice = convertToUSD(price, listing.price.current.currency, ethToUsdExchangeRate, maticToUsdExchangeRate);
                    console.log(listing);
                    var itemId = parseInt(tokenID) + 1;
                    return {
                        id: tokenID,
                        itemId: itemId ,
                        price: price,
                        currency: listing.price.current.currency,
                        listDate: listing.protocol_data.parameters.startTime,
                        dateString: dateString,
                        usdPrice: usdPrice,
                        orderHash: listing.order_hash,
                        isOwner: false
                    };
                });

                console.log("selectedClasses" + response.data);

                //this.products = this.products.slice(0, 50);
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.product-item {
    /* Add additional styling for each grid item if needed */
}

.text-secondary {
    font-size: 16px;
    color: #A4A5A5 !important;
    font-weight: 300;
}

@media (max-width: 768px) {
    .product-list {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}
</style>