import { defineStore } from 'pinia';

interface Card {
    card_id: number;
    card_name: string;
    card_type: string;
    card_sp_use: number;
    card_element: string;
    card_description: string;
    card_use_pos: string;
    card_target_pos: string;
    card_map_part_id: number;
}

interface PartMapper {
    part_id: number;
    part_name: string;
    card_id: number;
}

export const useCardStore = defineStore({
    id: 'cards',
    state: () => ({
        cards: [] as Card[],
        partMappers: [] as PartMapper[],
    }),
    actions: {
        async fetchCardData(this: any) { // Adjust the type of 'this' as per Pinia's requirement
            if (this.cards.length !== 0) {
                return;
            }

            // const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQOjpQWNTyoSDoE8DwLqjRCFs3UxedvYPOzEK9zqGkSIFoSM6g1ZGqPGQdazjg8tWrhgK8SUjwTEX3J/pub?gid=976672992&single=true&output=csv";

            try {
                const response = await fetch('/csv/card-data.csv');
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const csvData = await response.text();
                // const csvData = await this.readCSVFromFile('/images/cards/Card-data.csv');

                const rows = csvData.split('\n').slice(1);

                rows.forEach(row => {
                    const values = row.replace(/#N\/A/g, "").split(',');
                    // console.log('Total cards:', values);
                    if(values[0] !== "" && !isNaN(Number(values[0]))){
                        const card: Card = {
                            card_id: parseInt(values[0]),
                            card_name: (values[1] != '')? values[1] : "Card",
                            card_type: (values[4] != '')? values[4].toLowerCase():"", 
                            card_sp_use: (values[5] !== "" && !isNaN(Number(values[5]))) ? parseInt(values[5]) : 0,
                            card_element: (values[13] !== "" && !isNaN(Number(values[13]))) ? values[10].toLowerCase() : "empty",
                            card_description: `<span class="text-yellow">ACC ${values[12]} ${(values[9] !== "" && !isNaN(Number(values[9])) && values[9].trim() !== "0")? 'DMG '+ values[9]+"-"+values[10] : ''} </span><br>${values[15]}`,
                            card_use_pos: values[7],
                            card_target_pos: values[8],
                            card_map_part_id: (values[21] !== "" && !isNaN(Number(values[21]))) ? parseInt(values[21]) : 0,
                        };

                        this.cards.push(card);
                    }
                    
                });
                console.log('Total cards:', this.cards.length);
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchPartMapper(this: any) { // Adjust the type of 'this' as per Pinia's requirement
            if (this.partMappers.length !== 0) {
                return;
            }

            // const url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQOjpQWNTyoSDoE8DwLqjRCFs3UxedvYPOzEK9zqGkSIFoSM6g1ZGqPGQdazjg8tWrhgK8SUjwTEX3J/pub?gid=1555178043&single=true&output=csv";

            try {
                const response = await fetch('/csv/part-data.csv');
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                
                const csvData = await response.text();

                const rows = csvData.split('\n').slice(1);

                rows.forEach(row => {
                    const values = row.replace(/#N\/A/g, "").split(',');
                    if((values[0] !== "" && !isNaN(Number(values[0])))){
                        const mapper: PartMapper = {
                            part_id: parseInt(values[0]),
                            part_name: values[1],
                            card_id: (values[2] !== "" && !isNaN(Number(values[2]))) ? parseInt(values[2]) : 0
                        };

                        this.partMappers.push(mapper);
                    }
                });
                this.partMappers.sort((a: PartMapper, b: PartMapper) => a.part_id - b.part_id);
                console.log('Total parts:', this.partMappers.length);
            } catch (error) {
                console.error('Error:', error);
            }
        },
    },
    getters: {
        getCardData: (state) => (id: number) => {
            // console.log("Get card ID:", id);
            //console.error("Get :", state.cards.find(card => card.card_id === id));
            return state.cards.find(card => card.card_id === id);
        },
        getCardIdFromPart: (state) => (id: number) => {
            // console.log("Get part ID:", id);
            //const partMapper = state.partMappers.find(p => p.part_id === id);
            const partMapper = state.cards.find(p => p.card_map_part_id === id);
            if(partMapper) {
                return partMapper.card_id;
            } else {
                console.error("Part with ID", id, "not found");
                return 0;
            }
        },
        getAllPartData: (state) => () => {
            return state.partMappers;
        },
    },   
});
