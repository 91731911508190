<template>
  <div
    id="recieveItemPopup"
    class="modal fade zoom-in"
    tabindex="-1"
    aria-labelledby="recieveItemPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-body"
          style="padding: 30px; display: grid;"
        >
          <div
            class="text-recieved"
            style="justify-self: center;"
          > 
            <img src="~@/assets/images/obtain-reward.png">
          </div>
          <div
            class=""
            style="margin-top: -10px; margin-bottom: 0; justify-self: center; position: relative;"
          >
            <audio
              id="recievedItemSFX"
              controls
              class="hidden-audio"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
            >
              <source
                src="~@/assets/sound/SFX_QuestReward.mp3"
                type="audio/mp3"
              >
            </audio>
            <img
              src="~@/assets/images/shine-opening-1.png"
              class="animated-image"
              alt="Shining Image"
            >
            <!-- <img :src="getImagePath()" alt="Product Image" class="product-image"> -->
            <img
              :src="`../images/item/${item.id}.png`"
              alt="Item Recieved Image"
              class="product-image"
            >
          </div>
          <span style="justify-self: center; font-size: 22px;"> You have received <span style="color: yellow;">{{ item.amount }}  {{ item.name }}</span>!!! </span>
          <div
            class=""
            style="justify-self: center; margin-top: 40px;"
          >
            <button
              ref="Close"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';

import NFTContractABI from '@/contracts/NFTContractABI.json';

export default {
    components: {
    },
    props: {
        showModal: Boolean,
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        
        };
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        getImagePath(){
            if(this.item.id == "FIGHTER") return this.item.url;
            return new URL("../images/image/"+this.item.id+".png", import.meta.url);
        },
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 1080px;
    max-height: 500px;
}
.modal-content {
    border: 0px;
    background-color: transparent;
}

.modal.zoom-in .modal-dialog {
    transform: scale(0.8);
    transition: transform 0.3s ease-out;
}

.modal.show.zoom-in .modal-dialog {
    transform: scale(1);
}

.text-recieved {
    font-weight: 600;
    font-size: x-large;
}

.modal {
    background-color: rgba(3, 3, 3, 0.8);
}

.animated-image {
  animation: imageAnimation 0.4s linear infinite; /* Adjust duration as needed */
  height: 450px; 
  width: auto;
}

.hidden-audio {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

.text-recieved img {
    height: 100px; 
    width: auto;
}

.product-image {
    height: 170px; 
    width: auto; 
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
}

@keyframes imageAnimation {
  0%, 33% {
    opacity: 1;
    content: url('@/assets/images/shine-opening-1.png');
  }
  33.01%, 66% {
    opacity: 1;
    content: url('@/assets/images/shine-opening-2.png');
  }
  66.01%, 100% {
    opacity: 1;
    content: url('@/assets/images/shine-opening-3.png');
  }
}

@media (max-width: 768px) {
    .text-recieved img {
        height: auto; 
        width: 100%;
    }

    .animated-image {
        height: auto; 
        width: 100%;
    }

    .product-image {
        height: 120px; 
        width: auto; 
    }
}
</style>
  
