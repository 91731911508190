import { reactive } from 'vue';

const state = reactive({
  isPolygonConnected: false,
});

const setPolygonConnection = (value) => {
  state.isPolygonConnected = value;
};

export const useNetwork = () => {
  return {
    state,
    setPolygonConnection,
  };
};
