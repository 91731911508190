<template>
  <div
    id="openingItemPopup"
    class="modal fade zoom-in"
    tabindex="-1"
    data-bs-backdrop="static"
    aria-labelledby="openingItemPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-body"
          style="padding: 30px; display: grid;"
        >
          <div class="text-header"> 
            Open {{ item.name }}
          </div>
          <div
            class=""
            style="margin: 0; margin-bottom: 20px; justify-self: center; position: relative;"
          >
            <audio
              id="openingItemSFX"
              controls
              class="hidden-audio"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%);"
            >
              <source
                src="~@/assets/sound/SFX_HitCharge.mp3"
                type="audio/mp3"
              >
            </audio>
            <img
              :src="`../images/loading/${item.itemCode}-loading.gif`"
              alt="Product Image"
              class="loading-image"
            >
          </div>
          <div v-if="isConfirmed">
            <div class="text-recieved">
              <span> {{ item.name }} is opening. please do not close this page. </span>
            </div>
          </div>
          <div
            v-else
            class="text-recieved"
          >
            <span> Go to your wallet </span>
            <span> You'll be asked to approve this transfer from your wallet. </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';

import NFTContractABI from '@/contracts/NFTContractABI.json';

export default {
    components: {
    },
    props: {
        showModal: Boolean,
        item: {
            type: Object,
            required: true
        },
        isConfirmed: Boolean
    },
    data() {
        return {
            
        };
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        }
    }
};
</script>

<style scoped>
.modal-content {
    border: 0px;
    background-color: transparent;
}

.modal.zoom-in .modal-dialog {
    transform: scale(0.8);
    transition: transform 0.3s ease-out;
}

.modal.show.zoom-in .modal-dialog {
    transform: scale(1);
}

.text-recieved {
    font-size: 16px;
    font-weight: 500;
    justify-content: center; 
    display: flex; 
    flex-direction: column; 
    text-align: center;
}

.text-header {
    justify-self: center; 
    font-size: 28px; 
    font-weight: 600;
}

.loading-image {
    height: 350px; 
    width: auto;
}

.modal {
    background-color: rgba(3, 3, 3, 0.8);
}

.hidden-audio {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

</style>
  
