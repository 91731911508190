// src/utils/web3Utils.js
import Web3 from 'web3';
import axios from 'axios';

import { useStore } from '@/stores/index.ts';

export const getSignature = async () => {
    try {

        const web3 = new Web3(window.ethereum);
        const store = useStore();
        const accountAddress =  store.getWalletAddress;
        const { data: challengeMessage } = await axios.get('https://reward-portal.kryptofighters.io/api/protect/generate-challenge'); // Fetch challenge message from API
        
        //console.log("challengeMessage",challengeMessage);
        //console.log("account",accountAddress);
        const signature = await web3.eth.personal.sign(challengeMessage.message, accountAddress, '');
        return {
            signature,
            challengeMessage : challengeMessage.message,
            address: accountAddress
        };
    } catch (error) {
        console.error('Error signing message:', error);
        throw error;
    }
};
