<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Stone / Artifact </h4>
    </div>


    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <EquipmentItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
              :type="product.type"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import EquipmentItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import { ref, onMounted, watch } from 'vue';
import { ethers } from 'ethers';
import axios from 'axios';
import { shortenedWalletAddress, shortenedWalletAddress_rarible, shortenedWalletAddress_rarible2 } from '@/utils/walletUtils.js';


export default {
    components: {
        EquipmentItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
    },
    methods: {
        async fetchNFTData(itemList) {
            for (const row of itemList) {
                try {
                    // console.log(row);
                    var artifactCollection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
                    const filterType = (row.collection.slice(6).toLowerCase() == artifactCollection.toLowerCase())? 'artifact':'stone';
                    const nftResponse = await axios.get(`https://metadata.kryptofighters.io/item/${filterType}/${row.tokenId}.json`);

                    const collection = (filterType == 'stone')? process.env.VITE_STONE_CONTRACT_ADDRESS:process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
                    // const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${collection}:${row.tokenId}`;
                    // const response = await axios.get(apiUrl, {
                    //     headers: {
                    //         'accept': 'application/json',
                    //         'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    //     }
                    // });
                    console.log("type response ",nftResponse);

                    const product = {
                        id: row.tokenId,
                        itemId: row.tokenId,
                        price: 0,
                        currency: 'JAB',
                        listDate: '',
                        dateString: '',
                        usdPrice: 0,
                        image: nftResponse.data.image,
                        type: nftResponse.data.attributes.type,
                        description: '',
                        isOwner: true,
                        isListing: Boolean(row.bestSellOrder && row.bestSellOrder !== null && row.bestSellOrder !== undefined),
                        collection: row.collection,
                        name: nftResponse.data.name,
                        amount: row.value
                    };

                    this.products.push(product);
                } catch (error) {
                    console.error(`Error fetching data for token ID ${row.tokenId}:`, error);
                }
            }
        },

        async fetchData() {
            try {
                const store = this.store;
                const accountAddress = store.getWalletAddress;

                try{
                    const stoneCollection = process.env.VITE_STONE_CONTRACT_ADDRESS;
                    const stoneRequestBody = {
                        size: 50,
                        filter: {
                            blockchains: ['KROMA'],
                            owners: [`ETHEREUM:${accountAddress}`],
                            collections: [`KROMA:${stoneCollection}`]
                        }
                    };
                    // console.log("stoneRequestBody ",stoneRequestBody);
                    const stoneApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                    const stoneResponse = await axios.post(stoneApiUrl, stoneRequestBody, {
                        headers: {
                            'accept': 'application/json',
                            'x-api-key': process.env.VITE_RARIBLE_API_KEY
                        }
                    });
                    console.log("stoneResponse ",stoneResponse);

                    const artifactCollection = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS
                    const artifactRequestBody = {
                        size: 50,
                        filter: {
                            blockchains: ['KROMA'],
                            owners: [`ETHEREUM:${accountAddress}`],
                            collections: [`KROMA:${artifactCollection}`]
                        }
                    };
                    // console.log("artifactRequestBody ", artifactRequestBody);
                    const artifactApiUrl = `https://testnet-api.rarible.org/v0.1/ownerships/search`;
                    const artifactResponse = await axios.post(artifactApiUrl, artifactRequestBody, {
                        headers: {
                            'accept': 'application/json',
                            'x-api-key': process.env.VITE_RARIBLE_API_KEY
                        }
                    });
                    console.log("artifactResponse ", artifactResponse);

                    const combinedData = [...stoneResponse.data.ownerships, ...artifactResponse.data.ownerships];
                    console.log("combinedData ", combinedData);

                    await this.fetchNFTData(combinedData);
                } catch (error) {
                    console.error("Contract1 Error:", error);
                }

                this.amounts["equipment"] = this.products.length;
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  