export function shortenedWalletAddress(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(0, 6)}...${walletAddress.slice(-3)}`;
    }
    return '';
  }

export function shortenedWalletAddress_rarible(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(9)}`;
    }
    return '';
  }

  export function shortenedWalletAddress_rarible2(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(9, 15)}...${walletAddress.slice(-3)}`;
    }
    return '';
  }