<template>
  <div
    class="card"
    style="width: 100%;"
  >
    <div class="card-body">
      <h5 class="card-title">
        Sale History
      </h5>
      <div
        class="sale-history-table"
        style="margin-top: 24px !important; "
      >
        <div class="sale-history-table-in">
          <div v-if="GetListCount(assetEvent) > 0">
            <div class="dango-table table-module_fullWidth__wKwXo inside-table">
              <div class="dango-table-container">
                <div class="dango-table-content">
                  <table>
                    <colgroup>
                      <col style="width: 35%;">
                      <col style="width: 40%;">
                      <col style="width: 25%;">
                    </colgroup>
                    <thead class="dango-table-thead">
                      <tr>
                        <th
                          class="table-cell"
                          scope="col"
                        >
                          <div class="header-text">
                            BUYER
                          </div>
                        </th>
                        <th
                          class="table-cell"
                          scope="col"
                        >
                          <div class="header-text">
                            SELLER
                          </div>
                        </th>
                        <th
                          class="table-cell"
                          scope="col"
                        >
                          <div class="header-text">
                            DATE/PRICE
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="dango-table-tbody">
                      <tr
                        v-for="x in assetEvent"
                        class="table-row"
                      >
                        <td class="list-table-cell">
                          <div class="">
                            <div class="popover-module_trigger">
                              <a
                                href=""
                                class="link"
                              >
                                <div class="cell-upper-text">
                                  {{ GetWalletName(x.buyer) }}
                                </div>
                                <div class="cell-lower-text">
                                  {{ GetWalletAddress(x.buyer) }}
                                </div>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td class="list-table-cell">
                          <div class="">
                            <div class="popover-module_trigger">
                              <a
                                href=""
                                class="link"
                              >
                                <div class="cell-upper-text">
                                  {{ GetWalletName(x.seller) }}
                                </div>
                                <div class="cell-lower-text">
                                  {{ GetWalletAddress(x.seller) }}
                                </div>
                              </a>
                            </div>
                          </div>
                        </td>
                        <td class="list-table-cell">
                          <div class="currency">
                            <img
                              :src="GetCurrencySymbol(x.payment.symbol)"
                              height="16"
                            >
                            <div class="">
                              {{ x.salePrice }}
                            </div>
                          </div>
                          <div class="cell-lower-text">
                            {{ x.dateString }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <img
              src="../assets/no-data.png"
              alt="no data"
              style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl } from '@/utils/imageUtils.js';
import { timeAgo } from '@/utils/timeUtils.js';

export default {
    props: {
        tokenId: 0,
        assetEvent: {}
    },
    data() {
        return {
           
        }
    },
    mounted() {
        // Fetch image URL when the component is mounted
        //this.fetchEvent();
    },
    methods: {
        GetListCount(list){
            return list.length;
        },
        GetWalletName(wallet){
            return "Fighterian #"+wallet.substr(0, 4);
        },
        GetWalletAddress(wallet){
            return "wallet: "+wallet.substr(0, 4)+"..."+wallet.substr(wallet.length-4, wallet.length);
        },
        GetCurrencySymbol(currency){
            let path = "";
            switch(currency){
                case 'USDC': path = new URL("/src/assets/currency/usd-coin.png", import.meta.url); break;
                case 'MATIC': path = new URL("/src/assets/currency/matic-coin.png", import.meta.url); break;
                case 'ETH': path =  new URL("/src/assets/currency/ETH.png", import.meta.url); break;
                case 'JAB': path =  new URL("/src/assets/currency/JAB.png", import.meta.url); break;
            }
            return path;
        }
    }
};
</script>
  
<style scoped>


.sale-history-table {
    background-color:var(--soft-background-color);
    border-radius: 8px;
}

.sale-history-table-in {
    padding: 32px 20px;
    border-radius: 8px;
}

.table-module_fullWidth__wKwXo table {
    width: 100%;
}

.header-text {
    font-size: x-small;
    color: #8e97a8;
}

.table-cell {
    padding: 20px 16px;
    padding-top: 0;
}

.list-table-cell {
    padding: 20px 16px;
}

.link {
    text-decoration: none;
}

.cell-upper-text {
    /* border: 1px solid pink; */
    color: white;
    font-size: 14p;
    font-weight: 600;
}

.cell-lower-text {
    /* border: 1px solid pink; */
    color: #5b6372;
    font-size: small;
}

.currency {
    display: flex;
    align-items: center;
}

.table-row:nth-child(odd) {
    background-color: var(--secondary-background-color);
}

@media (max-width: 768px) {
    .dango-table-content {
        overflow: scroll;
    }
}

</style>