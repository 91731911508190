<template>
    <div class="modal fade" id="pickMasterPopup" tabindex="-1" aria-labelledby="pickMasterPopup" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" style="height: 60px; width: 100%; display: flex; align-items: center; justify-content: space-between;">
                    <h5 style="flex: 1; text-align: center; margin: 0;"> Pick Your Master </h5>
                    <button class="close-button" ref="Close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">X</button>
                </div>
                <div class="modal-body card">
                    <div class="card-body">
                        <div v-if="localTokenOptions.length > 0">
                            <div class="product-list">
                                <MasterItem v-for="(option, index) in localTokenOptions" :key="index" :option="option" @refreshData="setMasterToken" :index="index"/>
                            </div>
                        </div>
                        <div v-else>
                            <img src="~@/assets/no-data.png" alt="no data"
                                style="display: block; margin-left: auto; margin-right: auto; width: 160px;">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
  
<script>
import MasterItem from '@/components/MasterItem.vue';

export default {
    components: {
        MasterItem
    },
    props: {
        showModal: Boolean,
        tokenOptions: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            localTokenOptions: []
        };
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        setMasterToken(tokenId) {
            console.log("setMasterToken "+tokenId);
            this.$emit('setMasterToken', tokenId);
            this.closeModal();
        },
        GetData(){
            return this.tokenOptions;
        }
    },
    mounted(){
        console.log("gen1Response pickmaster",this.tokenOptions.length);
    },
    watch: {
        tokenOptions(newVal) {
            console.log("Updated tokenOptions length:", newVal.length);
            this.localTokenOptions = newVal;
        }
    }
};
</script>

<style scoped lang="scss">

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.modal-dialog {
    max-width: 1000px;
    max-height: auto;
}

.modal-body {
  max-height: 100%; /* Adjust this value as needed */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-content {
    border: 0px;
    background-color: var(--primary-background-color);
}

@media (max-width: 768px) {
    .modal-title {
        font-size: 120%;
    }
    
    .modal-body {
        padding: 0.5rem 0;
    }
    .term-detail {
        padding: 0.2rem 0.5rem;
        gap: 5px;
    }

    .header-row {
        margin-bottom: 0.2rem;
    }

    .modal-header {
        padding: 0.5rem 1rem;
    }

    .detail-block {
        display: flex;
        flex-direction: column;
        overflow: scroll;
        width: auto;
        height: auto;
        max-height: 328px;
    }
}
</style>
  
